import { useSelector } from 'react-redux';

import { RootState } from '../../redux/store';
import DomainDropdown from '../DomainDropdown/Domiandropdown';
import Menu from '../MainMenu/Menu';
import MenuContainer from '../MainMenu/MenuContainer';
import ProfileMenu from '../profile/ProfileMenu';
import SettingsContainer from '../settings/SettingsContainer';

import './Header.css';

const Header = () => {
  const isSettingsOpen = useSelector((state: RootState) => state.user.isSettingsOpen);
  const isMenuOpen = useSelector((state: RootState) => state.user.isMenuOpen);

  return (
    <header>
      <div className="container">
        <div
          className="row"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <div
            className="header-section"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              justifyContent: 'space-between',
            }}
          >
            <div className="settingsSec" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <div
                className="domain-options"
                style={{
                  // width: '200px',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  borderRadius: '6px',
                }}
                id="component-g-element"
              >
                <DomainDropdown />
              </div>

              <div className="settings-options" id="component-h-element">
                <Menu />
              </div>
              <div className="userSec" id="component-i-element">
                <ProfileMenu />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSettingsOpen && <SettingsContainer />}
      {isMenuOpen && <MenuContainer />}
    </header>
  );
};

export default Header;
