import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useAnalytics from '../../../hooks/useAnalytics';
import { addChatToConversation, createNewConversation, setInputValue } from '../../../redux/features/conversationSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  ApiResponse,
  ConversationTreeNode,
  CreateConversationChatResponse,
  CreateNewConversationResponse,
} from '../../../services/apiService/definitions/types';
import { getLatestAnswerNodeOfQueryNode } from '../../../utils/conversation';
import { Spinner } from '../../Spinner';

import StopButton from './StopButton';

import './ChatInput.css';
import styles from './ChatInput.module.css';

type Props = {
  chatInputRef: React.RefObject<HTMLTextAreaElement>;
};

const ChatInput: React.FC<Props> = ({ chatInputRef }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const analytics = useAnalytics();

  const [listening, setListening] = useState(false);
  const conversationId = useSelector((state: RootState) => state.conversation.conversationId);
  const conversationChain = useSelector((state: RootState) => state.conversation.conversationChain);
  const isQuestionSubmitted = useSelector((state: RootState) => state.conversation.isQuestionSubmitted);
  const showApiCallPendingSpinner = useSelector((state: RootState) => state.conversation.showApiCallPendingSpinner);
  const inputValue = useSelector((state: RootState) => state.conversation.inputValue);
  const isCacheEnabled = useSelector((state: RootState) => state.conversation.isCacheEnabled);
  const isSemanticCacheEnabled = useSelector((state: RootState) => state.conversation.isSemanticCacheEnabled);
  const currentProjectKey = useSelector((state: RootState) => state.sideBar.projectKey);
  const isPro = useSelector((state: RootState) => state.user.isPro);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setInputValue(event.target.value));

    const maxHeight = 150;
    const minHeight = 45; // Height for a single line
    const textarea = event.target;

    // Reset height to allow shrinking, then adjust based on content
    textarea.style.height = 'auto';
    // If there's more than one line of content, adjust the height
    if (textarea.scrollHeight > minHeight) {
      if (textarea.scrollHeight == 68) {
        textarea.style.height = `${minHeight}px`;
        return;
      }
      textarea.style.height = `${Math.min(textarea.scrollHeight, maxHeight)}px`;

      if (textarea.scrollHeight > maxHeight) {
        textarea.style.overflowY = 'auto';
        textarea.style.padding = '29px 76px 37px 25px'; // Add padding
        textarea.style.borderRadius = '10px'; // Add border-radius
      } else {
        textarea.style.overflowY = 'hidden';
        textarea.style.padding = '10px 45px 10px 20px'; // Reset padding
        textarea.style.borderRadius = '36.449px'; // Reset border-radius
      }
    } else {
      // For single-line content, reset to default styling
      textarea.style.height = `${minHeight}px`;
      textarea.style.overflowY = 'hidden';
      textarea.style.padding = '10px 45px 10px 20px';
      textarea.style.borderRadius = '36.449px';
    }

    // Additional check if textarea is empty
    if (textarea.value.trim() === '') {
      textarea.style.height = `${minHeight}px`;
      textarea.style.overflowY = 'hidden';
      textarea.style.padding = '10px 45px 10px 20px';
      textarea.style.borderRadius = '36.449px';
    }
  };

  const trackMixpanelEvent = (mode: string) => {
    analytics.trackChatSubmitEvent({
      mode,
      is_cached: isCacheEnabled,
      is_semantic_cache: isCacheEnabled ? isSemanticCacheEnabled : false,
      conversation_id: conversationId || '',
      project_key: currentProjectKey,
      is_pro: isPro,
    });
  };

  const trackChatResponseReceived = (answerNode?: ConversationTreeNode | null) => {
    if (answerNode && answerNode.data.engine_response) {
      analytics.trackChatResponseReceived({
        success: !answerNode.data.engine_response.error_flag,
        chart_present: !!answerNode.data.engine_response.chart_results,
        source: answerNode.data.engine_response.answer_source,
        conversation_id: answerNode.data.conversation_id,
        question_type:
          answerNode.data.engine_response.response && answerNode.data.engine_response.response.length > 1
            ? 'complex'
            : 'simple',
        chat_id: answerNode.id,
        question_status: answerNode.data.status,
        status: 'COMPLETED',
      });
    }
  };

  const handleSendMessage = async (query: string) => {
    try {
      if (query.trim() === '') {
        return;
      }
      if (conversationId) {
        const parentQueryNodeId = conversationChain[conversationChain.length - 1].queryNodeId;
        const result = await dispatch(
          addChatToConversation({
            conversationId,
            body: {
              parent_node_id: parentQueryNodeId,
              query: query.replace(/\n/g, ' '),
              is_cached: isCacheEnabled,
              is_semantic_cache: isCacheEnabled ? isSemanticCacheEnabled : false,
              is_pro: isPro,
            },
          }),
        );
        if (result.type.endsWith('fulfilled')) {
          const {
            data: { conversation },
          } = result.payload as ApiResponse<CreateConversationChatResponse>;
          // For an existing tree, the new query node will be added as child of given `parentQueryNodeId`
          const newQueryNode = conversation.nodes[parentQueryNodeId];
          const newAnswerNode = getLatestAnswerNodeOfQueryNode(newQueryNode.id, conversation);
          trackChatResponseReceived(newAnswerNode);
        }
      } else {
        const result = await dispatch(
          createNewConversation({
            project_key: currentProjectKey,
            query: query.replace(/\n/g, ' '),
            is_cached: isCacheEnabled,
            is_semantic_cache: isCacheEnabled ? isSemanticCacheEnabled : false,
            is_pro: isPro,
          }),
        );
        if (result.type.endsWith('fulfilled')) {
          const {
            data: { conversation },
          } = result.payload as ApiResponse<CreateNewConversationResponse>;
          // For newly created conversation, tree would only have 2 nodes (query & answer)
          const newAnswerNode = getLatestAnswerNodeOfQueryNode(conversation.top_level_node_ids[0], conversation);
          trackChatResponseReceived(newAnswerNode);
          navigate(`/chat/${conversation.conversation_id}`);
        }
      }
    } catch (err) {
      console.error('Error sending message:', err);
    }
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        event.preventDefault();
        dispatch(setInputValue(inputValue + '\n'));
      } else {
        handleSendMessage(inputValue.trim());
        trackMixpanelEvent('enter_key');
        event.currentTarget.style.height = '42px';
        event.currentTarget.style.overflowY = 'hidden';
        event.currentTarget.style.padding = '10px 45px 10px 20px'; // Reset padding
        event.currentTarget.style.borderRadius = '36.449px'; // Reset border-radius
      }
    }
  };

  const handleSendMessageClick = async () => {
    handleSendMessage(inputValue.trim());
    trackMixpanelEvent('button_click');
  };

  const recognition = new (window as any).webkitSpeechRecognition();
  const startSpeechRecognition = () => {
    recognition.onstart = () => {
      setListening(true); // Set to true only when recognition starts
    };

    recognition.onresult = (event: any) => {
      const transcript = event.results[0][0].transcript;
      dispatch(setInputValue(transcript));
    };

    recognition.onend = () => {
      setListening(false); // Only set to false when recognition truly stops
    };

    recognition.start();
  };

  const stopSpeechRecognition = () => {
    if (recognition) {
      // Clear `onend` temporarily to avoid any unwanted triggers
      recognition.onend = () => {
        setListening(false); // Update listening state only after recognition fully stops
      };

      recognition.stop(); // Stop recognition
    }
  };

  // Debounce function to avoid rapid toggling
  const debounce = <T extends (...args: any[]) => void>(func: T, delay: number) => {
    let timer: NodeJS.Timeout;
    return (...args: Parameters<T>) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  const handleMicrophoneClick = debounce(() => {
    if (!listening) {
      startSpeechRecognition();
    } else {
      stopSpeechRecognition();
    }
  }, 200);

  return (
    <>
      <div className="chatSearch">
        <div className="chat-input" id="component-a-element">
          {listening && (
            <img src={`${process.env.PUBLIC_URL}/images/Voice.gif`} alt="Listening" className="listening-icon" />
          )}
          <textarea
            id="prompt-textarea"
            placeholder={listening ? 'Listening' : 'Ask a question'}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            ref={chatInputRef}
            className={`${isQuestionSubmitted ? 'disabled' : null}`}
            disabled={isQuestionSubmitted}
            style={{ paddingLeft: listening ? '60px' : '' }}
          />
          {showApiCallPendingSpinner ? (
            <div className={styles.spinnerContainer}>
              <Spinner />
            </div>
          ) : (
            <div className={styles.chatIconContainer}>
              {isQuestionSubmitted ? (
                <StopButton />
              ) : (
                <>
                  {inputValue.trim().length ? (
                    <svg
                      onClick={handleSendMessageClick}
                      className={styles.sendIcon}
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                    >
                      <mask id="mask0_17872_2331" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">
                        <rect width="22" height="22" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_17872_2331)">
                        <path
                          d="M3.01758 17.8529V12.487L9.5361 10.6984L3.01758 8.9098V3.54395L18.4991 10.6984L3.01758 17.8529Z"
                          fill="#E5B611"
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 36 36"
                      fill="none"
                      onClick={handleSendMessageClick}
                    >
                      <path
                        className="chat-icon-svg"
                        d="M6.075 29.325C5.7 29.475 5.34375 29.4437 5.00625 29.2312C4.66875 29.0187 4.5 28.7125 4.5 28.3125V20.8125L15.825 18L4.5 15.1125V7.6875C4.5 7.2875 4.66875 6.98125 5.00625 6.76875C5.34375 6.55625 5.7 6.525 6.075 6.675L30.525 16.95C30.975 17.15 31.2 17.5 31.2 18C31.2 18.5 30.975 18.85 30.525 19.05L6.075 29.325Z"
                        fill="white"
                        fillOpacity="0.61"
                      />
                    </svg>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        <div className="voice-input">
          {!listening ? (
            <svg
              onClick={handleMicrophoneClick}
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
            >
              <mask id="mask0_2022_86" maskUnits="userSpaceOnUse" x="0" y="0" width="23" height="23">
                <rect x="0.853394" y="0.115784" width="22" height="22" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_2022_86)">
                <path
                  className="svg-icon-path"
                  d="M11.8534 12.9491C11.0895 12.9491 10.4402 12.6817 9.90546 12.147C9.37073 11.6123 9.10337 10.963 9.10337 10.1991V4.6991C9.10337 3.93521 9.37073 3.2859 9.90546 2.75118C10.4402 2.21646 11.0895 1.9491 11.8534 1.9491C12.6173 1.9491 13.2666 2.21646 13.8013 2.75118C14.336 3.2859 14.6034 3.93521 14.6034 4.6991V10.1991C14.6034 10.963 14.336 11.6123 13.8013 12.147C13.2666 12.6817 12.6173 12.9491 11.8534 12.9491ZM10.9367 19.3658V16.547C9.34782 16.3331 8.03393 15.6227 6.99504 14.4158C5.95615 13.2088 5.43671 11.8033 5.43671 10.1991H7.27004C7.27004 11.4672 7.71691 12.5481 8.61066 13.4418C9.50442 14.3356 10.5853 14.7824 11.8534 14.7824C13.1214 14.7824 14.2023 14.3356 15.0961 13.4418C15.9898 12.5481 16.4367 11.4672 16.4367 10.1991H18.27C18.27 11.8033 17.7506 13.2088 16.7117 14.4158C15.6728 15.6227 14.3589 16.3331 12.77 16.547V19.3658H10.9367ZM11.8534 11.1158C12.1131 11.1158 12.3308 11.0279 12.5065 10.8522C12.6822 10.6765 12.77 10.4588 12.77 10.1991V4.6991C12.77 4.43937 12.6822 4.22167 12.5065 4.04597C12.3308 3.87028 12.1131 3.78243 11.8534 3.78243C11.5937 3.78243 11.3759 3.87028 11.2002 4.04597C11.0246 4.22167 10.9367 4.43937 10.9367 4.6991V10.1991C10.9367 10.4588 11.0246 10.6765 11.2002 10.8522C11.3759 11.0279 11.5937 11.1158 11.8534 11.1158Z"
                  fill="#E2E2E2"
                />
              </g>
            </svg>
          ) : (
            <svg
              onClick={handleMicrophoneClick}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path className="svg-icon-path" d="M6 19H10V5H6V19ZM14 5V19H18V5H14Z" fill="#CDCDCD" />
            </svg>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatInput;
