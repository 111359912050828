import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import useAnalytics from '../../hooks/useAnalytics';
import { RootState } from '../../redux/store';
import LogoComponent from '../LandingScreen/IC-Logo';

import AcknowledgeContainer from './AcknowledgeContainer';
import Notice from './NoticeComponent';
import OnboardingContainer from './OnboardingContainer';

import './Onboarding.css';

const Onboarding = () => {
  const userAccount = useSelector((state: RootState) => state.user.userData);

  const theme = useSelector((state: RootState) => state.user.theme);

  const [step, setStep] = useState(1); // State to track the current step of the onboarding process

  const onboardingRef = useRef<HTMLDivElement>(null);

  const acknowledgeRef = useRef<HTMLDivElement>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  const analytics = useAnalytics();

  useEffect(() => {
    if (step === 1 && onboardingRef.current) {
      onboardingRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (step === 2 && acknowledgeRef.current) {
      acknowledgeRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (step > 2 && containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [step]);

  // Function to handle moving to the next step
  const handleNextStep = () => {
    setStep(step + 1);
    analytics.trackOnboardingStep(step);
  };

  const [dosAllChecked, setDosAllChecked] = useState(false);

  const [dontsAllChecked, setDontsAllChecked] = useState(false);

  const handleDosAllCheckedChange = (allChecked: boolean) => {
    setDosAllChecked(allChecked);
  };

  const handleDontsAllCheckedChange = (allChecked: boolean) => {
    setDontsAllChecked(allChecked);
  };

  const [showPopup, setShowPopup] = useState(() => localStorage.getItem('termsAccepted') !== 'true'); // State to track if the terms and conditions popup should be shown
  const [isChecked, setIsChecked] = useState(false);

  const handleClose = () => {
    if (isChecked) {
      setShowPopup(false);
    } else {
      alert('You must agree to the terms to continue.');
    }
  };

  const handleAgreeTerms = () => {
    setShowPopup(false);
    localStorage.setItem('termsAccepted', 'true');
  };

  const username = userAccount?.username;

  const dosIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0156 9.83289L11.7656 6.08286L12.7968 7.11405L8.0156 11.8953L5.20312 9.0828L6.23433 8.05161L8.0156 9.83289Z"
        fill="#F5E003"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99998 2.93075C5.77832 2.93075 3.16665 5.54242 3.16665 8.76408C3.16665 11.9858 5.77832 14.5974 8.99998 14.5974C12.2217 14.5974 14.8333 11.9858 14.8333 8.76408C14.8333 5.54242 12.2217 2.93075 8.99998 2.93075ZM1.70831 8.76408C1.70831 4.737 4.9729 1.47241 8.99998 1.47241C13.027 1.47241 16.2916 4.737 16.2916 8.76408C16.2916 12.7911 13.027 16.0557 8.99998 16.0557C4.9729 16.0557 1.70831 12.7911 1.70831 8.76408Z"
        fill="#F5E003"
      />
    </svg>
  );

  const dontsIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_3761_10752)">
        <path
          d="M17.6481 15.5594L9.7184 1.17668C9.57408 0.914942 9.29889 0.752411 9.00003 0.752411C8.70116 0.752411 8.42597 0.914942 8.28165 1.17668L0.351965 15.5594C0.211856 15.8135 0.216395 16.1227 0.363997 16.3726C0.511598 16.6224 0.780169 16.7757 1.07034 16.7757H16.9297C17.2199 16.7757 17.4885 16.6224 17.6361 16.3726C17.7836 16.1227 17.7882 15.8135 17.6481 15.5594ZM2.45935 15.1351L9.00003 3.27175L15.5407 15.1352H2.45935V15.1351Z"
          fill="#FF3236"
        />
        <path
          d="M7.47267 9.19436C7.25912 8.98086 6.91284 8.98086 6.69923 9.19436C6.48567 9.40791 6.48567 9.7542 6.69923 9.9678L8.2266 11.4952L6.69923 13.0225C6.48567 13.2361 6.48567 13.5824 6.69923 13.796C6.80603 13.9027 6.94598 13.9562 7.08592 13.9562C7.22587 13.9562 7.36587 13.9027 7.47262 13.796L9.00004 12.2686L10.5274 13.7959C10.6342 13.9027 10.7742 13.9561 10.9141 13.9561C11.054 13.9561 11.194 13.9027 11.3008 13.7959C11.5144 13.5824 11.5144 13.2361 11.3008 13.0225L9.77343 11.4951L11.3008 9.96775C11.5144 9.75419 11.5144 9.40791 11.3008 9.1943C11.0872 8.9808 10.741 8.9808 10.5274 9.1943L9.00004 10.7217L7.47267 9.19436Z"
          fill="#FF3236"
        />
      </g>
      <defs>
        <clipPath id="clip0_3761_10752">
          <rect width="17.5" height="17.5" fill="white" transform="translate(0.25 0.0140686)" />
        </clipPath>
      </defs>
    </svg>
  );

  const doscontentTexts = [
    <>
      Include specific details such as <span>zone, countries, brands, and time period</span> in your question for
      precise results.
    </>,
    <>
      Utilize <span>proper grammar and clear phrasing</span> to ensure your query is easily understood.
    </>,
    <>
      Clearly <span>explain any specialized terms</span> or jargon the first time you use them to ensure clarity.
    </>,
    <>
      <span>Offer feedback</span> directly to the response to help us improve our service.
    </>,
    <>
      <span>Indicate your preferred response length</span> to ensure it meets your needs.
    </>,
  ];

  const dontscontentTexts = [
    <>
      <span>Don&apos;t ask multiple questions</span> in a single query; separate them for better responses.
    </>,
    <>
      Avoid asking for information you <span>don&apos;t have permission to access.</span>
    </>,
    <>
      <span>Don&apos;t expect instant responses </span>for complex queries; allow ~30 seconds for simple and ~3min for
      complex questions.
    </>,
    <>
      <span>Don&apos;t ask questions unrelated to AB InBev&apos;s scope of data</span> Refer “Know your data” for more
      help.
    </>,
  ];

  return (
    <>
      <div className="IC-Onboarding-main" style={{ justifyContent: step == 1 ? 'center' : 'start' }}>
        <div className="IC-Onboarding-user-info">
          <LogoComponent />
          <div className="IC-Onboarding-user-title">
            <p>
              Hello <span>{username}</span>!
            </p>
          </div>
          <div className="IC-Onboarding-user-description">
            I am ABI Insights Copilot, an advanced AI assistant designed to provide you with precise AB InBev-related
            insights. I can answer simple as well as complex questions from both structured and unstructured data across
            multiple domains to help you take faster data-driven decisions.
          </div>

          {step === 1 && (
            <div className="get-started" onClick={handleNextStep}>
              Get started
              <div className="sparks">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path
                    d="M10.5104 6.86297C8.57091 6.45638 7.05494 4.94041 6.64841 3.00085C6.59847 2.76276 6.38847 2.59219 6.14513 2.59219C5.90178 2.59219 5.69178 2.76276 5.64188 3.00088C5.23531 4.94041 3.71934 6.45635 1.77981 6.86288C1.54169 6.91279 1.37109 7.12282 1.37109 7.36613C1.37109 7.60944 1.54166 7.81947 1.77981 7.86938C3.71931 8.27597 5.23522 9.79191 5.64178 11.7314C5.69169 11.9696 5.90169 12.1402 6.14503 12.1402C6.38834 12.1402 6.59838 11.9696 6.64828 11.7314C7.05488 9.79191 8.57088 8.27597 10.5104 7.86947C10.7485 7.81957 10.9191 7.60957 10.9191 7.36622C10.9191 7.12291 10.7485 6.91288 10.5104 6.86297Z"
                    fill="black"
                  />
                  <path
                    d="M14.2479 11.678C13.3341 11.4865 12.6199 10.7722 12.4283 9.85833C12.3784 9.62021 12.1684 9.44962 11.925 9.44962C11.6817 9.44962 11.4717 9.62015 11.4218 9.8583C11.2302 10.7721 10.5159 11.4864 9.60208 11.678C9.36395 11.7279 9.19336 11.9379 9.19336 12.1812C9.19336 12.4245 9.36392 12.6346 9.60208 12.6845C10.5159 12.876 11.2301 13.5903 11.4217 14.5041C11.4716 14.7423 11.6816 14.9129 11.925 14.9129C12.1683 14.9129 12.3783 14.7423 12.4282 14.5041C12.6198 13.5903 13.3341 12.8761 14.2479 12.6845C14.486 12.6346 14.6566 12.4246 14.6566 12.1812C14.6566 11.9379 14.4861 11.7279 14.2479 11.678Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>

        {step > 1 && (
          <div className="IC-Onboarding-Acknowledge" ref={acknowledgeRef}>
            <p
              style={{
                fontSize: 'var(--Sub-20)',
                fontWeight: '600',
                lineHeight: '136%',
              }}
            >
              {' '}
              Great!
            </p>
            <div className="IC-Onboarding-Acknowledge-container">
              <div className="IC-Onboarding-Acknowledge-info">
                Now let&apos;s start with some basics for asking questions. First, please review and acknowledge these
                simple Do&apos;s and Don&apos;ts.
              </div>
              <div className="IC-Onboarding-Acknowledge-data">
                <AcknowledgeContainer
                  headerIcon={dosIcon}
                  headerText="Here are some Do's while asking questions"
                  contentTexts={doscontentTexts}
                  onAllCheckedChange={handleDosAllCheckedChange}
                />
                <div className="line"></div>
                <AcknowledgeContainer
                  headerIcon={dontsIcon}
                  headerText="Here are some Don'ts while asking questions"
                  contentTexts={dontscontentTexts}
                  iconStyle={{
                    background: theme === 'light-theme' ? '#EBEBEB' : '#3D252E',
                  }}
                  onAllCheckedChange={handleDontsAllCheckedChange}
                />
              </div>

              <div className="IC-Onboarding-Acknowledge-button">
                <p>
                  {' '}
                  If you have any doubt or query, please reach out to <span> kartik.sangani@ab-inbev.com </span>
                </p>
                {step == 2 && (
                  <button
                    style={{ cursor: 'pointer' }}
                    disabled={!dosAllChecked || !dontsAllChecked}
                    onClick={handleNextStep}
                  >
                    Acknowledge & Continue
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {step > 2 && (
          <div ref={containerRef}>
            <OnboardingContainer />
          </div>
        )}
        {showPopup && (
          <div className="popupBG">
            <div className="popup" style={{ maxWidth: '758px' }}>
              <div className="popupHeader">
                <p className="popup-title" style={{ color: 'vaar(--neutral-0)' }}>
                  Insights Copilot Terms and Conditions
                </p>
                <span className="closeIcon">
                  <i className="fa-solid fa-xmark" onClick={handleClose} tabIndex={0}></i>
                </span>
              </div>
              <div
                className="popup-response-answer terms"
                style={{
                  maxHeight: '320px',
                  overflow: 'auto',
                  background: 'var(--Secondary-Grey-900)',
                  padding: 'var(--M, 16px) var(--XS, 8px) var(--M, 16px) var(--M, 16px)',
                  borderRadius: '4px',
                }}
              >
                <div className="IC-Onboarding-terms">
                  <Notice
                    header="Terms of Use"
                    info='By using this Insights Copilot tool you agree to use it in accordance with the <span><a href="/terms-of-use">Terms of Use.</a></span> In particular, you acknowledge and agree that:'
                  />
                  <ul
                    style={{
                      padding: '0 16px 0',
                      listStyleType: 'inherit',
                      color: 'var(--Greys-Top-0)',
                      lineHeight: '120%',
                      paddingBottom: 0,
                    }}
                  >
                    <li style={{ fontSize: 'var(--Info-14)' }}>
                      Your use of Insights Copilot will always be in compliance with applicable law, regulations, ABI
                      policies and contractual obligations.
                    </li>
                    <li style={{ fontSize: 'var(--Info-14)' }}>
                      You will not input trade secrets or other non-public, sensitive, proprietary, commercial
                      information, business plans, strategies or confidential information into Insights Copilot.
                    </li>
                    <li style={{ fontSize: 'var(--Info-14)' }}>
                      No content generated through Insights Copilot will be distributed or made available outside of ABI
                      (such as brands, brand labels, claims, slogans, commercials, ad copy, social media posts, etc.).
                    </li>
                    <li style={{ fontSize: 'var(--Info-14)' }}>
                      Do not use Insights Copilot to seek or provide legal, tax, regulatory, health, or medical advice.
                    </li>
                    <li style={{ fontSize: 'var(--Info-14)' }}>
                      You will not consider or hold out the responses generated by Insights Copilot as the position of
                      ABI, nor use the responses to make public statements on behalf of the company.
                    </li>
                    <li style={{ fontSize: 'var(--Info-14)' }}>
                      You are responsible for verifying the accuracy, fairness, and appropriateness of all output
                      generated by Insights Copilot before use. Do not assume that such output will be accurate, can be
                      lawfully used by ABI or is without bias.
                    </li>
                    <li style={{ fontSize: 'var(--Info-14)' }}>
                      You will be transparent about your use of Insights Copilot in your work.
                    </li>
                  </ul>

                  <Notice
                    header="Privacy Policy"
                    info='By using Insights Copilot, you agree to the collection and processing of your personal data as described in the <span><a href="/privacy-policy">Privacy Policy.</a></span> In particular, you acknowledge that:'
                  />
                  <ul
                    style={{
                      padding: '0 16px 0',
                      listStyleType: 'inherit',
                      color: 'var(--Greys-Top-0)',
                      lineHeight: '120%',
                      paddingBottom: 0,
                    }}
                  >
                    <li style={{ fontSize: 'var(--Info-14)' }}>Your Single Sign-On credentials;</li>
                    <li style={{ fontSize: 'var(--Info-14)' }}>Your name, e-mail, country and zone; and</li>
                    <li style={{ fontSize: 'var(--Info-14)' }}>Your queries to Insights Copilot.</li>
                  </ul>
                </div>
              </div>
              <div className="popupFooter" style={{ flexDirection: 'column', gap: '24px', marginTop: '24px' }}>
                <label style={{ width: '100%', flex: 1, gap: '4px', display: 'flex', alignItems: 'baseline' }}>
                  <input type="checkbox" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
                  <p style={{ fontSize: 'var(--Paragraph-18)', color: 'rgba(255, 255, 255, 0.90)' }}>
                    <span style={{ fontWeight: '600' }}>I agree to the terms and conditions above.</span>
                  </p>
                </label>

                <div
                  className="IC-Onboarding-Acknowledge-button"
                  style={{ justifyContent: 'flex-end', flexDirection: 'row' }}
                >
                  <button onClick={handleAgreeTerms} disabled={!isChecked} style={{ cursor: 'pointer' }}>
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Onboarding;
