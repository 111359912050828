import React, { useState } from 'react';

import './HowItWork.css';
const SampleQuestion = () => {
  const [activeTab, setActiveTab] = useState('maz-com');

  const handleTabClick = (tabName: any) => {
    setActiveTab(tabName);
  };

  const Mazquestions = [
    'What is the total sales volume in Mexico last month?',
    'What is the volume share of premium segment out of all segments?',
    'What is the region with the highest sales volume in 2023 in Mexico?',
    'What is the volume per customer in Mexico in Jul 2023?',
    'Which regions have the maximum number of customers?',
    'Which region has lost the highest number of customers?',
    'What is the NR/HL in Mexico?',
    'What is the Net Revenue per Hectoliter for the premium segment?',
    'What are the brands that have the highest profit margins in the MainStream segment?',
    'What are the best-selling brands this year?',
    'What has been the percentage growth in sales for the Premium segment in 2023 Aug compared to 2023 Jul?',
    'Which region has the highest demand for premium products?',
    'Which brands have generated the highest total revenue in 2023?',
    'What is the total revenue of the top 5 Main Stream brands?',
    'Which beer brands have observed the highest percentage growth in sales volume in 2023 Jul compared to the previous month?',
    'What is the total sales amount in the last month?',
    'What is the best-selling product in Mexico?',
    'What is the total sales volume by regions in Mexico?',
    'How many customers are present in the system who have no transactions in 2023?',
    'What is the total sales quantity by regions?',
    'Which customer has made the most purchases in the last month?',
    'Which SKU name has the highest total sales quantity?',
    'What is the total sales amount by regions in Mexico?',
    'What is the most popular SKU in terms of units sold?',
    'Which region has the highest number of issued invoices?',
  ];

  // You can now use this 'questions' array as needed in your code.
  const CPAquestions = [
    'What is the total volume sold by brand corona in the time period LP TY?',
    'What are the top 5 brands with the highest total volume in area 4?',
    'What is the total revenue sold by brand Heineken in  the time period LP TY? ',
  ];

  const Logisticsquestions = [
    'What do I do if my truck breaks down?',
    'When should I call refusals into the dispatch?',
    'What are quality issues to look for when delivering to a customer?',
  ];

  const Procurementquestions = [
    'What is the revenue for Alcoa in quarter 4 of 2022?',
    'What contributed to losses for Alcoa in 2022?',
    'What is YoY growth of Alcoa in 2022?',
  ];

  const Marketingquestions = [
    'Summarize evolution of power for ABI brands in 2023 Q1 compared to same duration last year?',
    'Which price tier contributed to gains and loss in Power?',
  ];

  const Financequestions = [
    'What is the percentage of the number of invoices paid on time in US in 2023?',
    'What are the delay reasons for documents Not Paid on Time in US ?',
  ];

  return (
    <>
      <section className="sampQues">
        {/* Tab buttons */}
        <div className="sampQues-tab-buttons">
          <button onClick={() => handleTabClick('maz-com')} className={activeTab === 'maz-com' ? 'active' : ''}>
            MAZ Commercial
          </button>
          <button onClick={() => handleTabClick('cpa-dom')} className={activeTab === 'cpa-dom' ? 'active' : ''}>
            CP&A
          </button>
          <button onClick={() => handleTabClick('log-dom')} className={activeTab === 'log-dom' ? 'active' : ''}>
            Logistics
          </button>
          <button onClick={() => handleTabClick('pro-dom')} className={activeTab === 'pro-dom' ? 'active' : ''}>
            Procurement
          </button>
          <button onClick={() => handleTabClick('mar-dom')} className={activeTab === 'mar-dom' ? 'active' : ''}>
            Marketing
          </button>
          <button onClick={() => handleTabClick('fin-dom')} className={activeTab === 'fin-dom' ? 'active' : ''}>
            Finance
          </button>
        </div>
        {/* Tab content */}
        <div className="tab-content">
          {activeTab === 'maz-com' && (
            <div className="contentInner">
              <ul>
                {Mazquestions.map((question, index) => (
                  <li key={index}>
                    <p>{question}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}{' '}
          {activeTab === 'cpa-dom' && (
            <div className="contentInner">
              <ul>
                {CPAquestions.map((question, index) => (
                  <li key={index}>
                    <p>{question}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}{' '}
          {activeTab === 'log-dom' && (
            <div className="contentInner">
              <ul>
                {Logisticsquestions.map((question, index) => (
                  <li key={index}>
                    <p>{question}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}{' '}
          {activeTab === 'pro-dom' && (
            <div className="contentInner">
              <ul>
                {Procurementquestions.map((question, index) => (
                  <li key={index}>
                    <p>{question}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}{' '}
          {activeTab === 'mar-dom' && (
            <div className="contentInner">
              <ul>
                {Marketingquestions.map((question, index) => (
                  <li key={index}>
                    <p>{question}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}{' '}
          {activeTab === 'fin-dom' && (
            <div className="contentInner">
              <ul>
                {Financequestions.map((question, index) => (
                  <li key={index}>
                    <p>{question}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}{' '}
        </div>
      </section>
    </>
  );
};

export default SampleQuestion;
