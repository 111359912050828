import React from 'react';

const AccordianHeaderIcon: React.FC = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <mask id="mask0_7232_122176" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
          <rect width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_7232_122176)">
          <path
            className="svg-icon-path"
            d="M12.6668 14C12.2335 14 11.8446 13.875 11.5002 13.625C11.1557 13.375 10.9168 13.0556 10.7835 12.6667H7.3335C6.60016 12.6667 5.97238 12.4056 5.45016 11.8833C4.92794 11.3611 4.66683 10.7333 4.66683 10C4.66683 9.26667 4.92794 8.63889 5.45016 8.11667C5.97238 7.59444 6.60016 7.33333 7.3335 7.33333H8.66683C9.0335 7.33333 9.34738 7.20278 9.6085 6.94167C9.86961 6.68056 10.0002 6.36667 10.0002 6C10.0002 5.63333 9.86961 5.31944 9.6085 5.05833C9.34738 4.79722 9.0335 4.66667 8.66683 4.66667H5.21683C5.07238 5.05556 4.83072 5.375 4.49183 5.625C4.15294 5.875 3.76683 6 3.3335 6C2.77794 6 2.30572 5.80556 1.91683 5.41667C1.52794 5.02778 1.3335 4.55556 1.3335 4C1.3335 3.44444 1.52794 2.97222 1.91683 2.58333C2.30572 2.19444 2.77794 2 3.3335 2C3.76683 2 4.15294 2.125 4.49183 2.375C4.83072 2.625 5.07238 2.94444 5.21683 3.33333H8.66683C9.40016 3.33333 10.0279 3.59444 10.5502 4.11667C11.0724 4.63889 11.3335 5.26667 11.3335 6C11.3335 6.73333 11.0724 7.36111 10.5502 7.88333C10.0279 8.40556 9.40016 8.66667 8.66683 8.66667H7.3335C6.96683 8.66667 6.65294 8.79722 6.39183 9.05833C6.13072 9.31945 6.00016 9.63333 6.00016 10C6.00016 10.3667 6.13072 10.6806 6.39183 10.9417C6.65294 11.2028 6.96683 11.3333 7.3335 11.3333H10.7835C10.9279 10.9444 11.1696 10.625 11.5085 10.375C11.8474 10.125 12.2335 10 12.6668 10C13.2224 10 13.6946 10.1944 14.0835 10.5833C14.4724 10.9722 14.6668 11.4444 14.6668 12C14.6668 12.5556 14.4724 13.0278 14.0835 13.4167C13.6946 13.8056 13.2224 14 12.6668 14ZM3.3335 4.66667C3.52238 4.66667 3.68072 4.60278 3.8085 4.475C3.93627 4.34722 4.00016 4.18889 4.00016 4C4.00016 3.81111 3.93627 3.65278 3.8085 3.525C3.68072 3.39722 3.52238 3.33333 3.3335 3.33333C3.14461 3.33333 2.98627 3.39722 2.8585 3.525C2.73072 3.65278 2.66683 3.81111 2.66683 4C2.66683 4.18889 2.73072 4.34722 2.8585 4.475C2.98627 4.60278 3.14461 4.66667 3.3335 4.66667Z"
            fill="white"
          />
        </g>
      </svg>
    </>
  );
};

export default AccordianHeaderIcon;
