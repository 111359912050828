import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setIsMenuOpen, setIsSettingsOpen } from '../../redux/features/userSlice';
import { AppDispatch, RootState } from '../../redux/store';

import './Menu.css';

const Menu = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isMenuOpen = useSelector((state: RootState) => state.user.isMenuOpen);
  const theme = useSelector((state: RootState) => state.user.theme);
  const isSettingsOpen = useSelector((state: RootState) => state.user.isSettingsOpen);
  return (
    <>
      <div className="settings-container">
        <div
          className="settings-button"
          style={{
            background: theme === 'light-theme' ? 'none' : '',
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (isSettingsOpen) {
              dispatch(setIsSettingsOpen(false));
            }
            dispatch(setIsMenuOpen(!isMenuOpen));
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path
              className="svg-icon-path"
              d="M2.75 16.5H19.25V14.6667H2.75V16.5ZM2.75 11.9167H19.25V10.0833H2.75V11.9167ZM2.75 5.5V7.33333H19.25V5.5H2.75Z"
              fill="#CDCDCD"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default Menu;
