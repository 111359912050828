import { useCallback, useEffect, useRef, useState } from 'react';
import * as signalR from '@microsoft/signalr';

import * as apiService from '../services/apiService';

type Props = {
  enableAutomaticRefreshAfterMins?: number;
};

const useSignalR = ({ enableAutomaticRefreshAfterMins = 40 }: Props) => {
  const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
  const connectionRef = useRef(connection);

  const refreshConnection = useCallback(async () => {
    const { data } = await apiService.negotiateSignalR();
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(data.url, { accessTokenFactory: () => data.accessToken })
      .withAutomaticReconnect([0, 2000, 10000]) // Custom reconnect intervals
      .configureLogging(signalR.LogLevel.Information)
      .build();
    try {
      await newConnection.start();
      newConnection.onclose(async (error) => {
        if (error?.message.includes('401') || error?.message.includes('404')) {
          console.log('SignalR connection closed due to 401/404, refreshing connection', error);
          await refreshConnection();
        } else {
          console.error('SignalR connection closed', error);
        }
      });
      const existingConnection = connectionRef.current;
      if (existingConnection) {
        // Don't await this, we don't want to block creation of new connection
        existingConnection.stop().catch(console.error);
      }
      connectionRef.current = newConnection; // Ref won't cause closure issues
      setConnection(newConnection); // It will trigger re-render
      if (enableAutomaticRefreshAfterMins) {
        setTimeout(() => refreshConnection(), enableAutomaticRefreshAfterMins * 60 * 1000);
      }
    } catch (error) {
      console.error('Error starting SignalR connection', error);
      if (error instanceof Error && (error.message.includes('401') || error.message.includes('404'))) {
        await refreshConnection();
      } else {
        throw error;
      }
    }
  }, []);

  useEffect(() => {
    refreshConnection().catch(console.error);
    return () => {
      const existingConnection = connectionRef.current;
      if (existingConnection) {
        existingConnection.stop();
      }
    };
  }, []);

  return { connection, refreshConnection };
};

export default useSignalR;
