import { Component } from 'react';
import type { ErrorInfo, ReactNode } from 'react';

import styles from './ErrorBoundary.module.css';

type Props = {
  fallback?: ReactNode;
  children?: ReactNode;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error('[Error]', error);
    console.error('[Error Info]', info);
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return <>{this.props.fallback}</>;
      }
      return (
        <div className={styles.root}>
          <div className={styles.container}>
            <img src="/images/error-boundary-no-data.svg" alt="Error" className={styles.image} />
            <div className={styles.title}>Something didn&apos;t go as planned.</div>
            <div className={styles.subtitle}>
              It seems there&apos;s been an unexpected error. We&apos;re sorry for the inconvenience. Try refreshing the
              page, or get back to the home screen.
            </div>
            <a href="/" className={styles.link}>
              Return to Home
            </a>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export { ErrorBoundary };
