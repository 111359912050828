import React from 'react';

import './Situations.css';
interface QuestionSituationProps {
  title: string;
  info: string;
}

const QuestionSituation: React.FC<QuestionSituationProps> = ({ title, info }) => {
  return (
    <div className="IC-Onboarding-question-situation">
      <div className="IC-Onboarding-question-situation-title">
        <p>{title}</p>
      </div>
      <div className="IC-Onboarding-questions-info">{info}</div>
    </div>
  );
};

export default QuestionSituation;
