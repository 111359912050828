import { ParsedEngineResponse } from '../../../services/apiService/definitions/types';
import { Source } from '../definitions/types';

export const getSourcesFromResponse = (engineResponse?: ParsedEngineResponse | null): Source[] => {
  const response = engineResponse?.response ?? [];
  const subHeadings = response.slice(1, -1);
  const allCitations = subHeadings.map((subHeading) => subHeading.citations).flat();
  const allSources: Source[] = [];
  const dedupSet = new Set();
  allCitations.forEach((citation) => {
    if (!citation) return;
    if (typeof citation === 'string') {
      const dedupKey = `url-${citation}`;
      if (!dedupSet.has(dedupKey)) {
        allSources.push({
          type: 'url',
          url: citation,
        });
      }
      dedupSet.add(dedupKey);
    } else if (citation.mode === 'reasoning') {
      citation.table_references?.forEach((tableReference) => {
        const dedupKey = `table-${tableReference}`;
        if (!dedupSet.has(dedupKey)) {
          allSources.push({
            type: 'table',
            displayName: tableReference.tablename,
          });
        }
        dedupSet.add(dedupKey);
      });
    }
  });
  engineResponse?.table_references?.forEach((tableReference) => {
    const dedupKey = `table-${tableReference.tablename}`;
    if (!dedupSet.has(dedupKey)) {
      allSources.push({
        type: 'table',
        displayName: tableReference.tablename,
      });
    }
    dedupSet.add(dedupKey);
  });
  return allSources;
};
