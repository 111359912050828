import { clsx } from 'clsx';

// import { Pill } from '../../Pill';
import { Slider } from '../../Slider';
import { Source } from '../definitions/types';

import styles from './SourcesList.module.css';

type Props = {
  sources: Source[];
};

export const SourcesList: React.FC<Props> = ({ sources }) => {
  return (
    <Slider
      items={sources.map((source, index) => {
        const isUrlSource = source.type === 'url';
        const isTableSource = source.type === 'table';
        return (
          <div key={index} className={clsx(styles.root, isUrlSource && styles.linkRoot)}>
            <div className={clsx(styles.content, isUrlSource && styles.linkContent)}>
              <div className={styles.friendlyName}>
                {isUrlSource ? 'URL' : source.displayName}
                {/* Pill will be shown once KYD is in place */}
                {/* {isTableSource && <Pill text={source.dbName} variant="primary" />} */}
              </div>
              {isUrlSource ? (
                <div>
                  <a href={source.url} target="_blank" rel="noreferrer" className={styles.link}>
                    {source.url}
                  </a>
                </div>
              ) : isTableSource ? (
                <div className={styles.tableCaption}>Table</div>
              ) : (
                <div className={styles.pdfCaption}>PDF</div>
              )}
            </div>
          </div>
        );
      })}
    />
  );
};
