import styles from './VersionSwitcher.module.css';

type Props = {
  current: number;
  total: number;
  isNextAvailable: boolean;
  isPrevAvailable: boolean;
  onNextClick: () => void;
  onPrevClick: () => void;
};

export const VersionSwitcher = ({
  current,
  total,
  isNextAvailable,
  isPrevAvailable,
  onNextClick,
  onPrevClick,
}: Props) => {
  return (
    <div className={styles.container}>
      <button className={styles.button} disabled={!isPrevAvailable} onClick={onPrevClick}>
        <i className="fa-solid fa-angle-left" />
      </button>
      <div className={styles.text}>
        <span className={styles.currentPosition}>{current}</span>/{total}
      </div>
      <button className={styles.button} disabled={!isNextAvailable} onClick={onNextClick}>
        <i className="fa-solid fa-angle-right" />
      </button>
    </div>
  );
};
