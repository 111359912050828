import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../redux/store';
import { ChatStatusMapping } from '../../../services/apiService/definitions/constants';
import { Spinner } from '../../Spinner';

import './MultiStepIndicator.css';
import styles from './loader.module.css';

type Props = {
  answerNodeId: string;
  nerValues: string[];
};

const MultiStepLoader: React.FC<Props> = ({ answerNodeId, nerValues }) => {
  const conversationTree = useSelector((state: RootState) => state.conversation.conversationTree);

  const answerNode = conversationTree?.nodes[answerNodeId];
  const subStatus = (answerNode?.data.sub_status || []).map((status) => ChatStatusMapping[status]).filter(Boolean);
  const status = subStatus[subStatus.length - 1] || 'Starting Insights Copilot Engine.';
  const subStatusValues = answerNode?.data.sub_status_value || [];

  return (
    <div className={`${styles.root} open`}>
      <div className="current-step">
        <div className="step-div" style={{ display: 'flex', gap: '13px', alignItems: 'center' }}>
          <div className={`step-name`}>{status}</div>
        </div>
        <div style={{ display: 'flex', gap: '21px', alignItems: 'center' }}>
          <Spinner />
        </div>
        <div
          className="line"
          style={{
            position: 'absolute',
            bottom: '0',
            marginTop: '0',
            width: '100%',
            left: '0',
          }}
        ></div>
        <div className="step-completion-line" style={{ width: `${Math.min(1, subStatus.length / 8) * 100}%` }} />
      </div>
      <div className="multistep-dropdown">
        <div className="step ongoing">
          <div className="steps-div" style={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                gap: 'var(--Paragraph-18)',
                alignItems: subStatusValues.length > 0 ? 'baseline' : 'center',
              }}
            >
              <p
                style={{
                  fontSize: 'var(--Paragraph-16)',
                  fontWeight: '600',
                  color: '#B1B1B1',
                  //  flex: '0 0 115px'
                }}
              >
                Analysing the Question
              </p>
              {nerValues.length ? (
                <div
                  className="step-title"
                  style={{
                    display: 'flex',
                    gap: '8px',
                    flexWrap: 'wrap',
                    flex: '1',
                  }}
                >
                  {nerValues.map((item: any, index: number) => (
                    <p className={`${styles.ner_wrapper}`} key={index}>
                      {item}
                    </p>
                  ))}
                </div>
              ) : (
                <div className={styles.dot_wrapper}>
                  <div className={styles.dot_flashing}></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiStepLoader;
