import React from 'react';

const ArrowUp: React.FC = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
        <mask id="mask0_7232_122181" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
          <rect width="16" height="16" transform="matrix(1 0 0 -1 0 16.5)" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_7232_122181)">
          <path
            className="svg-icon-path"
            d="M8 6.24977L4 10.2498L4.93333 11.1831L8 8.11644L11.0667 11.1831L12 10.2498L8 6.24977Z"
            fill="white"
          />
        </g>
      </svg>
    </>
  );
};

export default ArrowUp;
