import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Loader } from './components/Loader';
import { RootState } from './redux/store';
import { logout } from './services/apiService';
import { logoutAndCleanup } from './utils/auth';

const PrivateRoutes = () => {
  const { isLoggedIn, userData } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn && userData && !userData.project_keys.length) {
      logout().finally(() => {
        logoutAndCleanup();
        toast.error('Contact admin to assign a domain.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        navigate('/');
      });
    }
  }, [isLoggedIn, userData]);

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  } else if (!userData) {
    return (
      <div
        style={{
          display: 'flex',
          width: '100vw',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: ' var(--Greys-Base-BG-800)',
        }}
      >
        <Loader />
      </div>
    );
  }

  return <Outlet />;
};

export default PrivateRoutes;
