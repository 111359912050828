import React, { useState } from 'react';

import './HowItWork.css';
const HowItWorks = () => {
  const [selectedBrand, setSelectedBrand] = useState('MAZ Commercial'); // Step 1: State to track selected brand

  const data = [
    { domain: 'CPNA', dataSet: 'CPNA_MO_OMNI_CHANNEL' },
    { domain: 'CPNA', dataSet: 'CPNA_MO_OMNI_CHANNEL_BRAND' },
    { domain: 'CPNA', dataSet: 'CPNA_MO_OMNI_CHANNEL_MANUFACTURER' },
    { domain: 'CPNA', dataSet: 'CPNA_MO_OMNI_CHANNEL_SEGMENT' },
    { domain: 'CPNA', dataSet: 'CPNA_MO_OMNI_CHANNEL_SKU' },
    { domain: 'CPNA', dataSet: 'CPNA_MO_PRICE_SEGMENT_MAPPING' },
    { domain: 'CPNA', dataSet: 'CPNA_MO_TOTAL_AREA_BRAND' },
    { domain: 'CPNA', dataSet: 'CPNA_MO_TOTAL_AREA_MANUFACTURER' },
    { domain: 'CPNA', dataSet: 'CPNA_MO_TOTAL_AREA_SEGMENT' },
    { domain: 'CPNA', dataSet: 'CPNA_MO_TOTAL_AREA_SKU' },
    { domain: 'CPNA', dataSet: 'CPNA_MO_TOTAL_MARKET' },
    { domain: 'CPNA', dataSet: 'CPNA_MO_TOTAL_MARKET_BRAND' },
    { domain: 'CPNA', dataSet: 'CPNA_MO_TOTAL_MARKET_MANUFACTURER' },
    { domain: 'CPNA', dataSet: 'CPNA_MO_TOTAL_MARKET_SEGMENT' },
    { domain: 'CPNA', dataSet: 'CPNA_MO_TOTAL_MARKET_SKU' },
    { domain: 'CPNA', dataSet: 'CPNA_MO_TOTAL_PACK_TYPE_DATA' },
    { domain: 'CPNA', dataSet: 'CPNA_MO_TOTAL_RETAILER_CHANNEL' },
    { domain: 'CPNA', dataSet: 'CPNA_MO_WTD_DIST_FOLDER' },
    { domain: 'CPNA', dataSet: 'INSIGHTS_FEEDBACK' },
    { domain: 'Finance', dataSet: 'GENAI_POT_2023' },
    { domain: 'Finance', dataSet: 'INSIGHTS_FEEDBACK' },
    { domain: 'MAZ Commercial', dataSet: 'INSIGHTS_FEEDBACK' },
    { domain: 'MAZ Commercial', dataSet: 'MX_CUSTOMER' },
    { domain: 'MAZ Commercial', dataSet: 'MX_INVOICE' },
    { domain: 'MAZ Commercial', dataSet: 'MX_PRODUCT' },
    { domain: 'Procurement', dataSet: 'INSIGHTS_FEEDBACK' },
    {
      domain: 'Procurement',
      dataSet: 'PROCUREMENT_CANSHEET_FINANCIAL_KPI_ANNUAL',
    },
    {
      domain: 'Procurement',
      dataSet: 'PROCUREMENT_CANSHEET_FINANCIAL_KPI_QUARTER',
    },
  ];

  const handleBrandSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedBrand(event.target.value);
  };

  // Filtered data based on the selected brand (Step 3)
  const filteredData = data.filter((item) => selectedBrand === '' || item.domain === selectedBrand);

  return (
    <>
      <div className="contentInner">
        <div className="space50"></div>
        <div className="secTitles">
          <p>
            Our user-friendly platform simplifies complex information retrieval processes, making it easy for you to
            harness the full potential of your data. <br />
            Please find below the code flow diagram illustrating how Insights CoPilot generates your insights
          </p>
        </div>
        <div className="space20"></div>
        <div className="howitworkFlow max-80">
          {/* ====Card 1====== */}
          <div className="card_work">
            <div className="befHover">
              <h2 className="cNumber">1</h2>
              <p>
                Enrich Structured Data using BrewDat Data Catalog and store metadata (table and column descriptions) in
                a common Knowledge Store
              </p>
            </div>
            <div className="aftHover">
              <img src="/images/card1.svg" alt="hoverimage" />
            </div>
          </div>
          <img className="arrowDown" src="/images/downArrow.png" alt="hoverimage" />
          {/* ====Card 2====== */}
          <div className="card_work">
            <div className="befHover">
              <h2 className="cNumber">2</h2>
              <p>Enrich Unstructured Data and store embeddings in a common Vector DB</p>
            </div>
            <div className="aftHover">
              <img src="/images/card2.svg" alt="hoverimage" />
            </div>
          </div>
          <img className="arrowDown" src="/images/downArrow.png" alt="hoverimage" />
          {/* ====Card 3====== */}
          <div className="card_work">
            <div className="befHover">
              <h2 className="cNumber">3</h2>
              <p>Enrich Unstructured Data and store embeddings in a common Vector DB</p>
            </div>
            <div className="aftHover">
              <img src="/images/card3.svg" alt="hoverimage" />
            </div>
          </div>
          <img className="arrowDowndouble" src="/images/downDoubleArrow.svg" alt="hoverimage" />
          <div className="row gap30 doubleCards">
            <div className="col-md-6">
              {/* ====Card 4.1====== */}
              <div className="card_work">
                <div className="befHover">
                  <h2 className="cNumber">4.1</h2>
                  <p>4.1 Use GenAI Models to build SQL query based on the Natural Language Query</p>
                </div>
                <div className="aftHover">
                  <img src="/images/card4.1.svg" alt="hoverimage" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {/* ====Card 4.2====== */}
              <div className="card_work">
                <div className="befHover">
                  <h2 className="cNumber">4.2</h2>
                  <p>Enrich Unstructured Data and store embeddings in a common Vector DB</p>
                </div>
                <div className="aftHover">
                  <img src="/images/card4.2.svg" alt="hoverimage" />
                </div>
              </div>
            </div>
          </div>
          <img className="arrowDowndouble1" src="/images/downDoubleArrow1.svg" alt="hoverimage" />
          {/* ====Card 5====== */}
          <div className="card_work">
            <div className="befHover">
              <h2 className="cNumber">5</h2>
              <p>Enrich Unstructured Data and store embeddings in a common Vector DB</p>
            </div>
            <div className="aftHover">
              <img src="/images/card5.svg" alt="hoverimage" />
            </div>
          </div>
        </div>
        {/* <img src="/images/howItWork.svg" width="100%" /> */}
        <div className="space50"></div>
        <div className="secTitles max-80">
          <h2>Learn about the data that drives Insights CoPilot:</h2>
          <p>
            Gain insights into the datasets we use to power our platform. We prioritize data quality and integrity to
            ensure you have the
            <br></br>most accurate information at your fingertips.
          </p>
        </div>
        <div className="space20"></div>
        <div className="tableWrapper max-80">
          <table>
            <thead>
              <tr>
                <th>Domain</th>
                <th>DataSet</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Logistics</td>
                <td>Structured and Unstructured</td>
              </tr>
              <tr>
                <td>Procurement</td>
                <td>Structured and Unstructured</td>
              </tr>
              <tr>
                <td>Finance</td>
                <td>Structured and Unstructured</td>
              </tr>
              <tr>
                <td>CPNA</td>
                <td>Structured</td>
              </tr>
              <tr>
                <td>MAZ Commercial</td>
                <td>Structured</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="space50"></div>
        <div className="tableWrapper max-80">
          <table>
            <thead>
              <tr>
                <th>Domain</th>
                <th>
                  DataSet
                  <select value={selectedBrand} onChange={handleBrandSelect} className="brandSelect">
                    <option value="">All Domains</option>
                    <option value="CPNA">CPNA</option>
                    <option value="Finance">Finance</option>
                    <option value="MAZ Commercial">MAZ Commercial</option>
                    <option value="Procurement">Procurement</option>
                    {/* Add more brands as needed */}
                  </select>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index}>
                  <td>{item.domain}</td>
                  <td>{item.dataSet}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
