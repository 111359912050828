import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setIsMenuOpen } from '../../redux/features/userSlice';
import { AppDispatch, RootState } from '../../redux/store';

import './Menu.css';

const MenuContainer = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isMenuOpen = useSelector((state: RootState) => state.user.isMenuOpen);
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      dispatch(setIsMenuOpen(false));
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const navigateHome = () => {
    navigate('/chat');
  };

  const navigateDiscoverInsights = () => {
    navigate('/chat/discover');
  };

  const navigateKnowYourData = () => {
    navigate('/chat/knowdata');
  };

  return (
    <>
      {isMenuOpen && (
        <div className="Menu-container">
          <div className="settings-menu" ref={menuRef}>
            <div className="settings-option" style={{ display: 'flex', gap: 'var(--xs)' }} onClick={navigateHome}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  className="svg-icon-path"
                  d="M9 4.6425L12.75 8.0175V13.875H11.25V9.375H6.75V13.875H5.25V8.0175L9 4.6425ZM9 2.625L1.5 9.375H3.75V15.375H8.25V10.875H9.75V15.375H14.25V9.375H16.5L9 2.625Z"
                  fill="white"
                />
              </svg>{' '}
              Home
            </div>
            <div
              className="settings-option"
              style={{ display: 'flex', gap: 'var(--xs)' }}
              onClick={navigateDiscoverInsights}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  className="svg-icon-path"
                  d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15ZM4.875 13.125L10.5075 10.5075L13.125 4.875L7.4925 7.4925L4.875 13.125ZM9 8.175C9.4575 8.175 9.825 8.5425 9.825 9C9.825 9.4575 9.4575 9.825 9 9.825C8.5425 9.825 8.175 9.4575 8.175 9C8.175 8.5425 8.5425 8.175 9 8.175Z"
                  fill="white"
                />
              </svg>{' '}
              Discover Insights
            </div>
            <div
              className="settings-option"
              style={{ display: 'flex', gap: 'var(--xs)' }}
              onClick={navigateKnowYourData}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  className="svg-icon-path"
                  d="M13.5 15H4.5V12.75H3V15C3 15.825 3.675 16.5 4.5 16.5H13.5C14.325 16.5 15 15.825 15 15V12.75H13.5V15Z"
                  fill="white"
                />
                <path
                  className="svg-icon-path"
                  d="M4.5 3H9.75V6.75H13.5V8.25H15V6L10.5 1.5H4.5C3.675 1.5 3 2.175 3 3V8.25H4.5V3Z"
                  fill="white"
                />
                <path className="svg-icon-path" d="M17.25 9.75H0.75V11.25H17.25V9.75Z" fill="white" />
              </svg>{' '}
              Know Your Data
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MenuContainer;
