import { getCookie } from 'typescript-cookie';

import { Env } from '../../config/env';
import { logoutAndCleanup } from '../../utils/auth';

import {
  ApiResponse,
  ConversationChatRegenerateRequestBody,
  ConversationChatUpdateFeedbackRequestBody,
  ConversationTree,
  ConversationTreeNode,
  CreateConversationChatRequestBody,
  CreateConversationChatResponse,
  CreateNewConversationRequestBody,
  CreateNewConversationResponse,
  ListConversationResponse,
} from './definitions/types';
import { authorizationToken, globalAbortController, setAbortController } from './globals';

const timeoutDuration = 600000; // Adjust the timeout duration as needed

const handleTimeout = () => {
  globalAbortController?.abort();

  console.error('Request timed out');
};

const makeApiCall = async <T = any>(options: {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  params?: Record<string, string>;
  body?: BodyInit | object | null | undefined;
  headers?: HeadersInit;
  signal?: AbortSignal | null | undefined;
  responseType?: 'json' | 'text' | 'blob';
  redirectOn401?: boolean;
}): Promise<ApiResponse<T>> => {
  const { url, method, params, body, headers, signal, responseType = 'json', redirectOn401 = true } = options;
  const abortController = new AbortController();

  setAbortController(abortController); // Update the globalAbortController

  const timeoutId = setTimeout(() => handleTimeout, timeoutDuration);

  try {
    // setGlobalTooltipFlag(false);
    const isLoggedIn = !!getCookie('is_logged_in');
    const urlEffective = new URL(Env.API_BASE_URL + url);
    if (params) {
      Object.keys(params).forEach((key) => urlEffective.searchParams.append(key, params[key]));
    }
    const response = await fetch(urlEffective, {
      method,
      body: body ? JSON.stringify(body) : null,
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorizationToken,
        ...headers,
      },
      signal: signal || abortController.signal,
      credentials: isLoggedIn ? 'include' : undefined,
    });

    clearTimeout(timeoutId);

    if (response.status === 401 && redirectOn401) {
      console.error('Unauthorized response received. Redirecting to login page');
      logoutAndCleanup();
      window.location.href = '/login?force_relogin=true';
    }

    if (!response.ok) {
      throw new Error('API response error');
    }

    const data = (await response[responseType]()) as T;
    return { data, status: response.status };
  } catch (error) {
    clearTimeout(timeoutId);
    throw error;
  }
};

export const listConversations = async (projectKey: string) => {
  return await makeApiCall<ListConversationResponse>({
    url: `/v2/api/conversations/`,
    method: 'GET',
    params: { project_key: projectKey },
  });
};

export const createNewConversation = async (body: CreateNewConversationRequestBody) => {
  return await makeApiCall<CreateNewConversationResponse>({
    url: '/v2/api/conversations/',
    method: 'POST',
    body,
  });
};

export const deleteManyConversations = async (projectKey: string) => {
  return await makeApiCall<string>({
    url: `/v2/api/conversations/`,
    method: 'DELETE',
    params: { project_key: projectKey },
  });
};

export const deleteConversationById = async (id: string) => {
  return await makeApiCall<string>({
    url: `/v2/api/conversations/${id}`,
    method: 'DELETE',
  });
};

export const listConversationChats = async (conversationId: string) => {
  return await makeApiCall<ConversationTree>({
    url: `/v2/api/conversations/${conversationId}/chats`,
    method: 'GET',
  });
};

export const addChatToConversation = async (conversationId: string, body: CreateConversationChatRequestBody) => {
  return await makeApiCall<CreateConversationChatResponse>({
    url: `/v2/api/conversations/${conversationId}/chats`,
    method: 'POST',
    body,
  });
};

export const regenerateChat = async (queryNodeId: string, body: ConversationChatRegenerateRequestBody) => {
  return await makeApiCall<ConversationTreeNode>({
    url: `/v2/api/conversations/chats/${queryNodeId}/regenerate`,
    method: 'POST',
    body,
  });
};

export const updateChatFeedback = async (answerNodeId: string, body: ConversationChatUpdateFeedbackRequestBody) => {
  return await makeApiCall<ConversationTreeNode>({
    url: `/v2/api/conversations/chats/${answerNodeId}/feedback`,
    method: 'PATCH',
    body,
  });
};

export const stopChatGeneration = async (answerNodeId: string) => {
  return await makeApiCall<ConversationTreeNode>({
    url: `/v2/api/conversations/chats/${answerNodeId}/stop`,
    method: 'PATCH',
  });
};

export const getdiscoverInsights = async (projectKey: string) => {
  return await makeApiCall({
    url: `/v2/api/usecase-insights/${projectKey}`,
    method: 'GET',
  });
};

export const getFAQQuestions = async (projectKey: string) => {
  return await makeApiCall({
    url: `/v2/api/faq/${projectKey}`,
    method: 'GET',
  });
};

export const getKnowYourData = async (projectKey: string) => {
  return await makeApiCall({
    url: `/v2/api/usecase-datasets/${projectKey}`,
    method: 'GET',
  });
};

export const getUserProfile = async () => {
  return await makeApiCall<{
    username: string;
    image_bytes: string | null;
    email: string;
    user_groups: { id: string; display_name: string; project_name: string }[];
  }>({
    url: '/v1/api/auth/me',
    method: 'GET',
  });
};

export const negotiateSignalR = async () => {
  return await makeApiCall({
    url: '/v1/api/auth/signalr/negotiate',
    method: 'GET',
  });
};

export const logout = async () => {
  return await makeApiCall({
    url: '/v1/api/auth/logout',
    method: 'GET',
  });
};
