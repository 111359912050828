import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchKnowYourData } from '../../redux/features/landingPageSlice';
import { AppDispatch, RootState } from '../../redux/store';
import ArrowButton from '../DiscoverFlow/ArrowButton';

import DataTable from './DataTable';
import ViewMore from './ViewMore';

import './KnowYourData.css';

interface Template {
  dataset_tag: string;
  dataset_name: string;
  dataset_description: string;
  dataset_short_description: string;
  key_data_kpis: [
    {
      kpi_name: string;
      kpi_value: string;
    },
  ];
}

const KnowYourData: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
  const [activeTab, setActiveTab] = useState<string | null>(selectedTemplate?.dataset_name || null);
  const KnowYourData = useSelector((state: RootState) => state.landingPage.knowyourdata);
  const currentProjectKey = useSelector((state: RootState) => state.sideBar.projectKey);
  const userAccount = useSelector((state: RootState) => state.user.userData);

  const dataTables: Template[] = KnowYourData;
  const activeTable = dataTables.find((table) => table.dataset_name === activeTab);

  const handleReadMore = (template: Template) => {
    setSelectedTemplate(template);
    setActiveTab(template.dataset_name);
  };

  const handleBack = () => {
    setSelectedTemplate(null);
  };

  const fetchData = async () => {
    console.log('fetchData');
    if (userAccount) {
      dispatch(fetchKnowYourData({ projectKey: currentProjectKey }));
    }
  };

  useEffect(() => {
    // call fetch data only if dataTables is empty
    if (dataTables.length === 0) {
      fetchData();
    }
  }, [dataTables, currentProjectKey]);

  return (
    <div className="IC-data-main">
      <div className="IC-content">
        {selectedTemplate ? (
          <>
            <div className="IC-view-more">
              <div className="IC-view-more-header">
                <ArrowButton isBackButton onClick={handleBack} />
                <div className="IC-header-tabs">
                  {dataTables.map((table, index) => (
                    <div
                      key={index}
                      className={`IC-tab ${activeTab === table.dataset_name ? 'active' : ''}`}
                      onClick={() => setActiveTab(table.dataset_name)}
                    >
                      {table.dataset_name}
                    </div>
                  ))}
                </div>
              </div>
              <ViewMore dataTables={activeTable} />
            </div>
          </>
        ) : (
          <div className="IC-data-header">
            <div className="IC-discover">
              <ArrowButton onClick={handleBack} />
              <div className="IC-compass">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <mask id="mask0_3221_74200" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                    <rect width="20" height="20" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_3221_74200)">
                    <path
                      className="svg-icon-path"
                      d="M5.00001 18.3332C4.54168 18.3332 4.14932 18.17 3.82293 17.8436C3.49654 17.5172 3.33334 17.1248 3.33334 16.6665V14.1665H5.00001V16.6665H15V14.1665H16.6667V16.6665C16.6667 17.1248 16.5035 17.5172 16.1771 17.8436C15.8507 18.17 15.4583 18.3332 15 18.3332H5.00001ZM3.33334 9.1665V3.33317C3.33334 2.87484 3.49654 2.48248 3.82293 2.15609C4.14932 1.8297 4.54168 1.6665 5.00001 1.6665H11.6667L16.6667 6.6665V9.1665H15V7.49984H10.8333V3.33317H5.00001V9.1665H3.33334ZM0.833344 12.4998V10.8332H19.1667V12.4998H0.833344Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </div>
              <div className="IC-discover-title">Know your data</div>
            </div>
            <div className="line" style={{ marginTop: '16px' }}></div>
            <div className="IC-data-info">
              <p>
                Welcome to the data overview for Insights Copilot. This page aims to explain the data used to generate
                responses and insights, helping you understand the sources and key performance indicators (KPIs)
                leveraged in the process. Our data is organized into three primary tables, each serving a distinct
                purpose in enhancing the capabilities of our AI. Below are the descriptions of these tables along with
                the key inputs they provide.{' '}
              </p>
            </div>
            <div className="IC-data-tables">
              <div className="IC-data-title">Data Tables</div>
              <div className="line"></div>
              {dataTables.map((table, index) => (
                <React.Fragment key={index}>
                  <DataTable table={table} onViewMore={() => handleReadMore(table)} />
                  {index !== dataTables.length - 1 && <div className="line"></div>}
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default KnowYourData;
