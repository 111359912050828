import React, { useEffect, useState } from 'react';
import { differenceInDays, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import useAnalytics from '../../../hooks/useAnalytics';
import { fetchAndLoadConversationChats, setConversationId } from '../../../redux/features/conversationSlice';
import { fetchChatHistory } from '../../../redux/features/historySlice';
import { AppDispatch, RootState } from '../../../redux/store';

import ChatEntry from './ChatEntry';
import HistoryLoader from './Loader';

import styles from './ChartEntry.module.css';

type Props = {
  chatInputRef: React.RefObject<HTMLTextAreaElement>;
};

const ChatHistoryContainer: React.FC<Props> = ({ chatInputRef }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const analytics = useAnalytics();
  const { conversation_id } = useParams<{ conversation_id: string }>();

  const conversationId = useSelector((state: RootState) => state.conversation.conversationId);
  const conversations = useSelector((state: RootState) => state.history.conversations);
  const searchQuery = useSelector((state: RootState) => state.sideBar.searchQuery);
  const historyFetched = useSelector((state: RootState) => state.history.historyFetch);
  const selectedProjectKey = useSelector((state: RootState) => state.sideBar.projectKey);
  const loading = useSelector((state: RootState) => state.history.loading);
  const [filteredChatHistory, setFilteredChatHistory] = useState(conversations);

  const handleChatHistoryClick = async (conversationId: string) => {
    dispatch(setConversationId(conversationId));
    await dispatch(fetchAndLoadConversationChats({ conversationId })).then((result) => {
      if (result.type.endsWith('fulfilled')) {
        analytics.trackChatHistoryClick(conversationId);
        navigate(`/chat/${conversationId}`);
      }
    });
    setTimeout(() => {
      chatInputRef.current?.focus();
    }, 100);
  };

  const categorizeChatHistory = (entries: typeof conversations) => {
    const today: typeof conversations = [];
    const yesterday: typeof conversations = [];
    const past10Days: typeof conversations = [];
    const earlier: typeof conversations = [];

    const now = new Date();

    entries
      .filter((entry) => !!entry.name)
      .forEach((entry) => {
        const entryDate = parseISO(entry.updated_at); // assuming entry.timestamp is an ISO string
        const difference = differenceInDays(now, entryDate);
        if (difference === 0) {
          today.push(entry);
        } else if (difference === 1) {
          yesterday.push(entry);
        } else if (difference <= 10) {
          past10Days.push(entry);
        } else {
          earlier.push(entry);
        }
      });
    return { today, yesterday, past10Days, earlier };
  };

  const groupedChatHistory = categorizeChatHistory(filteredChatHistory);

  const renderChatEntries = (entries: typeof conversations) => {
    return entries.map((entry) => <ChatEntry key={entry.conversation_id} entry={entry} />);
  };

  useEffect(() => {
    if (!historyFetched) {
      dispatch(fetchChatHistory({ projectKey: selectedProjectKey }));
    }
  }, [historyFetched, selectedProjectKey]);

  useEffect(() => {
    if (conversation_id && conversation_id !== conversationId) {
      handleChatHistoryClick(conversation_id);
    }
  }, [conversation_id]);

  useEffect(() => {
    setFilteredChatHistory(
      conversations.filter((entry) => entry.name.toLowerCase().includes(searchQuery.toLowerCase())),
    );
  }, [conversations, searchQuery]);

  return (
    <>
      {loading === 'pending' ? (
        <HistoryLoader />
      ) : (
        <>
          <div className={styles.chat_history_containers}>
            {groupedChatHistory.today.length > 0 && (
              <div>
                <div style={{ padding: '5px 5px 5px 10px' }}>Today</div>
                {renderChatEntries(groupedChatHistory.today)}
              </div>
            )}
            {groupedChatHistory.yesterday.length > 0 && (
              <div style={{ marginTop: '30px' }}>
                <div style={{ padding: '5px 5px 5px 10px' }}>Yesterday</div>
                {renderChatEntries(groupedChatHistory.yesterday)}
              </div>
            )}
            {groupedChatHistory.past10Days.length > 0 && (
              <div style={{ marginTop: '30px' }}>
                <div style={{ padding: '5px 5px 5px 10px' }}>Past 10 Days</div>
                {renderChatEntries(groupedChatHistory.past10Days)}
              </div>
            )}
            {groupedChatHistory.earlier.length > 0 && (
              <div style={{ marginTop: '30px' }}>
                <div style={{ padding: '5px 5px 5px 10px' }}>Last 30 days</div>
                {renderChatEntries(groupedChatHistory.earlier)}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ChatHistoryContainer;
