import { useEffect, useRef } from 'react';

type Props = {
  onClickOutside: () => void;
};

export const useHandleClickOutside = <T extends HTMLElement = HTMLDivElement>({ onClickOutside }: Props) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside, ref]);

  return ref;
};
