import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../redux/store';
import MsgBodyAvatar from '../Core/msgBodyAvatar/MsgBodyAvatar';

import './QuestionBody.css';

interface QuestionBodyProps {
  user: string;
  message: ReactNode;
}

const QuestionBody: React.FC<QuestionBodyProps> = ({ message }) => {
  const theme = useSelector((state: RootState) => state.user.theme);

  return (
    <div className="Onboarding-question-body">
      <div style={{ width: '20px' }}>
        <MsgBodyAvatar />
      </div>
      <div className="msg-content" style={{ width: '100%', letterSpacing: '0.15px' }}>
        <p
          style={{
            color: theme === 'light-theme' ? 'rgba(13, 13, 13, 0.90)' : '',
          }}
        >
          {message}
        </p>
      </div>
    </div>
  );
};

export default QuestionBody;
