import React from 'react';

const FaqLoader: React.FC = () => {
  return (
    <div
      className="faq-loader"
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        className="skeleton-loader"
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '40px',
          justifyContent: 'center',
        }}
      >
        <div className="contain" style={{ width: '100%', maxWidth: '230px' }}>
          <div
            className="skeleton-loader__header shine-animation "
            style={{
              height: '10px',
              marginBottom: '20px',
              borderRadius: '10px',
              width: '50%',
            }}
          ></div>
          <div
            className="skeleton-loader__header shine-animation "
            style={{
              height: '10px',
              marginBottom: '5px',
              borderRadius: '10px',
            }}
          ></div>
          <div
            className="skeleton-loader__content shine-animation "
            style={{
              height: '10px',
              marginBottom: '5px',
              borderRadius: '10px',
            }}
          ></div>
        </div>

        <div className="contain" style={{ width: '100%', maxWidth: '230px' }}>
          <div
            className="skeleton-loader__header shine-animation "
            style={{
              height: '10px',
              marginBottom: '20px',
              borderRadius: '10px',
              width: '50%',
            }}
          ></div>
          <div
            className="skeleton-loader__header shine-animation "
            style={{
              height: '10px',
              marginBottom: '5px',
              borderRadius: '10px',
            }}
          ></div>
          <div
            className="skeleton-loader__content shine-animation "
            style={{
              height: '10px',
              marginBottom: '5px',
              borderRadius: '10px',
            }}
          ></div>
        </div>
        <div className="contain" style={{ width: '100%', maxWidth: '230px' }}>
          <div
            className="skeleton-loader__header shine-animation "
            style={{
              height: '10px',
              marginBottom: '20px',
              borderRadius: '10px',
              width: '50%',
              marginTop: '20px',
            }}
          ></div>
          <div
            className="skeleton-loader__header shine-animation "
            style={{
              height: '10px',
              marginBottom: '5px',
              borderRadius: '10px',
            }}
          ></div>
          <div
            className="skeleton-loader__content shine-animation "
            style={{
              height: '10px',
              marginBottom: '5px',
              borderRadius: '10px',
            }}
          ></div>
        </div>
        <div className="contain" style={{ width: '100%', maxWidth: '230px' }}>
          <div
            className="skeleton-loader__header shine-animation "
            style={{
              height: '10px',
              marginBottom: '20px',
              borderRadius: '10px',
              width: '50%',
              marginTop: '20px',
            }}
          ></div>
          <div
            className="skeleton-loader__header shine-animation "
            style={{
              height: '10px',
              marginBottom: '5px',
              borderRadius: '10px',
            }}
          ></div>
          <div
            className="skeleton-loader__content shine-animation "
            style={{
              height: '10px',
              marginBottom: '5px',
              borderRadius: '10px',
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default FaqLoader;
