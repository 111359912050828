import React, { useEffect, useRef } from 'react';

interface feedprops {
  setShowFeedbackSuccess: (value: boolean) => void;
}

const FeedbackSuccessPopup: React.FC<feedprops> = ({ setShowFeedbackSuccess }) => {
  const popupRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        // Click occurred outside the popup, close it
        setShowFeedbackSuccess(false);
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className="popupBG">
      <div className="success-popup" ref={popupRef}>
        <div className="popupHeader" style={{ justifyContent: 'flex-end' }}>
          <span className="closeIcon">
            <i className="fa-solid fa-xmark" onClick={() => setShowFeedbackSuccess(false)} tabIndex={0}></i>
          </span>
        </div>
        <div className="popup-response-container">
          <div className="success-popup-container">
            <div className="success-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                <mask id="mask0_1756_4944" maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="40">
                  <rect x="0.5" width="40" height="40" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_1756_4944)">
                  <path
                    d="M18.1668 27.6668L29.9168 15.9168L27.5835 13.5835L18.1668 23.0002L13.4168 18.2502L11.0835 20.5835L18.1668 27.6668ZM20.5002 36.6668C18.1946 36.6668 16.0279 36.2293 14.0002 35.3543C11.9724 34.4793 10.2085 33.2918 8.7085 31.7918C7.2085 30.2918 6.021 28.5279 5.146 26.5002C4.271 24.4724 3.8335 22.3057 3.8335 20.0002C3.8335 17.6946 4.271 15.5279 5.146 13.5002C6.021 11.4724 7.2085 9.7085 8.7085 8.2085C10.2085 6.7085 11.9724 5.521 14.0002 4.646C16.0279 3.771 18.1946 3.3335 20.5002 3.3335C22.8057 3.3335 24.9724 3.771 27.0002 4.646C29.0279 5.521 30.7918 6.7085 32.2918 8.2085C33.7918 9.7085 34.9793 11.4724 35.8543 13.5002C36.7293 15.5279 37.1668 17.6946 37.1668 20.0002C37.1668 22.3057 36.7293 24.4724 35.8543 26.5002C34.9793 28.5279 33.7918 30.2918 32.2918 31.7918C30.7918 33.2918 29.0279 34.4793 27.0002 35.3543C24.9724 36.2293 22.8057 36.6668 20.5002 36.6668Z"
                    fill="#44AC21"
                  />
                </g>
              </svg>
            </div>
            <div className="success-message">
              <p className="success-message-feed">Thanks for your feedback! 😇</p>
              <p className="success-message-res">Your feedbacks helps us improve the responses.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackSuccessPopup;
