import { useMemo } from 'react';

import {
  ParsedEngineResponseOutput,
  ParsedReasoningEngineCitation,
} from '../../../services/apiService/definitions/types';
import { Accordion } from '../../Accordion';
import { SQLQuery } from '../SQLQuery';

import styles from './Hypotheses.module.css';

type Props = {
  subHeadings: ParsedEngineResponseOutput[];
};

export const Hypotheses = ({ subHeadings }: Props) => {
  const subHeaderGroups = useMemo(
    () =>
      subHeadings.reduce(
        (acc, item, idx) => {
          const keyName = item.key_name;
          acc[keyName] = acc[keyName] || [];
          acc[keyName].push({
            data: item,
            index: idx,
          });
          return acc;
        },
        {} as Record<
          string,
          {
            data: ParsedEngineResponseOutput;
            index: number;
          }[]
        >,
      ),
    [subHeadings],
  );

  const items = useMemo(() => {
    return Object.entries(subHeaderGroups).map(([header, group]) => {
      const combinedCitations = group
        .map((item) => item.data.citations)
        .flat()
        .filter((item) => typeof item !== 'string' && item?.mode === 'reasoning') as ParsedReasoningEngineCitation[];
      return {
        title: <div className={styles.accordionTitle}>{header.replace(/\(Q[\w., &]+\.\)/g, '').trim()}</div>,
        content: (
          <>
            {combinedCitations.map((citation, idx) => {
              const subQuestion = Array.isArray(citation.sub_question)
                ? citation.sub_question[0]
                : citation.sub_question;
              const subAnswer = Array.isArray(citation.sub_answer) ? citation.sub_answer[0] : citation.sub_answer;
              const sqlQuery = citation.sql_query;
              return (
                <div key={idx}>
                  <div className={styles.questionHeading}>Hypothesis</div>
                  <div className={styles.questionContent}>{subQuestion.replace(/\(Q[\w., &]+\.\)/g, '').trim()}</div>
                  <div className={styles.answerHeading}>Answer</div>
                  <div className={styles.answerContent}>{subAnswer}</div>
                  {sqlQuery && sqlQuery !== 'None' && (
                    <div>
                      <div className={styles.queryHeading}>Associated SQL Query</div>
                      <SQLQuery query={sqlQuery} />
                    </div>
                  )}
                </div>
              );
            })}
          </>
        ),
      };
    });
  }, [subHeaderGroups]);

  return (
    <>
      <div className={styles.heading}>Hypotheses Breakdown</div>
      <Accordion
        items={items}
        multiActive
        initialActiveIndexes={[0]}
        classNames={{
          item: styles.accordionItem,
        }}
      />
    </>
  );
};
