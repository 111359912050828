import React from 'react';

import { EngineChartResults } from '../../services/apiService/definitions/types';

import ChartComponent from './ChartComponent';

import './MainChart.css';

interface Props {
  selectedChartType: string;
  chartResults?: EngineChartResults | null;
  showPopup?: boolean;
  chartRefs: {
    lineChartRef: React.RefObject<any>;
    barChartRef: React.RefObject<any>;
    horizontalBarChartRef: React.RefObject<any>;
    doughnutChartRef: React.RefObject<any>;
    pieChartRef: React.RefObject<any>;
    areaChartRef: React.RefObject<any>;
    stackedBarChartRef: React.RefObject<any>;
    stackedAreaChartRef: React.RefObject<any>;
  };
}

const MainChartComponent: React.FC<Props> = ({ selectedChartType, chartResults, showPopup, chartRefs }) => {
  return (
    <div className="chartDiv">
      <div className="chart-tab-content">
        <div
          className="graphArea"
          style={{
            height: selectedChartType === 'pie' || selectedChartType === 'doughnut' ? '300px' : '',
            maxWidth: selectedChartType === 'pie' || selectedChartType === 'doughnut' ? '450px' : '',
          }}
        >
          {chartResults && (
            <ChartComponent
              chartType={selectedChartType}
              chartData={chartResults}
              showPopup={showPopup}
              chartRefs={chartRefs}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MainChartComponent;
