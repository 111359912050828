import React from 'react';

// SVGIcon.js or SVGIcon.tsx if you're using TypeScript
const OfflineIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
    <mask id="mask0_6935_86351" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_6935_86351)">
      <path
        d="M13.1667 15.0666L6.9 8.76664C6.37778 8.88886 5.89167 9.07219 5.44167 9.31664C4.99167 9.56108 4.58889 9.86664 4.23333 10.2333L2.83333 8.79997C3.18889 8.44442 3.57222 8.13331 3.98333 7.86664C4.39444 7.59997 4.83333 7.36664 5.3 7.16664L3.8 5.66664C3.34444 5.89997 2.91944 6.15831 2.525 6.44164C2.13056 6.72497 1.75556 7.04442 1.4 7.39997L0 5.96664C0.355556 5.61108 0.725 5.29164 1.10833 5.00831C1.49167 4.72497 1.9 4.45553 2.33333 4.19997L0.933333 2.79997L1.86667 1.86664L14.1333 14.1333L13.1667 15.0666ZM8 14C7.53333 14 7.13889 13.8361 6.81667 13.5083C6.49444 13.1805 6.33333 12.7889 6.33333 12.3333C6.33333 11.8666 6.49444 11.4722 6.81667 11.15C7.13889 10.8278 7.53333 10.6666 8 10.6666C8.46667 10.6666 8.86111 10.8278 9.18333 11.15C9.50556 11.4722 9.66667 11.8666 9.66667 12.3333C9.66667 12.7889 9.50556 13.1805 9.18333 13.5083C8.86111 13.8361 8.46667 14 8 14ZM11.9333 10.0333L8.56667 6.66664C9.46667 6.75553 10.3083 6.9833 11.0917 7.34997C11.875 7.71664 12.5667 8.19997 13.1667 8.79997L11.9333 10.0333ZM14.6 7.39997C13.7444 6.54442 12.7528 5.87497 11.625 5.39164C10.4972 4.90831 9.28889 4.66664 8 4.66664C7.76667 4.66664 7.54167 4.67497 7.325 4.69164C7.10833 4.70831 6.88889 4.73331 6.66667 4.76664L4.96667 3.06664C5.45556 2.93331 5.95278 2.83331 6.45833 2.76664C6.96389 2.69997 7.47778 2.66664 8 2.66664C9.57778 2.66664 11.05 2.96108 12.4167 3.54997C13.7833 4.13886 14.9778 4.94442 16 5.96664L14.6 7.39997Z"
        fill="#D53E41"
      />
    </g>
  </svg>
);

export default OfflineIcon;
