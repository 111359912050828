import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import About from '../components/Resources/About';
import FAQAccordion from '../components/Resources/FAQaccordian';
import HowItWorks from '../components/Resources/HowItWork';
import SampleQuestion from '../components/Resources/SampleQuestion';

import './Resources.css';

const Resources = () => {
  const [activeTab, setActiveTab] = useState('about');

  const handleTabClick = (tabName: any) => {
    setActiveTab(tabName);
  };

  const location = useLocation();

  const { sessionId } = location.state || {};

  return (
    <>
      <div className="rtHeader">
        <div className="space20"></div>
        <h2>Resources</h2>
        <p>
          Your hub for comprehensive information about our innovative solution.
          <br></br>
          Explore the following sections to gain a deeper understanding of what we offer and how it can benefit your
          team.
        </p>

        <Link to={sessionId ? `/chat/${sessionId}` : '/chat'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
            <path
              d="M14.1795 10.7752C14.5456 10.4091 14.5456 9.8155 14.1795 9.44939C13.8134 9.08328 13.2199 9.08328 12.8537 9.44939L7.85369 14.4494C7.67063 14.6324 7.5791 14.8724 7.5791 15.1123C7.5791 15.2394 7.6044 15.3607 7.65024 15.4712C7.69599 15.5817 7.7638 15.6853 7.85369 15.7752L12.8537 20.7752C13.2199 21.1413 13.8134 21.1413 14.1795 20.7752C14.5456 20.4091 14.5456 19.8156 14.1795 19.4494L10.7799 16.0498H22.8916C23.4094 16.0498 23.8291 15.6301 23.8291 15.1123C23.8291 14.5946 23.4094 14.1748 22.8916 14.1748H10.7799L14.1795 10.7752Z"
              fill="white"
            />
          </svg>
        </Link>
      </div>
      <section className="resourceTabber">
        {/* Tab buttons */}
        <div className="tab-buttons">
          <button onClick={() => handleTabClick('about')} className={activeTab === 'about' ? 'active' : ''}>
            About Insights CoPilot
          </button>
          <button
            onClick={() => handleTabClick('additionalInfo')}
            className={activeTab === 'additionalInfo' ? 'active' : ''}
          >
            How does it work?
          </button>
          <button onClick={() => handleTabClick('furtherInfo')} className={activeTab === 'furtherInfo' ? 'active' : ''}>
            Sample Questions
          </button>
          <button onClick={() => handleTabClick('faq')} className={activeTab === 'faq' ? 'active' : ''}>
            FAQs
          </button>
        </div>
        {/* Tab content */}
        <div className="tab-content">
          {activeTab === 'about' && <About />} {activeTab === 'additionalInfo' && <HowItWorks />}{' '}
          {activeTab === 'furtherInfo' && <SampleQuestion />} {activeTab === 'faq' && <FAQAccordion />}{' '}
        </div>
      </section>
    </>
  );
};

export default Resources;
