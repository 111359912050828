import { useMemo } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  ModuleRegistry,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import './table.css';
ModuleRegistry.registerModules([ClientSideRowModelModule]);

interface Props {
  result: Array<Record<string, any>>;
}

const GridExample: React.FC<Props> = ({ result }) => {
  if (!result) {
    return <div>No data available</div>; // Handle missing data
  }

  // Dynamically extract column names from the first row of the result
  const columnNames = Object.keys(result[0]);

  // Generate column definitions dynamically
  const columnDefs: ColDef[] = useMemo(() => {
    return columnNames.map((col) => ({
      field: col,
      headerName: col.replace(/_/g, ' ').toUpperCase(),
      sortable: true, // Enable sorting
      sortingOrder: ['asc', 'desc', null], // Show both ascending and descending icons
      unSortIcon: true,
    }));
  }, [columnNames]);

  // Prepare row data
  const rowData = useMemo(() => {
    return result.map((row) => {
      const rowObject: Record<string, unknown> = {};
      columnNames.forEach((col) => {
        const value = row[col];
        rowObject[col] = typeof value === 'number' ? value.toLocaleString() : value;
      });
      return rowObject;
    });
  }, [result, columnNames]);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
    };
  }, []);

  const autoSizeStrategy = useMemo<
    SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy
  >(() => {
    return {
      type: 'fitGridWidth',
      defaultMinWidth: 100,
    };
  }, []);

  // Conditionally enable pagination based on row count
  const shouldPaginate = result.length >= 20;

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div style={{ height: '100%', boxSizing: 'border-box' }}>
        <div className={'ag-theme-quartz'} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            pagination={shouldPaginate} // Conditional pagination
            paginationPageSize={20}
            domLayout={'autoHeight'}
            autoSizeStrategy={autoSizeStrategy}
            rowHeight={30}
          />
        </div>
      </div>
    </div>
  );
};

export default GridExample;
