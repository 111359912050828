import React from 'react';

import './ViewMore.css';
interface ViewMoreProps {
  dataTables: any;
}

const ViewMore: React.FC<ViewMoreProps> = ({ dataTables }) => {
  // const title= dataTables.title;
  const description = dataTables.dataset_description;

  const table_references = dataTables.key_data_kpis;

  return (
    <>
      <div style={{ width: '100%' }}>
        <div className="IC-tab-descripition">{description}</div>
        <div className="key-data" style={{ marginTop: '20px' }}>
          <p>Key Data KPIs</p>
          <div className="key-data-table">
            <div className="table-reference" style={{ marginTop: '14px' }}>
              <div className="mb-2 table-container">
                <table className="custom-table IC-tab">
                  <thead style={{ height: '31px' }}>
                    <tr>
                      <th>KPI Name</th>
                      <th>KPI Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(table_references) ? (
                      table_references.map((table_data, index) => {
                        const { kpi_name, kpi_value } = table_data;

                        return (
                          <tr key={index}>
                            <td>{kpi_name}</td>
                            <td>{kpi_value}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={2}>Invalid table_references format</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewMore;
