import React from 'react';

import LogoComponent from '../LandingScreen/IC-Logo';

import styles from './Terms.module.css';
const PrivacyPolicy = () => {
  return (
    <div
      className="onbording-container"
      style={{
        height: '100vh',
        display: 'flex',
        backgroundColor: 'var(--Greys-Base-BG-800)',
      }}
    >
      <div className="IC-Onboarding-container terms" style={{ maxWidth: '800px' }}>
        <div className="IC-Onboarding-main terms" style={{ justifyContent: 'flex-start' }}>
          <div className="IC-Onboarding-notice-container logo" style={{ marginTop: '0', position: 'relative' }}>
            <LogoComponent
              style={{
                position: 'absolute',
                right: 0,
                left: 0,
                margin: 'auto',
                top: '-50px',
                width: '110px',
                height: '20px',
              }}
            />
          </div>
          <div className="IC-Onboarding-user-title terms">
            <p>Privacy Policy</p>
          </div>
          <div className="IC-Onboarding-terms" style={{ color: 'var(--Greys-Top-0)' }}>
            <ul style={{ listStyleType: 'auto', padding: '20px', lineHeight: '120%' }} className={styles.terms_items}>
              <li className={styles.Terms_tile}>
                <strong>INTRODUCTION AND DATA CONTROLLER IDENTIFICATION </strong>
              </li>
              <li className={styles.Terms_tile}>
                <strong>
                  HOW THE TOOL FUNCTIONS AND WHAT PERSONAL DATA WILL BE COLLECTED, USED AND OTHERWISE PROCESSED{' '}
                </strong>
              </li>
              <ul style={{ listStyleType: 'disc', padding: '15px' }}>
                <li>
                  <strong className={styles.terms_list}>Your Single Sign-On</strong> ,as per our internal parameters, to
                  enable your login in the platform and to monitor its use. Your password will not be recorded by the
                  Tool.
                </li>
                <li>
                  <strong className={styles.terms_list}>Your name, e-mail, country and zone</strong>, to monitor both
                  platform usage and that you are using it according to these Terms of Use, ABI’s internal policies and
                  applicable laws and regulations.
                </li>
                <li>
                  <strong className={styles.terms_list}>
                    Your queries to Insights Copilot [and the corresponding outputs that are generated]
                  </strong>
                  , to monitor your use of the Tool is done according to these Terms of Use, ABI’s internal policies and
                  applicable laws and regulations and to improve the accuracy and quality of the Tool’s responses to
                  queries.
                </li>
                <li>
                  <strong className={styles.terms_list}>
                    Your feedback to the corresponding outputs that are generated
                  </strong>
                  , to improve the functionality of the Tool and user experience.
                </li>
              </ul>
              <li className={styles.Terms_tile}>
                <strong>OUR LEGAL BASIS FOR PROCESSING </strong>
              </li>
              <ul style={{ listStyleType: 'disc', padding: '15px' }}>
                <li>
                  the contractual necessity/performance of contract to allow you to use the Tool in the context of your
                  employment at ABI.
                </li>
                <li>
                  the legitimate interests of ABI to monitor, and improve the Tool, all in accordance with our privacy
                  and security policies and applicable laws and regulations.
                </li>
                <li>
                  the legitimate interests of ABI to use strictly necessary cookies (i.e., for communications to take
                  place over a network and/or to provide a service explicitly requested by you).
                </li>
                <li>your prior consent for ABI to use not strictly necessary cookies.</li>
              </ul>
              <li className={styles.Terms_tile}>
                <strong>RECIPIENTS AND INTERNATIONAL TRANSFERS </strong>
              </li>
              <li className={styles.Terms_tile}>
                <strong>DATA RETENTION AND SECURITY </strong>
              </li>
              <ul style={{ listStyleType: 'disc', padding: '15px' }}>
                <li>By default, your queries are retained for 365 days but you can always delete them in the Tool.</li>
                <li>
                  Any other personal data will be retained no longer than strictly necessary and will be deleted or
                  anonymized when they are no longer necessary.
                </li>
              </ul>
              <li className={styles.Terms_tile}>
                <strong>YOUR RIGHTS AS A DATA SUBJECT </strong>
              </li>
              <ul style={{ listStyleType: 'disc', padding: '15px' }}>
                <li>
                  the right to request confirmation and information on the data processing and a copy of the personal
                  data we are processing about you.
                </li>
                <li>
                  the right to demand that incorrect data are being corrected and data which are inappropriate or no
                  longer needed are being deleted.
                </li>
                <li>
                  the right to request from us to restrict the processing of your data as well as the right to data
                  portability.
                </li>
                <li>
                  the right to object to the processing of your personal data on grounds relating to your situation.
                </li>
                <li>
                  the right to withdraw your consent, without affecting the lawfulness of the processing based on
                  consent before its withdrawal.
                </li>
              </ul>
              <li className={styles.Terms_tile}>
                <strong>CONTACT & GRIEVANCE MECHANISM </strong>
              </li>
              <li className={styles.Terms_tile}>
                <strong>UPDATES </strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
