import { type ReactNode, useCallback, useMemo, useState } from 'react';
import { clsx } from 'clsx';

import ArrowDown from '../Core/AccordianArrows/ArrowDown';
import ArrowUp from '../Core/AccordianArrows/ArrowUp';

import styles from './Accordion.module.css';

type Props = {
  items: (
    | {
        title: ReactNode;
        content: ReactNode;
      }
    | false
  )[];
  // If activeIndex is provided, the accordion will be controlled. If not, it will be uncontrolled.
  activeIndexes?: number[];
  onChangeIndex?: (index: number[]) => void;
  multiActive?: boolean;
  initialActiveIndexes?: number[];
  arrowPosition?: 'left' | 'right';
  classNames?: {
    root?: string;
    item?: string;
  };
};

export const Accordion = ({
  items,
  activeIndexes,
  onChangeIndex,
  multiActive = false,
  initialActiveIndexes,
  arrowPosition = 'left',
  classNames,
}: Props) => {
  const [activeAccordionIndexes, setSelectedAccordionIndex] = useState<number[]>(
    initialActiveIndexes?.filter((x) => x < items.length) || [],
  );

  const onAccordionHeaderClick = useCallback(
    (index: number) => {
      const newIndex = activeAccordionIndexes.includes(index)
        ? activeAccordionIndexes.filter((i) => i !== index)
        : multiActive
          ? [...activeAccordionIndexes, index]
          : [index];
      setSelectedAccordionIndex(newIndex);
      if (onChangeIndex) {
        onChangeIndex(newIndex);
      }
    },
    [activeAccordionIndexes, onChangeIndex],
  );

  const content = useMemo(() => {
    return items
      .filter((item) => item)
      .map((item, index) => {
        if (!item) return null;
        const isActive = (activeIndexes !== undefined ? activeIndexes : activeAccordionIndexes).includes(index);
        return (
          <div key={index} className={clsx(styles.item, classNames?.item)}>
            <div
              className={clsx(styles.title, arrowPosition === 'right' && styles.titleRightArrow)}
              onClick={() => onAccordionHeaderClick(index)}
            >
              {arrowPosition === 'left' ? isActive ? <ArrowUp /> : <ArrowDown /> : null}
              {item.title}
              {arrowPosition === 'right' ? isActive ? <ArrowUp /> : <ArrowDown /> : null}
            </div>
            {isActive && <div>{item.content}</div>}
          </div>
        );
      });
  }, [activeAccordionIndexes, activeIndexes, items, onAccordionHeaderClick]);

  return <div className={classNames?.root}>{content}</div>;
};
