import { createSlice } from '@reduxjs/toolkit';

type NetworkStatusState = {
  isOnline: boolean;
};

const initialState: NetworkStatusState = {
  isOnline: true,
};

const networkStatusSlice = createSlice({
  name: 'networkStatus',
  initialState,
  reducers: {
    setOnline: (state) => {
      state.isOnline = true;
    },
    setOffline: (state) => {
      state.isOnline = false;
    },
  },
});

export const { setOnline, setOffline } = networkStatusSlice.actions;
export default networkStatusSlice.reducer;
