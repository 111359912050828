// NetworkStatus.tsx
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setOffline, setOnline } from './redux/features/networkStatusSlice';

const NetworkStatus: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleOnline = () => {
      dispatch(setOnline());
    };
    const handleOffline = () => {
      dispatch(setOffline());
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Initial check
    if (!navigator.onLine) {
      handleOffline();
    } else {
      handleOnline();
    }

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [dispatch]);

  return null; // This component does not render anything
};

export default NetworkStatus;
