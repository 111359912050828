import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../redux/store';

interface LogoComponentProps {
  style?: React.CSSProperties;
}

const LogoComponent: React.FC<LogoComponentProps> = ({ style }) => {
  const theme = useSelector((state: RootState) => state.user.theme);

  return (
    <img
      src={theme === 'light-theme' ? '/images/IC_landing_logo_light.png' : '/images/IC_landing_logo.png'}
      alt="logo"
      style={style}
    />
  );
};

export default LogoComponent;
