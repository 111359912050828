import React from 'react';
const StopChatIcon = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 12 12" fill="none">
        <mask id="mask0_7110_108936" maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">
          <rect width="12" height="12" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_7110_108936)">
          <path
            d="M2.125 9.1499C1.80833 8.78324 1.55417 8.3749 1.3625 7.9249C1.17083 7.4749 1.05 6.9999 1 6.4999H2.025C2.075 6.86657 2.16667 7.21449 2.3 7.54365C2.43333 7.87282 2.60833 8.1749 2.825 8.4499L2.125 9.1499ZM1 5.4999C1.06667 4.9999 1.19167 4.5249 1.375 4.0749C1.55833 3.6249 1.80833 3.21657 2.125 2.8499L2.825 3.5499C2.60833 3.8249 2.43333 4.12699 2.3 4.45615C2.16667 4.78532 2.075 5.13324 2.025 5.4999H1ZM5.475 10.9749C4.975 10.9249 4.50208 10.8041 4.05625 10.6124C3.61042 10.4207 3.2 10.1749 2.825 9.8749L3.525 9.1499C3.81667 9.36657 4.12292 9.54574 4.44375 9.6874C4.76458 9.82907 5.10833 9.9249 5.475 9.9749V10.9749ZM3.55 2.8499L2.825 2.1249C3.2 1.8249 3.61042 1.57907 4.05625 1.3874C4.50208 1.19574 4.98333 1.0749 5.5 1.0249V2.0249C5.125 2.0749 4.775 2.17074 4.45 2.3124C4.125 2.45407 3.825 2.63324 3.55 2.8499ZM4.75 8.2499V3.7499L8.25 5.9999L4.75 8.2499ZM6.5 10.9749V9.9749C7.50833 9.83324 8.34375 9.3874 9.00625 8.6374C9.66875 7.8874 10 7.00824 10 5.9999C10 4.99157 9.66875 4.1124 9.00625 3.3624C8.34375 2.6124 7.50833 2.16657 6.5 2.0249V1.0249C7.78333 1.16657 8.85417 1.70824 9.7125 2.6499C10.5708 3.59157 11 4.70824 11 5.9999C11 7.29157 10.5708 8.40824 9.7125 9.3499C8.85417 10.2916 7.78333 10.8332 6.5 10.9749Z"
            fill="#FFAD0D"
          />
        </g>
      </svg>
    </>
  );
};

export default StopChatIcon;
