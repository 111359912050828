import React from 'react';

import './DiscoverFlow.css';
interface DiscoverTemplateProps {
  image: string;
  altText: string;
  title: string;
  description: string;
  onReadMore: () => void;
}

const DiscoverTemplate: React.FC<DiscoverTemplateProps> = ({ image, altText, title, description, onReadMore }) => (
  <div className="IC-discover-template">
    {image !== '' && (
      <div className="IC-discover-image">
        <img src={image} alt={altText} />
      </div>
    )}
    <div className="IC-discover-content">
      <div className="IC-discover-title">{title}</div>
      <div className="IC-discover-description">
        <p>
          {description}
          <span onClick={onReadMore}>Read More</span>
        </p>
      </div>
    </div>
  </div>
);

export default DiscoverTemplate;
