import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useAnalytics from '../../../hooks/useAnalytics';
import { addChatToConversation } from '../../../redux/features/conversationSlice';
import { AppDispatch, RootState } from '../../../redux/store';

import styles from './MessageEditableQuery.module.css';

export const MessageEditableQuery: React.FC<{
  queryNodeId: string;
}> = ({ queryNodeId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const analytics = useAnalytics();

  const conversationTree = useSelector((state: RootState) => state.conversation.conversationTree);
  const queryNode = conversationTree?.nodes[queryNodeId];
  const [query, setQuery] = useState(queryNode?.data.query ?? '');
  const [isEditing, setIsEditing] = useState(false);
  const isQuestionSubmitted = useSelector((state: RootState) => state.conversation.isQuestionSubmitted);
  const isSemanticCacheEnabled = useSelector((state: RootState) => state.conversation.isSemanticCacheEnabled);
  const isCacheEnabled = useSelector((state: RootState) => state.conversation.isCacheEnabled);
  const isPro = useSelector((state: RootState) => state.user.isPro);
  const currentProjectKey = useSelector((state: RootState) => state.sideBar.projectKey);

  const onEditSubmit = async () => {
    setIsEditing(false);
    if (conversationTree && queryNode && query.trim() !== '') {
      await dispatch(
        addChatToConversation({
          conversationId: conversationTree.conversation_id,
          body: {
            parent_node_id: queryNode.parent === conversationTree.conversation_id ? null : queryNode.parent,
            query: query,
            is_cached: isCacheEnabled,
            is_semantic_cache: isCacheEnabled ? isSemanticCacheEnabled : false,
            is_pro: isPro,
          },
        }),
      );

      analytics.trackChatSubmitEvent({
        mode: 'edit',
        is_cached: isCacheEnabled,
        is_semantic_cache: isCacheEnabled ? isSemanticCacheEnabled : false,
        conversation_id: conversationTree.conversation_id || '',
        project_key: currentProjectKey,
        is_pro: isPro,
      });
    }
  };

  const onCancel = () => {
    setQuery(conversationTree?.nodes[queryNodeId].data.query ?? '');
    setIsEditing(false);
  };

  useEffect(() => {
    if (isQuestionSubmitted) {
      // If question is being submitting, don't allow edits
      onCancel();
    }
  }, [isQuestionSubmitted]);

  if (!conversationTree) {
    return null;
  }

  return isEditing ? (
    <div className={styles.editableQueryContainer}>
      <textarea
        className={styles.textarea}
        placeholder="Edit your question here"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        ref={inputRef}
      />
      <button className={`${styles['icon-button']} ${styles['cancel-button']}`} onClick={onCancel}>
        <i className="fa-solid fa-close" />
      </button>
      <button
        className={`${styles['icon-button']} ${styles['submit-button']}`}
        onClick={() => onEditSubmit()}
        disabled={isQuestionSubmitted}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 23 22" fill="none">
          <mask id="mask0_2022_83" maskUnits="userSpaceOnUse" x="0" y="-1" width="23" height="23">
            <rect x="0.0625" y="-0.00012207" width="22" height="22" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_2022_83)">
            <path
              className="chat-icon-svg"
              d="M3.08008 17.8528V12.4869L9.5986 10.6983L3.08008 8.90968V3.54382L18.5616 10.6983L3.08008 17.8528Z"
              fill="#818181"
              fillOpacity={query.trim() === '' ? 0.61 : 1}
            />
          </g>
        </svg>
      </button>
    </div>
  ) : (
    <div className={styles.readonlyQueryRoot}>
      <div className={styles.readonlyQueryContainer}>
        <p>{conversationTree.nodes[queryNodeId].data.query}</p>
      </div>
      <button
        className={styles['icon-button']}
        onClick={() => {
          setIsEditing(true);
          setTimeout(() => {
            inputRef.current?.focus();
          }, 500);
        }}
        disabled={isQuestionSubmitted}
      >
        <i className="fa-solid fa-pencil" />
      </button>
    </div>
  );
};
