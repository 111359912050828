import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { RootState } from '../../redux/store';
import { ParsedEngineResponseOutput, ParsedReasoningEngineCitation } from '../../services/apiService/definitions/types';
import { Accordion } from '../Accordion';
import RenderChartDataCard from '../Charts/RenderChartCard';
import AccordianHeaderIcon from '../Core/AccordianArrows/AccordianHeaderIcon';
import ToastIcon from '../Toast/ToastIcon';

import styles from './ResponseRestructure.module.css';

interface Props {
  answerNodeId: string;
}

const Restructure: React.FC<Props> = ({ answerNodeId }) => {
  const theme = useSelector((state: RootState) => state.user.theme);
  const conversationTree = useSelector((state: RootState) => state.conversation.conversationTree);
  const conversationTreeNodeStates = useSelector((state: RootState) => state.conversation.conversationTreeNodeStates);

  const answerNode = conversationTree?.nodes[answerNodeId];
  const answerNodeState = conversationTreeNodeStates[answerNodeId];

  const renderContentWithBold = (content: string) => {
    return content
      .split('**')
      .map((part, idx) =>
        idx % 2 === 0 ? <span key={idx}>{part.replace(/^[_-]+/, '')}</span> : <strong key={idx}>{part}</strong>,
      );
  };

  const renderTextAsList = (text: string, error_flag: boolean) => {
    const parts = text.split('\n\n');
    const initialPart = parts.shift() || '';
    const listItems = parts.map((part) => part.split('\n'));

    const processListItems = (items: string[]) => {
      if (items.length === 0) {
        return null;
      }
      if (items.length === 1) {
        if (items[0].trim().includes(':')) {
          return (
            <p>
              <span style={{ fontWeight: '600' }}>
                {renderContentWithBold(items[0].replace(/^\s*[_-]+/, '').split(':')[0])}
              </span>
              <span>:{renderContentWithBold(items[0].replace(/^\s*[_-]+/, '').split(':')[1])}</span>
            </p>
          );
        } else {
          return (
            <p>
              <span>{renderContentWithBold(items[0].replace(/^\s*[_-]+/, ''))}</span>
            </p>
          );
        }
      }

      const result: JSX.Element[] = [];
      let currentSubList: string[] = [];

      items.forEach((item) => {
        if (item.trim().endsWith(':')) {
          if (currentSubList.length > 0) {
            result.push(
              <ul key={item}>
                {currentSubList.map((subItem, idx) => (
                  <li key={idx}>
                    <span>{renderContentWithBold(subItem.replace(/^\s*[_-]+/, ''))}</span>
                  </li>
                ))}
              </ul>,
            );
            currentSubList = [];
          }
          result.push(
            <p key={item} className="item-header" style={{ fontWeight: '600' }}>
              <span>{renderContentWithBold(item.replace(/^\s*[_-]+/, ''))}</span>
            </p>,
          );
        } else {
          currentSubList.push(item);
        }
      });

      if (currentSubList.length > 0) {
        result.push(
          <ul key="sublist" className="sublist">
            {currentSubList.map((subItem, idx) => (
              <li key={idx}>
                <span>{renderContentWithBold(subItem.replace(/^\s*[_-]+/, ''))}</span>
              </li>
            ))}
          </ul>,
        );
      }
      if (listItems.length > 1) {
        result.push(<div className="line" style={{ marginBottom: '10px' }}></div>);
      }
      return result;
    };

    const initialPartItems = initialPart.includes('\n') ? initialPart.split('\n') : [initialPart];

    return (
      <div>
        <p className={error_flag ? styles.errorStyle : ''}>
          <>
            {error_flag && <ToastIcon />}
            <span>
              {initialPartItems.length > 1 ? (
                <ul>
                  {initialPartItems.map((item, idx) => (
                    <li key={idx}>{renderContentWithBold(item.replace(/^\s*[_-]+/, ''))}</li>
                  ))}
                </ul>
              ) : (
                <>{renderContentWithBold(initialPartItems[0].replace(/^\s*[_-]+/, ''))}</>
              )}
              {error_flag && (
                <>
                  {' '}
                  Kindly refer to{' '}
                  <Link to="/chat/knowdata" className={styles.errorLink}>
                    Know Your Data
                  </Link>{' '}
                  for more information.
                </>
              )}
            </span>
          </>
        </p>
        <div>
          {listItems.map((list) => (
            <>{processListItems(list)}</>
          ))}
        </div>
      </div>
    );
  };

  const renderSummary = () => {
    const response = answerNode?.data.engine_response?.response;
    return (
      <div className={styles.summary}>
        {renderTextAsList(
          Array.isArray(response) ? response[0]?.key_value : '',
          !!answerNode?.data.engine_response?.error_flag || !!answerNodeState?.errorMessage,
        )}
      </div>
    );
  };

  const renderConclusion = () => {
    const response = answerNode?.data.engine_response?.response;
    if (!Array.isArray(response)) {
      return null;
    }
    return (
      <div className="conclusion" style={{ marginTop: '20px' }}>
        <p style={{ marginBottom: '10px' }}> Conclusion</p>
        {renderTextAsList(response[response.length - 1]?.key_value, !!answerNode?.data.engine_response?.error_flag)}
      </div>
    );
  };

  const response = answerNode?.data.engine_response?.response;

  const subHeaders = response?.slice(1, -1) ?? [];
  const subHeaderGroups = subHeaders.reduce(
    (acc, item, idx) => {
      const keyName = item.key_name;
      acc[keyName] = acc[keyName] || [];
      acc[keyName].push({
        data: item,
        index: idx,
      });
      return acc;
    },
    {} as Record<
      string,
      {
        data: ParsedEngineResponseOutput;
        index: number;
      }[]
    >,
  );

  const items = useMemo(() => {
    return Object.entries(subHeaderGroups).map(([header, group]) => {
      // key_value will be same for all the items in the group. Only subquestions & chart/table data will be different.
      const content = group[0].data.key_value
        .split('\n')
        .map((line, idx) => line.trim().length > 0 && <li key={idx}>{renderContentWithBold(line)}</li>);
      const combinedCitations = group
        .map((item) => item.data.citations)
        .flat()
        .filter((item) => typeof item !== 'string' && item?.mode === 'reasoning') as ParsedReasoningEngineCitation[];
      return {
        title: (
          <div className={styles.accordionTitle}>
            <AccordianHeaderIcon />
            {header.replace(/\(Q[\w., &]+\.\)/g, '').trim()}
          </div>
        ),
        content: (
          <div className={styles.accordionContent}>
            <ul>{content}</ul>
            {combinedCitations.map((citation, idx) => {
              return (
                <div key={idx}>
                  <RenderChartDataCard
                    chartResults={citation.chart_results}
                    sqlResult={citation.sql_result as Record<string, unknown>[]}
                    chat_name={answerNode?.data.engine_response?.chat_name ?? ''}
                  />
                </div>
              );
            })}
          </div>
        ),
      };
    });
  }, [subHeaderGroups]);

  return (
    <>
      {Array.isArray(response) && response.length > 1 ? (
        <div style={{ fontWeight: theme === 'light-theme' ? '600' : '' }}>
          {renderSummary()}
          <p style={{ marginTop: '17px' }}>Here&apos;s a deep dive on the matter:</p>
          {subHeaders.length > 0 && (
            <Accordion
              items={items}
              multiActive
              arrowPosition="right"
              classNames={{
                item: styles.accordionItem,
              }}
            />
          )}
          {renderConclusion()}
        </div>
      ) : (
        renderSummary()
      )}
    </>
  );
};

export default Restructure;
