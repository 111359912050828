import { useSelector } from 'react-redux';

import { RootState } from '../../redux/store';

import styles from './Loader.module.css';

export const Loader = () => {
  const theme = useSelector((state: RootState) => state.user.theme);
  return (
    <img
      src={
        theme === 'light-theme'
          ? `${process.env.PUBLIC_URL}/images/IC-Loader-light.gif`
          : `${process.env.PUBLIC_URL}/images/IC-Loader.gif`
      }
      alt="Loading..."
      className={styles.loader}
    />
  );
};
