import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchDiscoverInsights } from '../../redux/features/landingPageSlice';
import { AppDispatch, RootState } from '../../redux/store';

import ArrowButton from './ArrowButton';
import DiscoverTemplate from './DiscoverTemplate';
import ReadMoreTemplate from './ReadMoreTemplate';

import './DiscoverFlow.css';

interface Template {
  image: string;
  altText: string;
  title: string;
  created_at: string;
  short_description: string;
  content: [
    {
      heading: string;
      data: string;
      chart_data: any | null;
    },
  ];
}

const Discover = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
  const DiscoverInsights = useSelector((state: RootState) => state.landingPage.DiscoverInsights);
  const currentProjectKey = useSelector((state: RootState) => state.sideBar.projectKey);
  const userAccount = useSelector((state: RootState) => state.user.userData);

  const detailsRef = useRef<HTMLDivElement>(null);

  const handleReadMore = (template: Template) => {
    setSelectedTemplate(template);

    setTimeout(() => {
      if (detailsRef.current) {
        detailsRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100); // Delay to ensure DOM is updated before scrolling
  };

  const handleBack = () => {
    setSelectedTemplate(null);
  };

  const templates: Template[] = DiscoverInsights;

  const fetchData = async () => {
    if (userAccount) {
      dispatch(
        fetchDiscoverInsights({
          projectKey: currentProjectKey,
        }),
      );
    }
  };

  useEffect(() => {
    // Fetch data only if the templates are not already present
    if (templates.length === 0) {
      fetchData();
    }
  }, [templates, currentProjectKey]);

  return (
    <>
      <div className="IC-discover-main">
        <div className="IC-content">
          {selectedTemplate ? (
            <div ref={detailsRef} className="IC-detailed-view">
              <div className="IC-discover" style={{ alignItems: 'center', marginBottom: '14px' }}>
                <ArrowButton isBackButton onClick={handleBack} />
                <div className="IC-center">
                  <div className="IC-discover-title">{selectedTemplate.title}</div>
                  <div className="IC-discover-curated">
                    <p>
                      Curated <span>Insights Copilot</span>
                    </p>
                    <p>
                      Posted:
                      <span>{selectedTemplate.created_at.split('T')[0]} </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="line"></div>
              <ReadMoreTemplate template={selectedTemplate} />
            </div>
          ) : (
            <>
              <div className="IC-discover">
                <ArrowButton onClick={handleBack} />
                <div className="IC-compass">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <mask id="mask0_3168_54873" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                      <rect width="20" height="20" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_3168_54873)">
                      <path
                        className="svg-icon-path"
                        d="M5.41626 14.5827L11.6663 11.666L14.5829 5.41602L8.33293 8.33268L5.41626 14.5827ZM9.99959 10.8327C9.76348 10.8327 9.56557 10.7528 9.40584 10.5931C9.24612 10.4334 9.16626 10.2355 9.16626 9.99935C9.16626 9.76324 9.24612 9.56532 9.40584 9.4056C9.56557 9.24588 9.76348 9.16602 9.99959 9.16602C10.2357 9.16602 10.4336 9.24588 10.5933 9.4056C10.7531 9.56532 10.8329 9.76324 10.8329 9.99935C10.8329 10.2355 10.7531 10.4334 10.5933 10.5931C10.4336 10.7528 10.2357 10.8327 9.99959 10.8327ZM9.99959 18.3327C8.84682 18.3327 7.76348 18.1139 6.74959 17.6764C5.7357 17.2389 4.85376 16.6452 4.10376 15.8952C3.35376 15.1452 2.76001 14.2632 2.32251 13.2493C1.88501 12.2355 1.66626 11.1521 1.66626 9.99935C1.66626 8.84657 1.88501 7.76324 2.32251 6.74935C2.76001 5.73546 3.35376 4.85352 4.10376 4.10352C4.85376 3.35352 5.7357 2.75977 6.74959 2.32227C7.76348 1.88477 8.84682 1.66602 9.99959 1.66602C11.1524 1.66602 12.2357 1.88477 13.2496 2.32227C14.2635 2.75977 15.1454 3.35352 15.8954 4.10352C16.6454 4.85352 17.2392 5.73546 17.6767 6.74935C18.1142 7.76324 18.3329 8.84657 18.3329 9.99935C18.3329 11.1521 18.1142 12.2355 17.6767 13.2493C17.2392 14.2632 16.6454 15.1452 15.8954 15.8952C15.1454 16.6452 14.2635 17.2389 13.2496 17.6764C12.2357 18.1139 11.1524 18.3327 9.99959 18.3327ZM9.99959 16.666C11.8607 16.666 13.4371 16.0202 14.7288 14.7285C16.0204 13.4368 16.6663 11.8605 16.6663 9.99935C16.6663 8.13824 16.0204 6.56185 14.7288 5.27018C13.4371 3.97852 11.8607 3.33268 9.99959 3.33268C8.13848 3.33268 6.56209 3.97852 5.27043 5.27018C3.97876 6.56185 3.33293 8.13824 3.33293 9.99935C3.33293 11.8605 3.97876 13.4368 5.27043 14.7285C6.56209 16.0202 8.13848 16.666 9.99959 16.666Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                </div>
                <div className="IC-discover-title">Discover Insights</div>
              </div>
              <div className="line"></div>
              <div className="IC-discover-templates">
                {templates.map((template, index) => (
                  <>
                    <DiscoverTemplate
                      key={index}
                      image={template?.image}
                      altText={template?.altText}
                      title={template?.title}
                      description={template?.short_description}
                      onReadMore={() => handleReadMore(template)}
                    />
                    <div className="line" style={{ marginTop: '0' }}></div>
                  </>
                ))}

                {/* <div className="load-more">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <mask id="mask0_3168_54906" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                      <rect width="16" height="16" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_3168_54906)">
                      <path
                        d="M4.66644 13.333V5.98302C4.66644 5.61635 4.79977 5.30524 5.06644 5.04968C5.33311 4.79413 5.64978 4.66635 6.01644 4.66635H13.3331C13.6998 4.66635 14.0137 4.79691 14.2748 5.05802C14.5359 5.31913 14.6664 5.63302 14.6664 5.99968V11.333L11.3331 14.6663H5.99977C5.63311 14.6663 5.31922 14.5358 5.05811 14.2747C4.797 14.0136 4.66644 13.6997 4.66644 13.333ZM1.34978 4.16635C1.28311 3.79968 1.35533 3.46913 1.56644 3.17468C1.77755 2.88024 2.06644 2.69968 2.43311 2.63302L9.66644 1.34968C10.0331 1.28302 10.3637 1.35524 10.6581 1.56635C10.9526 1.77746 11.1331 2.06635 11.1998 2.43302L11.3664 3.33302H9.99977L9.88311 2.66635L2.66644 3.94968L3.33311 7.71635V12.3664C3.15533 12.2663 3.00255 12.133 2.87478 11.9663C2.747 11.7997 2.66644 11.6108 2.63311 11.3997L1.34978 4.16635ZM5.99977 5.99968V13.333H10.6664V10.6664H13.3331V5.99968H5.99977Z"
                        fill="#F5E003"
                      />
                    </g>
                  </svg>{' '}
                  <div className="load-more-text">Load More</div>
                </div> */}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Discover;
