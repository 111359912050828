import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { RootState } from '../../../redux/store';

import styles from './ChartEntry.module.css';

type ChatEntryProps = {
  entry: {
    conversation_id: string;
    project_key: string;
    user_email: string;
    name: string;
    updated_at: string;
  };
};

const ChatEntry: React.FC<ChatEntryProps> = ({ entry }) => {
  const conversationId = useSelector((state: RootState) => state.conversation.conversationId);
  const theme = useSelector((state: RootState) => state.user.theme);

  return (
    <>
      <Link
        to={`${conversationId === entry.conversation_id ? '' : '/chat/' + entry.conversation_id}`}
        style={{ textDecoration: 'none' }}
      >
        <div className={styles.chat_history_list}>
          <div
            className={`${styles.chat_history_list_names}  ${entry.conversation_id === conversationId ? styles.chat_history_list_names_active : ''} `}
            style={
              entry.conversation_id === conversationId && theme === 'light-theme' ? { backgroundColor: '#E9EBEE' } : {}
            }
          >
            {entry.name.replace(/_/g, ' ')}
          </div>
        </div>
      </Link>
    </>
  );
};

export default ChatEntry;
