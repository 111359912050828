import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as apiService from '../../services/apiService';
import { ListConversationResponse } from '../../services/apiService/definitions/types';
import { RootState } from '../store';

export type HistoryState = {
  conversations: ListConversationResponse;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  historyFetch: boolean;
};

const initialState: HistoryState = {
  conversations: [],
  loading: 'idle',
  historyFetch: false,
};

export const fetchChatHistory = createAsyncThunk(
  'fetchChatHistory',
  async ({ projectKey }: { projectKey: string }, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const defaultProjectKey = state.sideBar.projectKey;
    const effectiveProjectKey = projectKey || defaultProjectKey;
    try {
      const { data } = await apiService.listConversations(effectiveProjectKey);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const deleteConversationById = createAsyncThunk(
  'deleteConversationById',
  async ({ conversationId }: { conversationId: string }, { rejectWithValue }) => {
    try {
      const { data } = await apiService.deleteConversationById(conversationId);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const deleteAllChatHistory = createAsyncThunk(
  'deleteAllChatHistory',
  async ({ projectKey }: { projectKey: string }, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const defaultProjectKey = state.sideBar.projectKey;
      const effectiveProjectKey = projectKey || defaultProjectKey;
      const { data } = await apiService.deleteManyConversations(effectiveProjectKey);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatHistory.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchChatHistory.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.conversations = action.payload;
        state.historyFetch = true;
      })
      .addCase(fetchChatHistory.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(deleteConversationById.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(deleteConversationById.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(deleteConversationById.fulfilled, (state) => {
        state.loading = 'succeeded';
        state.conversations = [];
      })
      .addCase(deleteAllChatHistory.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(deleteAllChatHistory.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(deleteAllChatHistory.fulfilled, (state) => {
        state.loading = 'succeeded';
        state.conversations = [];
      });
  },
});

export default historySlice.reducer;
