// TemplateComponent.tsx
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '../../redux/store';

import './LandingScreen.css';

interface TemplateProps {
  index: number;
  icon: React.ReactElement;
  title: string;
  description: string;
}

const TemplateComponent: React.FC<TemplateProps> = ({ index, icon, title, description }) => {
  const navigate = useNavigate();

  const theme = useSelector((state: RootState) => state.user.theme);

  const handleClick = () => {
    if (title === 'Discover Insights') {
      navigate('/chat/discover');
    }

    if (title === 'Know your data') {
      navigate('/chat/knowdata');
    }
  };

  return (
    <>
      {index !== 0 && (
        <div
          className={`IC-template ${index === 0 ? 'no-hover' : ''}`}
          onClick={handleClick}
          id={index === 1 ? 'component-e-element' : index === 2 ? 'component-f-element' : 'coming-soon'}
        >
          <div className="IC-template-title">
            {icon}
            {title}
          </div>
          <div className="IC-template-arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 14 15" fill="none">
              <mask id="mask0_3042_2281" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="15">
                <rect y="0.882202" width="14" height="14" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_3042_2281)">
                <path
                  className="IC-template-arrow-path"
                  d="M8.16665 11.3822L7.34998 10.5364L9.42081 8.46554H2.33331V7.29887H9.42081L7.34998 5.22804L8.16665 4.3822L11.6666 7.8822L8.16665 11.3822Z"
                  fill="white"
                />
              </g>
            </svg>
          </div>
          <div className="IC-template-description">
            <p>{description}</p>
          </div>

          {index === 0 && (
            <div
              className="IC-coming-soon"
              style={{
                backgroundColor: theme === 'light-theme' ? 'rgba(209, 163, 60, 0.09)' : '',
              }}
            >
              <div className="IC-coming-soon-text" style={{ color: theme === 'light-theme' ? '#D1A33C' : '' }}>
                Coming Soon
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TemplateComponent;
