import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  isLeftSidebarVisible: boolean;
  searchQuery: string;
  detailsVisible: boolean;
  isLightTheme: boolean;
  isCollapsed: boolean;
  projectKey: string;
  projectKeys: {
    id: string;
    display_name: string;
    project_name: string;
  }[];
};

const initialState: InitialState = {
  isLeftSidebarVisible: true,
  searchQuery: '',
  detailsVisible: true,
  isLightTheme: false,
  isCollapsed: false,
  projectKey: '',
  projectKeys: [],
};

const sideBarSlice = createSlice({
  name: 'sideBar',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.isLeftSidebarVisible = !state.isLeftSidebarVisible;
    },
    setSearchQuery: (state, action: PayloadAction<InitialState['searchQuery']>) => {
      state.searchQuery = action.payload;
    },
    setDetailsVisible: (state) => {
      state.detailsVisible = !state.detailsVisible;
    },
    setIsLightTheme: (state) => {
      state.isLightTheme = !state.isLightTheme;
    },
    setIsCollapsed: (state) => {
      state.isCollapsed = !state.isCollapsed;
    },
    setisCollapsedState: (state, action: PayloadAction<InitialState['isCollapsed']>) => {
      state.isCollapsed = action.payload;
    },
    setProjectKey: (state, action: PayloadAction<InitialState['projectKey']>) => {
      state.projectKey = action.payload;
    },
    setProjectKeys: (state, action: PayloadAction<InitialState['projectKeys']>) => {
      state.projectKeys = action.payload;
    },
  },
});

export const {
  toggleSidebar,
  setSearchQuery,
  setDetailsVisible,
  setIsLightTheme,
  setIsCollapsed,
  setisCollapsedState,
  setProjectKeys,
  setProjectKey,
} = sideBarSlice.actions;

// Fetch user data from ProjectKey on initialization if it's null
const projectKeyString = localStorage.getItem('ProjectKey');

if (projectKeyString) {
  let currentProjectKey;

  try {
    currentProjectKey = JSON.parse(projectKeyString);

    // Check if the parsed data is valid and has the expected structure
    if (currentProjectKey) {
      initialState.projectKey = currentProjectKey;
    } else {
      console.error('Invalid project key!!');
    }
  } catch (error) {
    console.error('Error parsing project key string:', error);
    // Handle the error, e.g., set a default value or show an error message
  }
}

export default sideBarSlice.reducer;
