import React from 'react';

import './ScrollToBottom.css';
interface ScrollToBottomButtonProps {
  scrollToBottom: () => void;
}

const ScrollToBottomButton: React.FC<ScrollToBottomButtonProps> = ({ scrollToBottom }) => {
  return (
    <button className="scroll-button" onClick={scrollToBottom}>
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
        <path
          className="svg-icon-path"
          d="M4.40007 0.199707V7.50471L1.04007 4.14471L0.200073 4.99971L5.00007 9.79971L9.80007 4.99971L8.96007 4.14471L5.60007 7.50471V0.199707H4.40007Z"
          fill="#E1E0E4"
        />
      </svg>
    </button>
  );
};

export default ScrollToBottomButton;
