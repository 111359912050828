import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { format } from 'sql-formatter';

import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';

import styles from './SQLQuery.module.css';

type Props = {
  query: string;
};

export const SQLQuery: React.FC<Props> = ({ query }) => {
  const { isCopied, copyToClipboard } = useCopyToClipboard();

  const formattedQuery = format(query, { language: 'mysql' });

  return (
    <div className={styles.root}>
      <button className={styles.iconButton} onClick={() => copyToClipboard(formattedQuery)} disabled={isCopied}>
        {isCopied ? (
          <i className="fa-solid fa-check" />
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 12 14" fill="none">
            <path
              className="svg-icon-path"
              d="M4 10.9997C3.63333 10.9997 3.31944 10.8691 3.05833 10.608C2.79722 10.3469 2.66667 10.033 2.66667 9.66634V1.66634C2.66667 1.29967 2.79722 0.985786 3.05833 0.724674C3.31944 0.463563 3.63333 0.333008 4 0.333008H10C10.3667 0.333008 10.6806 0.463563 10.9417 0.724674C11.2028 0.985786 11.3333 1.29967 11.3333 1.66634V9.66634C11.3333 10.033 11.2028 10.3469 10.9417 10.608C10.6806 10.8691 10.3667 10.9997 10 10.9997H4ZM4 9.66634H10V1.66634H4V9.66634ZM1.33333 13.6663C0.966667 13.6663 0.652778 13.5358 0.391667 13.2747C0.130556 13.0136 0 12.6997 0 12.333V2.99967H1.33333V12.333H8.66667V13.6663H1.33333Z"
              fill="white"
              fillOpacity="0.54"
            />
          </svg>
        )}
      </button>
      <SyntaxHighlighter language="sql" style={solarizedlight}>
        {formattedQuery}
      </SyntaxHighlighter>
    </div>
  );
};
