import React, { useRef, useState } from 'react';

import useAnalytics from '../../hooks/useAnalytics';
import { EngineChartResults } from '../../services/apiService/definitions/types';
import { downloadChartAndData } from '../../utils/utils';
import ChartCard from '../Home/Cards/ChartCard';
import ChartDataCard from '../Home/Cards/ChartDataCard';

import './RenderChartCard.css';

type Props = {
  chartResults?: EngineChartResults | null;
  sqlResult?: Record<string, unknown>[];
  chat_name?: string;
};

const RenderChartDataCard = ({ chartResults, sqlResult, chat_name }: Props) => {
  const analytics = useAnalytics();
  const [showChart, setShowChart] = useState(true);
  const [showTable, setShowTable] = useState(true);
  const [showPopup, setShowPopup] = useState(false);

  const chartCardRef = useRef<any>(null);

  const chartTitle = (chartResults?.chart_title ?? chat_name ?? '')?.replace(/_/g, ' ') ?? '';

  const [selectedChartType, setSelectedChartType] = useState(chartResults?.chart_type ?? '');

  const handleDownloadClick = (type: string) => {
    if (chartCardRef.current) {
      // If the chartCardRef has a download handler, we can trigger it
      chartCardRef.current.handleDownload(type);
    } else if (type === 'table' && sqlResult) {
      // Handle table download when chartCardRef is not available (i.e., no chart)
      const feedData = sqlResult;
      const chartTitle = chartResults?.chart_title || chat_name || '';
      downloadChartAndData('table', null, feedData, chartTitle, type);
    }
  };

  const handleChartTypeChange = (type: string) => {
    analytics.trackChartTypeChange(type);
    setSelectedChartType(type);
  };

  const handleMaximize = (type: string) => {
    setShowPopup(true);
    if (type === 'table') {
      setShowTable(true);
      setShowChart(false); // Ensure chart is hidden when showing the table
    } else {
      setShowChart(true);
      setShowTable(false); // Ensure table is hidden when showing the chart
    }
    analytics.trackMaximizeClick(type === 'table' ? 'table' : selectedChartType);
  };

  const handleMinimize = (type: string) => {
    setShowPopup(false);
    setShowChart(true); // Reset to showing both chart and table
    setShowTable(true);
    analytics.trackMinimizeClick(type == 'table' ? 'table' : selectedChartType);
  };

  if (
    ((!selectedChartType || selectedChartType === 'None') &&
      !sqlResult &&
      (!chartResults?.chart_data || Object.keys(chartResults?.chart_data || {}).length === 0)) ||
    (sqlResult?.length === 1 && Object.keys(sqlResult[0])?.length === 1)
  ) {
    return null;
  }

  return (
    <>
      {/* ChartDataCard now handles both table and chart */}
      <ChartDataCard
        title={chartTitle}
        chartType={selectedChartType}
        tableResults={Array.isArray(sqlResult) ? (sqlResult ?? []) : []}
        handleMaximize={handleMaximize}
        handleMinimize={handleMinimize}
        showPopup={showPopup}
        handleDownload={handleDownloadClick}
        showChart={showChart}
        showTable={showTable}
      >
        {/* Render the chart inside ChartDataCard based on showChart prop */}
        {selectedChartType && chartResults && (
          <ChartCard
            selectedChartType={selectedChartType}
            chartResults={chartResults}
            sqlResult={sqlResult}
            ref={chartCardRef}
            handleMaximize={handleMaximize}
            handleMinimize={handleMinimize}
            showPopup={showPopup}
            handleChartTypeChange={handleChartTypeChange}
          />
        )}
      </ChartDataCard>
      {showPopup && (
        <div className="popupBG">
          <div className="popup-chart">
            <ChartDataCard
              title={chartTitle}
              chartType={selectedChartType}
              tableResults={Array.isArray(sqlResult) ? (sqlResult ?? []) : []}
              handleMaximize={handleMaximize}
              handleMinimize={handleMinimize}
              showPopup={showPopup}
              handleDownload={handleDownloadClick}
              showChart={showChart}
              showTable={showTable}
            >
              {/* Render the chart inside ChartDataCard based on showChart prop */}
              {selectedChartType && chartResults && (
                <ChartCard
                  selectedChartType={selectedChartType}
                  chartResults={chartResults}
                  sqlResult={sqlResult}
                  ref={chartCardRef}
                  handleMaximize={handleMaximize}
                  handleMinimize={handleMinimize}
                  showPopup={showPopup}
                  handleChartTypeChange={handleChartTypeChange}
                />
              )}
            </ChartDataCard>
          </div>
        </div>
      )}
    </>
  );
};

export default RenderChartDataCard;
