import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  changeAnswerNodeInChain,
  verifyChainAndSetIsQuestionSubmitted,
} from '../../../redux/features/conversationSlice';
import { RootState } from '../../../redux/store';
import {
  ABANDONED_CHAT_TIMEOUT_MINS,
  getNodesOfType,
  isAbandonedAnswerNode,
  isServerUnavailable,
  SERVER_UNAVAILABLE_TIMEOUT_MINS,
  sortNodeIdsByCreationTimeAsc,
} from '../../../utils/conversation';
import RenderChartDataCard from '../../Charts/RenderChartCard';
import MultiStepLoader from '../../Core/Loader/MultiStepLoader';
import MsgBodyAvatar from '../../Core/msgBodyAvatar/MsgBodyAvatar';
import MessageFooter from '../../MsgFooter/MsgFooter';
import Restructure from '../../ResponseRestructure';
import { VersionSwitcher } from '../../VersionSwitcher';
import { MessageErrorState } from '../MessageErrorState';

import './index.css';
import styles from './MessageContent.module.css';

export const MessageContent: React.FC<{
  queryNodeId: string;
  answerNodeId: string;
}> = ({ queryNodeId, answerNodeId }) => {
  const dispatch = useDispatch();
  const isOnline = useSelector((state: RootState) => state.network.isOnline);
  const conversationTree = useSelector((state: RootState) => state.conversation.conversationTree);
  const conversationTreeNodeStates = useSelector((state: RootState) => state.conversation.conversationTreeNodeStates);
  const [, renderCount] = useState(0);

  const answerNode = conversationTree?.nodes[answerNodeId];
  const answerNodeState = conversationTreeNodeStates[answerNodeId];

  const siblingAnswerNodes = conversationTree
    ? getNodesOfType(conversationTree.nodes[queryNodeId].children, conversationTree, 'answer')
    : [];
  const siblingAnswerNodesSorted = conversationTree
    ? sortNodeIdsByCreationTimeAsc(
        siblingAnswerNodes.map((node) => node.id),
        conversationTree,
      )
    : [];
  const answerNodePosition = siblingAnswerNodesSorted.findIndex((nodeId) => nodeId === answerNodeId);
  const nextAnswerNode = siblingAnswerNodesSorted[answerNodePosition + 1] || null;
  const prevAnswerNode = siblingAnswerNodesSorted[answerNodePosition - 1] || null;

  const forceRender = () => {
    dispatch(verifyChainAndSetIsQuestionSubmitted());
    renderCount((prev) => prev + 1);
  };

  const onNextVersionClick = () => {
    if (nextAnswerNode) {
      dispatch(changeAnswerNodeInChain({ currentQueryNodeId: queryNodeId, newAnswerNodeId: nextAnswerNode }));
    }
  };

  const onPrevVersionClick = () => {
    if (prevAnswerNode) {
      dispatch(changeAnswerNodeInChain({ currentQueryNodeId: queryNodeId, newAnswerNodeId: prevAnswerNode }));
    }
  };

  const renderAnswerVersion = () => {
    if (siblingAnswerNodesSorted.length > 1) {
      return (
        <VersionSwitcher
          current={answerNodePosition + 1}
          total={siblingAnswerNodesSorted.length}
          isNextAvailable={!!nextAnswerNode}
          isPrevAvailable={!!prevAnswerNode}
          onNextClick={onNextVersionClick}
          onPrevClick={onPrevVersionClick}
        />
      );
    }
    return null;
  };

  const getNERValues = (): string[] => {
    try {
      const idx = answerNode?.data.sub_status?.findIndex((item: string) => item.includes('NER Extraction Done'));
      if (!idx || idx === -1) {
        return [];
      }
      const nerData = answerNode?.data.sub_status_value?.[idx];
      return Object.values(nerData || {}).flat();
    } catch (error) {
      console.error('Error in getting NER values', error);
      return [];
    }
  };

  const renderMessageContent = () => {
    if (!answerNode) {
      console.error('No answer node found', answerNodeId);
      return null;
    }
    const nerValues = getNERValues();
    const taskStatus = answerNode.data.task_status?.trim();
    const isFailedStatus = taskStatus === 'FAILURE';
    const isNetworkError = !isOnline && !answerNode?.data.engine_response?.response;
    const isChatChatGenerationStopped = !!answerNodeState?.chatGenerationStopped || taskStatus === 'CANCELLED';
    const isServerUnavailableError = isServerUnavailable(answerNode);
    const isAbandonedAnswerNodeError = isAbandonedAnswerNode(answerNode);
    const genericErrorMessage = answerNodeState?.errorMessage;
    if (
      isFailedStatus ||
      isNetworkError ||
      isChatChatGenerationStopped ||
      isServerUnavailableError ||
      isAbandonedAnswerNodeError ||
      !!genericErrorMessage
    ) {
      return (
        <div className={styles.versionContainer}>
          <MessageErrorState
            answerNodeId={answerNodeId}
            isFailedStatus={isFailedStatus}
            isNetworkError={isNetworkError}
            isChatChatGenerationStopped={isChatChatGenerationStopped}
            isServerUnavailableError={isServerUnavailableError}
            isAbandonedAnswerNodeError={isAbandonedAnswerNodeError}
            genericErrorMessage={genericErrorMessage}
            nerValues={nerValues}
          />
          {renderAnswerVersion()}
        </div>
      );
    }
    return (
      <>
        {answerNode.data.engine_response?.response ? (
          <>
            <Restructure answerNodeId={answerNodeId} />
            {answerNode.data.engine_response && (
              <RenderChartDataCard
                chartResults={answerNode.data.engine_response.chart_results}
                sqlResult={answerNode.data.engine_response.sql_result as Record<string, unknown>[]}
                chat_name={answerNode.data.engine_response.chat_name}
              />
            )}
            <MessageFooter queryNodeId={queryNodeId} answerNodeId={answerNodeId} />
          </>
        ) : (
          <div className={styles.versionContainer}>
            <MultiStepLoader answerNodeId={answerNodeId} nerValues={nerValues} />
            {renderAnswerVersion()}
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    // Force render after 2 and 5 mins to ensure we capture isServerUnavailable and isAbandonedAnswerNode cases
    const t1 = setTimeout(
      () => {
        forceRender();
      },
      SERVER_UNAVAILABLE_TIMEOUT_MINS * 60 * 1000 + 10 * 1000, // Add delta of 10 seconds, just in case
    );
    const t2 = setTimeout(
      () => {
        forceRender();
      },
      ABANDONED_CHAT_TIMEOUT_MINS * 60 * 1000 + 10 * 1000, // Add delta of 10 seconds, just in case
    );
    return () => {
      clearTimeout(t1);
      clearTimeout(t2);
    };
  }, [queryNodeId, answerNodeId]);

  return (
    <div className="msgBody">
      <div style={{ width: '20px' }}>
        <MsgBodyAvatar />
      </div>
      <div className="msg-content" style={{ width: '100%', letterSpacing: '0.15px' }}>
        {renderMessageContent()}
      </div>
    </div>
  );
};
