import React from 'react';

import './AcknowledgeContent.css';
interface AcknowledgeHeaderProps {
  icon: React.ReactNode;
  text: string;
  iconStyle?: React.CSSProperties;
}

const AcknowledgeHeader: React.FC<AcknowledgeHeaderProps> = ({ icon, text, iconStyle }) => {
  return (
    <div className="Acknowledge-header">
      <div className="Acknowledge-icon" style={iconStyle}>
        {icon}
      </div>
      <div className="Acknowledge-info">{text}</div>
    </div>
  );
};

export default AcknowledgeHeader;
