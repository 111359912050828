import React from 'react';

import { EngineChartResults } from '../../../services/apiService/definitions/types';

import styles from './ChartCardControls.module.css';

interface ChartCardControlsProps {
  selectedChartType: string;
  chartResults?: EngineChartResults | null;
  handleMaximize: (type: string) => void;
  handleMinimize: (type: string) => void;
  showPopup?: boolean;
  handleChartTypeChange: (type: string) => void;
}

const ChartCardControls = ({ selectedChartType, chartResults, handleChartTypeChange }: ChartCardControlsProps) => {
  return (
    <div className={styles.chart_controls}>
      <div className={styles.chart_controls_wrapper}>
        <div className={styles.chart_controls_btn}>
          <>
            <div
              className={styles.control_button_bc}
              onClick={() => handleChartTypeChange(chartResults?.chart_type ?? '')}
              style={{
                background: selectedChartType === chartResults?.chart_type ? '#4C4C4C' : '#272727',
              }}
            >
              <img
                style={{
                  filter: selectedChartType === chartResults?.chart_type ? 'brightness(0) invert(1)' : '',
                }}
                src={`${process.env.PUBLIC_URL}/images/${chartResults?.chart_type}.png`}
                className={styles.control_button_img}
                alt="H-bar"
              />
            </div>
            {chartResults?.chart_alternatives.map((alternative: any, altIndex: number) => (
              <div
                key={altIndex}
                className={styles.control_button_bc}
                onClick={() => handleChartTypeChange(alternative)}
                style={{
                  background: selectedChartType === alternative ? '#4C4C4C' : '#272727',
                }}
              >
                <img
                  style={{
                    filter: selectedChartType === alternative ? 'brightness(0) invert(1)' : '',
                  }}
                  src={`${process.env.PUBLIC_URL}/images/${alternative}.png`}
                  className={styles.control_button_img}
                  alt="H-bar"
                />
              </div>
            ))}
          </>
        </div>
      </div>
    </div>
  );
};

export default ChartCardControls;
