import React, { useState } from 'react';

import './ResponseOptions.css';
interface ResponseOptionProps {
  label: string;
  onClick: (e: React.MouseEvent) => void; // Accepts a function to be called on click
}

const ResponseOption: React.FC<ResponseOptionProps> = ({ label, onClick }) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    setIsActive(!isActive);

    onClick(e); // Pass the event parameter to the onClick function
  };

  const SvgIcon = ({ fill = 'white', maskId }: { fill?: string; maskId: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <mask id={maskId} maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">
        <rect width="12" height="12" fill="#333333" />
      </mask>
      <g mask={`url(#${maskId})`}>
        <path
          d="M3.5 8.5C3.64167 8.5 3.76042 8.45208 3.85625 8.35625C3.95208 8.26042 4 8.14167 4 8C4 7.85833 3.95208 7.73958 3.85625 7.64375C3.76042 7.54792 3.64167 7.5 3.5 7.5C3.35833 7.5 3.23958 7.54792 3.14375 7.64375C3.04792 7.73958 3 7.85833 3 8C3 8.14167 3.04792 8.26042 3.14375 8.35625C3.23958 8.45208 3.35833 8.5 3.5 8.5ZM3 6.5H4V3.5H3V6.5ZM5.5 8.5H9V7.5H5.5V8.5ZM5.5 6.5H9V5.5H5.5V6.5ZM5.5 4.5H9V3.5H5.5V4.5ZM2 10.5C1.725 10.5 1.48958 10.4021 1.29375 10.2063C1.09792 10.0104 1 9.775 1 9.5V2.5C1 2.225 1.09792 1.98958 1.29375 1.79375C1.48958 1.59792 1.725 1.5 2 1.5H10C10.275 1.5 10.5104 1.59792 10.7063 1.79375C10.9021 1.98958 11 2.225 11 2.5V9.5C11 9.775 10.9021 10.0104 10.7063 10.2063C10.5104 10.4021 10.275 10.5 10 10.5H2ZM2 9.5H10V2.5H2V9.5Z"
          fill={fill}
        />
      </g>
    </svg>
  );

  return (
    <div className={`response-option ${isActive ? 'active' : ''}`} onClick={handleClick}>
      {isActive ? (
        <SvgIcon fill="#F5E003" maskId="mask0_1575_6389" />
      ) : (
        <SvgIcon fill="white" maskId="mask0_1575_6399" />
      )}

      <p>{label}</p>
      {isActive && (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <mask id="mask0_1756_4708" maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">
            <rect width="12" height="12" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1756_4708)">
            <path
              d="M5.3 8.3L8.825 4.775L8.125 4.075L5.3 6.9L3.875 5.475L3.175 6.175L5.3 8.3ZM6 11C5.30833 11 4.65833 10.8687 4.05 10.6062C3.44167 10.3438 2.9125 9.9875 2.4625 9.5375C2.0125 9.0875 1.65625 8.55833 1.39375 7.95C1.13125 7.34167 1 6.69167 1 6C1 5.30833 1.13125 4.65833 1.39375 4.05C1.65625 3.44167 2.0125 2.9125 2.4625 2.4625C2.9125 2.0125 3.44167 1.65625 4.05 1.39375C4.65833 1.13125 5.30833 1 6 1C6.69167 1 7.34167 1.13125 7.95 1.39375C8.55833 1.65625 9.0875 2.0125 9.5375 2.4625C9.9875 2.9125 10.3438 3.44167 10.6062 4.05C10.8687 4.65833 11 5.30833 11 6C11 6.69167 10.8687 7.34167 10.6062 7.95C10.3438 8.55833 9.9875 9.0875 9.5375 9.5375C9.0875 9.9875 8.55833 10.3438 7.95 10.6062C7.34167 10.8687 6.69167 11 6 11Z"
              fill="#F5E003"
            />
          </g>
        </svg>
      )}
    </div>
  );
};

export default ResponseOption;
