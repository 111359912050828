// Loader.tsx
import React from 'react';

const HistoryLoader: React.FC = () => {
  return (
    <div className="skeleton-loader">
      <div
        className="skeleton-loader__header shine-animation "
        style={{
          height: '10px',
          marginBottom: '20px',
          borderRadius: '10px',
          width: '50%',
        }}
      ></div>
      <div
        className="skeleton-loader__header shine-animation "
        style={{ height: '10px', marginBottom: '5px', borderRadius: '10px' }}
      ></div>
      <div
        className="skeleton-loader__content shine-animation "
        style={{ height: '10px', marginBottom: '5px', borderRadius: '10px' }}
      ></div>
      <div
        className="skeleton-loader__header shine-animation "
        style={{
          height: '10px',
          marginBottom: '20px',
          borderRadius: '10px',
          width: '50%',
          marginTop: '20px',
        }}
      ></div>
      <div
        className="skeleton-loader__header shine-animation "
        style={{ height: '10px', marginBottom: '5px', borderRadius: '10px' }}
      ></div>
      <div
        className="skeleton-loader__content shine-animation "
        style={{ height: '10px', marginBottom: '5px', borderRadius: '10px' }}
      ></div>
      <div
        className="skeleton-loader__header shine-animation "
        style={{
          height: '10px',
          marginBottom: '20px',
          borderRadius: '10px',
          width: '50%',
          marginTop: '20px',
        }}
      ></div>
      <div
        className="skeleton-loader__header shine-animation "
        style={{ height: '10px', marginBottom: '5px', borderRadius: '10px' }}
      ></div>
      <div
        className="skeleton-loader__content shine-animation "
        style={{ height: '10px', marginBottom: '5px', borderRadius: '10px' }}
      ></div>
    </div>
  );
};

export default HistoryLoader;
