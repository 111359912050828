export const ChatStatusMapping: Record<string, string> = {
  'Initializing ABI Insights Copilot Engine.': 'Starting Insights Copilot Engine',
  'Parsing historical conversation.': 'Understanding your question',
  'Fixing query with historical context.': 'Adding more context',
  'NER Extraction Done.': 'Identifying relevant data',
  'Classified question : Complex': 'Creating multiple hypotheses & investigating each',
  'Generating response.': 'Summarizing Insights & Generating Response',
  'Generating follow up questions.': 'Suggesting follow-up questions',
  'Validating final response.': 'Plotting Charts',
  'Parsing response.': 'Parsing conversation',
  'Error generating response.': 'Failed to generate response',
  'Message not picked up by engine': 'Error Starting Insights Copilot Engine',
  'Error Generating follow up questions.': 'Failed to suggest follow-up questions',
  'Error Validating engine response.': 'Failed to plot charts',
  'Error in NER Extraction.': 'Failed to identify relevant data',
  'Error Fixing query with historical context.': 'Failed to add more context',
  'Error parsing historical conversation.': 'Failed to understand your question',
  'Error initializing ABI Insights Copilot Engine.': 'Failed to start Insights Copilot Engine',
  'Error parsing response.': 'Failed to parse conversation',
};
