import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ArrowButtonProps {
  onClick?: () => void;
  isBackButton?: boolean;
}

const ArrowButton: React.FC<ArrowButtonProps> = ({ onClick, isBackButton }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (isBackButton) {
      // If it's a back button, set templates to empty or handle the back action
      // For now, setting templates to empty
      // handleBack(); // You can replace this line with your desired back action
      onClick && onClick(); // Add null check before invoking onClick
    } else {
      navigate('/chat');
    }
  };

  return (
    <div className="IC-arrow" onClick={handleClick} style={{ cursor: 'pointer' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <mask id="mask0_3108_51666" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
          <rect width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3108_51666)">
          <path
            className="svg-icon-path"
            d="M8.33374 15L3.33374 10L8.33374 5L9.50041 6.20833L6.54207 9.16667H16.6671V10.8333H6.54207L9.50041 13.7917L8.33374 15Z"
            fill="#EFEFEF"
          />
        </g>
      </svg>
    </div>
  );
};

export default ArrowButton;
