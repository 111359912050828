import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../redux/store';

const UserAvatar = () => {
  const userImage = useSelector((state: RootState) => state.user.userData?.image);
  const theme = useSelector((state: RootState) => state.user.theme);

  return (
    <img
      src={userImage}
      alt="profile"
      className="profImg"
      loading="lazy"
      onError={(e) => {
        const imgElement = e.target as HTMLImageElement;
        imgElement.src =
          theme == 'light'
            ? `${process.env.PUBLIC_URL}/images/user-image-light.png`
            : `${process.env.PUBLIC_URL}/images/user-image.png`;
      }}
    />
  );
};

export default UserAvatar;
