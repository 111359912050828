import React from 'react';

import { EngineChartResults } from '../../../services/apiService/definitions/types';
import MainChartComponent from '../../Charts/MainChart';

import styles from './ChartAreaCard.module.css';

interface ChartAreaCardProps {
  selectedChartType: string;
  chartResults?: EngineChartResults | null;
  showPopup?: boolean;
  chartRefs: {
    lineChartRef: React.RefObject<any>;
    barChartRef: React.RefObject<any>;
    horizontalBarChartRef: React.RefObject<any>;
    doughnutChartRef: React.RefObject<any>;
    pieChartRef: React.RefObject<any>;
    areaChartRef: React.RefObject<any>;
    stackedBarChartRef: React.RefObject<any>;
    stackedAreaChartRef: React.RefObject<any>;
  };
}

const ChartAreaCard = ({ selectedChartType, chartResults, showPopup, chartRefs }: ChartAreaCardProps) => {
  // Function to capitalize first letter and replace underscores with spaces
  // const capitalizeAndReplaceUnderscore = (str: string) => {
  //   // Replace underscores with spaces and split the string into words
  //   const words = str.split('_').map((word) => word.toLowerCase());

  //   // Capitalize the first letter of each word
  //   const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  //   // Join the words back into a string
  //   return capitalizedWords.join(' ');
  // };

  return (
    <>
      <div className={styles.chart_area}>
        <div style={{ display: 'block' }}>
          <MainChartComponent
            selectedChartType={selectedChartType}
            chartResults={chartResults}
            showPopup={showPopup}
            chartRefs={chartRefs}
          />
        </div>
      </div>
    </>
  );
};

export default ChartAreaCard;
