import { configureStore } from '@reduxjs/toolkit';

import conversationReducer from '../features/conversationSlice';
import historyReducer from '../features/historySlice';
import landingPageReducer from '../features/landingPageSlice';
import networkReducer from '../features/networkStatusSlice';
import sideBarReducer from '../features/sideBar';
import toastReducer from '../features/toastSlice';
import userReducer from '../features/userSlice';
import networkStatusMiddleware from '../middleware/networkStatusMiddleware';

export const store = configureStore({
  reducer: {
    history: historyReducer,
    conversation: conversationReducer,
    sideBar: sideBarReducer,
    user: userReducer,
    landingPage: landingPageReducer,
    network: networkReducer,
    toast: toastReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(networkStatusMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
