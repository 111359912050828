import React from 'react';

import { EngineChartResults } from '../../services/apiService/definitions/types';
import RenderChartDataCard from '../Charts/RenderChartCard';

import './DiscoverFlow.css';

interface Content {
  heading: string;
  data: string;
  chart_data: any | null;
}

interface Template {
  image: string;
  altText: string;
  title: string;
  created_at: string;
  short_description: string;
  content: Content[];
}

interface ReadMoreTemplateProps {
  template: Template;
}

const ReadMoreTemplate: React.FC<ReadMoreTemplateProps> = ({ template }) => {
  const formatChartData = (chartData: any): EngineChartResults => {
    // @ts-expect-error it's fine
    return {
      chart_type: chartData.chart_type,
      chart_alternatives: chartData.chart_alternatives,
      chart_title: chartData.chart_title,
      x_axis_title: chartData.x_axis_title,
      y_axis_title: chartData.y_axis_title,
      chart_data: chartData.chart_data,
      legends: chartData.legends,
    } as EngineChartResults;
  };

  return (
    <div className="IC-discover-template" style={{ paddingTop: '0px' }}>
      <div className="IC-discover-description" style={{ width: '100%', paddingBottom: '7.45rem' }}>
        <div className="IC-discover-template-info">
          {template.image !== '' && (
            <div className="IC-discover-template-info-image">
              <img src={template.image} alt={template.altText} />
            </div>
          )}
          <p>{template.short_description}</p>
        </div>

        <div
          style={{
            marginTop: '20px',
            display: 'flex',
            gap: '12px',
            flexDirection: 'column',
          }}
        >
          {template.content.map((content, index) => (
            <div key={index}>
              <div className="IC-discover-title" style={{ fontWeight: '700' }}>
                {content.heading}
              </div>
              <p>{content.data}</p>
              {content.chart_data && <RenderChartDataCard chartResults={formatChartData(content.chart_data)} />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReadMoreTemplate;
