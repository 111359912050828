// networkStatusMiddleware.ts
import { Middleware } from '@reduxjs/toolkit';

import { RootState } from '../store';

const networkStatusMiddleware: Middleware = (store) => (next) => (action: any) => {
  const state = store.getState() as RootState;
  const isOnline = state.network.isOnline;
  if (action.type.endsWith('/rejected') && !isOnline) {
    // Modify action to include network status
    action.meta = {
      ...action.meta,
      isOnline,
    };
  }
  return next(action);
};

export default networkStatusMiddleware;
