import React from 'react';

import UserAvatar from '../Core/userAvatar/UserAvatar';

import './QuestionHeader.css';

interface QuestionHeaderProps {
  user: string;
  question: string;
}

// This component is used to display the user's question in the onboarding process
const QuestionHeader: React.FC<QuestionHeaderProps> = ({ question }) => {
  return (
    <div className="Onboarding-question-header">
      <div className="user-image">
        <UserAvatar />
      </div>
      <div className="user-question">
        <p className="question" dangerouslySetInnerHTML={{ __html: question }} />
      </div>
    </div>
  );
};

export default QuestionHeader;
