import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useAnalytics from '../../../hooks/useAnalytics';
import { cancelChatRequest } from '../../../redux/features/conversationSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import { findInprogressNodesInChain } from '../../../utils/conversation';

import styles from './StopButton.module.css';

const StopButton = () => {
  const dispatch = useDispatch<AppDispatch>();
  const analytics = useAnalytics();
  const conversationTree = useSelector((state: RootState) => state.conversation.conversationTree);
  const conversationChain = useSelector((state: RootState) => state.conversation.conversationChain);

  const stopChat = async () => {
    if (conversationTree) {
      const inprogressAnswerNodes = findInprogressNodesInChain(conversationChain, conversationTree);
      await Promise.all(
        inprogressAnswerNodes.map(async (inprogressAnswerNode) => {
          analytics.trackStopQuestionClick(inprogressAnswerNode.id, conversationTree.conversation_id);
          await dispatch(cancelChatRequest({ answerNodeId: inprogressAnswerNode.id }));
        }),
      );
    }
  };

  return (
    <>
      <div className={styles.stop_chat_button} data-testid="stop-button" onClick={stopChat}>
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
          <mask id="mask0_11890_199" maskUnits="userSpaceOnUse" x="0" y="0" width="23" height="23">
            <rect x="0.931641" y="0.306763" width="22" height="22" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_11890_199)">
            <path
              className="chat-icon-svg"
              d="M8.26501 14.9735H15.5983V7.64014H8.26501V14.9735ZM11.9317 20.4735C10.6636 20.4735 9.47196 20.2328 8.35668 19.7516C7.2414 19.2703 6.27126 18.6172 5.44626 17.7922C4.62126 16.9672 3.96814 15.9971 3.48689 14.8818C3.00564 13.7665 2.76501 12.5749 2.76501 11.3068C2.76501 10.0387 3.00564 8.84708 3.48689 7.7318C3.96814 6.61653 4.62126 5.64639 5.44626 4.82139C6.27126 3.99639 7.2414 3.34326 8.35668 2.86201C9.47196 2.38076 10.6636 2.14014 11.9317 2.14014C13.1997 2.14014 14.3914 2.38076 15.5067 2.86201C16.622 3.34326 17.5921 3.99639 18.4171 4.82139C19.2421 5.64639 19.8952 6.61653 20.3765 7.7318C20.8577 8.84708 21.0983 10.0387 21.0983 11.3068C21.0983 12.5749 20.8577 13.7665 20.3765 14.8818C19.8952 15.9971 19.2421 16.9672 18.4171 17.7922C17.5921 18.6172 16.622 19.2703 15.5067 19.7516C14.3914 20.2328 13.1997 20.4735 11.9317 20.4735ZM11.9317 18.6401C13.9789 18.6401 15.7129 17.9297 17.1338 16.5089C18.5546 15.0881 19.265 13.354 19.265 11.3068C19.265 9.25958 18.5546 7.52555 17.1338 6.10472C15.7129 4.68389 13.9789 3.97347 11.9317 3.97347C9.88446 3.97347 8.15043 4.68389 6.7296 6.10472C5.30876 7.52555 4.59835 9.25958 4.59835 11.3068C4.59835 13.354 5.30876 15.0881 6.7296 16.5089C8.15043 17.9297 9.88446 18.6401 11.9317 18.6401Z"
              fill="#EFEFEF"
            />
          </g>
        </svg>
      </div>
    </>
  );
};

export default StopButton;
