import type { WritableDraft } from 'immer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as apiService from '../../services/apiService';
import {
  ConversationChatRegenerateRequestBody,
  ConversationChatUpdateFeedbackRequestBody,
  ConversationTree,
  CreateConversationChatRequestBody,
  CreateNewConversationRequestBody,
  SignalREventData,
} from '../../services/apiService/definitions/types';
import {
  breakChainAfterGivenNode,
  breakChainAtGivenNodeInclusive,
  createChainFromConversation,
  findInprogressNodesInChain,
  finishChainTillLeafAnswerNode,
  getChildrenOfType,
  getLatestAnswerNodeOfQueryNode,
  getLatestChildQueryOfQueryNode,
  getMostRecentlyCreatedNodeId,
  getNodesOfType,
} from '../../utils/conversation';

export type ConversationTreeNodeState = {
  cacheDate?: string | null;
  chatGenerationStopped?: boolean;
  errorMessage?: string;
};

export type ConversationState = {
  isNewConversation: boolean;
  isConversationLoading: boolean;
  conversationId: string | null;
  conversationTree: ConversationTree | null;
  conversationChain: {
    queryNodeId: string;
    answerNodeId: string;
  }[];
  conversationTreeNodeStates: Record<string, ConversationTreeNodeState | undefined>;
  viewSourceState: {
    show?: boolean;
    answerNodeId?: string;
    citationIndex?: number;
  } | null;
  isQuestionSubmitted: boolean;
  showApiCallPendingSpinner: boolean;
  inputValue: string;
  isCacheEnabled: boolean;
  isSemanticCacheEnabled: boolean;
  showChartTooltip: boolean;
};

const initialState: ConversationState = {
  isNewConversation: false,
  isConversationLoading: false,
  conversationId: null,
  conversationTree: null,
  conversationChain: [],
  conversationTreeNodeStates: {},
  viewSourceState: null,
  isQuestionSubmitted: false,
  showApiCallPendingSpinner: false, // Used for new question, edit, regenerate, stop chat
  inputValue: '',
  isCacheEnabled: true,
  isSemanticCacheEnabled: true,
  showChartTooltip: false,
};

const isCacheEnabledString = localStorage.getItem('isCacheEnabled');
if (isCacheEnabledString) {
  try {
    const isCacheEnabled = JSON.parse(isCacheEnabledString);
    if (typeof isCacheEnabled === 'boolean') {
      initialState.isCacheEnabled = isCacheEnabled;
    } else {
      console.error("Invalid 'isCacheEnabled' value!!");
    }
  } catch (error) {
    console.error("Error parsing 'isCacheEnabled':", error);
  }
}

const isSemanticCacheEnabledString = localStorage.getItem('isSemanticCacheEnabled');
if (isSemanticCacheEnabledString) {
  try {
    const isSemanticCacheEnabled = JSON.parse(isSemanticCacheEnabledString);
    if (typeof isSemanticCacheEnabled === 'boolean') {
      initialState.isSemanticCacheEnabled = isSemanticCacheEnabled;
    } else {
      console.error("Invalid 'isSemanticCacheEnabled' value!!");
    }
  } catch (error) {
    console.error("Error parsing 'isSemanticCacheEnabled':", error);
  }
}

export const fetchAndLoadConversationChats = createAsyncThunk(
  'listConversationChats',
  async ({ conversationId }: { conversationId: string }, { rejectWithValue }) => {
    try {
      return await apiService.listConversationChats(conversationId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createNewConversation = createAsyncThunk(
  'createNewConversation',
  async (data: CreateNewConversationRequestBody, { rejectWithValue }) => {
    try {
      return await apiService.createNewConversation(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const addChatToConversation = createAsyncThunk(
  'addChatToConversation',
  async (data: { conversationId: string; body: CreateConversationChatRequestBody }, { rejectWithValue }) => {
    try {
      return await apiService.addChatToConversation(data.conversationId, data.body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const regenerateChat = createAsyncThunk(
  'regenerateChat',
  async (data: { queryNodeId: string; body: ConversationChatRegenerateRequestBody }, { rejectWithValue }) => {
    try {
      return await apiService.regenerateChat(data.queryNodeId, data.body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateChatFeedback = createAsyncThunk(
  'updateChatFeedback',
  async (data: { answerNodeId: string; body: ConversationChatUpdateFeedbackRequestBody }, { rejectWithValue }) => {
    try {
      return await apiService.updateChatFeedback(data.answerNodeId, data.body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const cancelChatRequest = createAsyncThunk(
  'stopChatGeneration',
  async (data: { answerNodeId: string }, { rejectWithValue }) => {
    try {
      return await apiService.stopChatGeneration(data.answerNodeId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const _verifyChainAndSetIsQuestionSubmitted = (state: WritableDraft<ConversationState>) => {
  // Check if the new chain has any in-progress node
  if (state.conversationTree && findInprogressNodesInChain(state.conversationChain, state.conversationTree).length) {
    state.isQuestionSubmitted = true;
  } else {
    state.isQuestionSubmitted = false;
  }
};

// Create chat slice
const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    setIsNewConversation: (state, action: PayloadAction<ConversationState['isNewConversation']>) => {
      state.isNewConversation = action.payload;
    },
    setConversationId: (state, action: PayloadAction<ConversationState['conversationId']>) => {
      state.conversationId = action.payload;
    },
    setConversationTree: (state, action: PayloadAction<ConversationState['conversationTree']>) => {
      state.conversationTree = action.payload;
    },
    setConversationTreeNodeState: (state, action: PayloadAction<ConversationState['conversationTreeNodeStates']>) => {
      state.conversationTreeNodeStates = action.payload;
    },
    mergeConversationTreeNodeState: (
      state,
      action: PayloadAction<{ nodeId: string; state: Partial<ConversationTreeNodeState> }>,
    ) => {
      state.conversationTreeNodeStates[action.payload.nodeId] = {
        ...state.conversationTreeNodeStates[action.payload.nodeId],
        ...action.payload.state,
      };
    },
    setViewSourceState: (state, action: PayloadAction<ConversationState['viewSourceState']>) => {
      state.viewSourceState = action.payload;
    },
    mergeViewSourceState: (
      state,
      action: PayloadAction<Partial<NonNullable<ConversationState['viewSourceState']>>>,
    ) => {
      state.viewSourceState = {
        ...state.viewSourceState,
        ...action.payload,
      };
    },
    verifyChainAndSetIsQuestionSubmitted: (state) => {
      _verifyChainAndSetIsQuestionSubmitted(state);
    },
    changeQueryNodeInChain: (state, action: PayloadAction<{ currentQueryNodeId: string; newQueryNodeId: string }>) => {
      const { currentQueryNodeId, newQueryNodeId } = action.payload;
      if (state.conversationTree) {
        const childAnswerNodes = getChildrenOfType(newQueryNodeId, state.conversationTree, 'answer');
        const latestAnswerNodeId = getMostRecentlyCreatedNodeId(
          childAnswerNodes.map((node) => node.id),
          state.conversationTree,
        );
        if (latestAnswerNodeId) {
          // Remove the current query node and it's descendants from the chain
          // and add the new query node and it's latest answer node to the chain
          const newChain = breakChainAtGivenNodeInclusive(currentQueryNodeId, state.conversationChain);
          newChain.push({
            queryNodeId: newQueryNodeId,
            answerNodeId: latestAnswerNodeId,
          });
          // then, finish the chain till the leaf answer node
          state.conversationChain = finishChainTillLeafAnswerNode(newChain, state.conversationTree);
          _verifyChainAndSetIsQuestionSubmitted(state);
        }
      }
    },
    changeAnswerNodeInChain: (
      state,
      action: PayloadAction<{ currentQueryNodeId: string; newAnswerNodeId: string }>,
    ) => {
      const { currentQueryNodeId, newAnswerNodeId } = action.payload;
      const queryNodeIndex = state.conversationChain.findIndex(
        (chainNode) => chainNode.queryNodeId === currentQueryNodeId,
      );
      if (state.conversationTree && queryNodeIndex > -1) {
        state.conversationChain[queryNodeIndex].answerNodeId = newAnswerNodeId;
        _verifyChainAndSetIsQuestionSubmitted(state);
      }
    },
    setInputValue: (state, action: PayloadAction<ConversationState['inputValue']>) => {
      state.inputValue = action.payload;
    },
    setShowChartTooltip: (state, action: PayloadAction<ConversationState['showChartTooltip']>) => {
      state.showChartTooltip = action.payload;
    },
    setIsCacheEnabled: (state) => {
      state.isCacheEnabled = !state.isCacheEnabled;
    },
    setIsSemanticCacheEnabled: (state) => {
      state.isSemanticCacheEnabled = !state.isSemanticCacheEnabled;
    },
    updateConversationTreeFromSignalREvent: (state, action: PayloadAction<SignalREventData>) => {
      const { chat_id } = action.payload;
      const conversationTree = state.conversationTree;
      const conversationTreeNodeStates = state.conversationTreeNodeStates;
      if (
        conversationTree?.nodes[chat_id] &&
        conversationTree?.nodes[chat_id].data.task_status?.trim() !== 'CANCELLED' &&
        !conversationTreeNodeStates[chat_id]?.chatGenerationStopped
      ) {
        // Only process the event if the chat_id is present in the conversation tree
        // This avoids processing events for chats that are not part of the current active conversation
        // Later on, if we wish to keep receiving events for inactive conversations, we can remove this check
        // chat_id always points to an answer node
        const { status, task_status, sub_status, sub_status_value, final_response } = action.payload;
        const taskStatus = task_status.trim().toUpperCase();
        conversationTree.nodes[chat_id] = {
          ...conversationTree.nodes[chat_id],
          data: {
            ...conversationTree.nodes[chat_id].data,
            status,
            task_status: taskStatus,
            sub_status,
            sub_status_value,
            engine_response: final_response ?? conversationTree.nodes[chat_id].data.engine_response,
          },
        };
        if (taskStatus === 'COMPLETED') {
          if (!final_response || !final_response?.response.length) {
            conversationTreeNodeStates[chat_id] = {
              ...conversationTreeNodeStates[chat_id],
              errorMessage: 'Something went wrong. Please try again in sometime.',
            };
          } else {
            state.isQuestionSubmitted = false;
          }
        }
      }
    },
    resetConversationState: (state) => {
      return {
        ...initialState,
        isSemanticCacheEnabled: state.isSemanticCacheEnabled,
        isCacheEnabled: state.isCacheEnabled,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAndLoadConversationChats.pending, (state, action) => {
      state.isConversationLoading = true;
      state.conversationId = action.meta.arg.conversationId;
    });
    builder.addCase(fetchAndLoadConversationChats.fulfilled, (state, action) => {
      state.conversationTree = action.payload.data;
      state.conversationChain = createChainFromConversation(action.payload.data);
      state.conversationTreeNodeStates = {};
      state.isConversationLoading = false;
      _verifyChainAndSetIsQuestionSubmitted(state);
    });
    builder.addCase(createNewConversation.pending, (state) => {
      state.conversationTreeNodeStates = {};
      state.viewSourceState = null;
      state.isQuestionSubmitted = true;
      state.showApiCallPendingSpinner = true;
      state.inputValue = '';
      state.showChartTooltip = false;
    });
    builder.addCase(createNewConversation.fulfilled, (state, action) => {
      const { conversation: conversationTree, cache_date } = action.payload.data;
      state.isNewConversation = true;
      state.conversationId = conversationTree.conversation_id;
      state.conversationTree = conversationTree;
      state.conversationChain = createChainFromConversation(conversationTree);
      state.showChartTooltip = true;
      state.showApiCallPendingSpinner = false;
      // For newly created conversation, tree would only have 2 nodes (query & answer)
      const answerNode = getLatestAnswerNodeOfQueryNode(conversationTree.top_level_node_ids[0], conversationTree);
      if (answerNode?.data.engine_response) {
        // If response is available from cache
        state.isQuestionSubmitted = false;
        state.conversationTreeNodeStates[answerNode.id] = {
          cacheDate: cache_date,
        };
      } else {
        state.isQuestionSubmitted = true; // Block UI until response is available
      }
    });
    builder.addCase(createNewConversation.rejected, (state) => {
      state.isQuestionSubmitted = false;
      state.showApiCallPendingSpinner = false;
    });
    builder.addCase(addChatToConversation.pending, (state) => {
      state.isQuestionSubmitted = true;
      state.showApiCallPendingSpinner = true;
      state.inputValue = '';
    });
    builder.addCase(addChatToConversation.fulfilled, (state, action) => {
      const { conversation: conversationTree, cache_date } = action.payload.data;
      state.conversationTree = conversationTree; // Replace conversation tree with new tree
      state.showChartTooltip = true;
      state.showApiCallPendingSpinner = false;
      // For an existing tree, the new query node will be added as child of given `parentQueryNodeId`
      // `parentQueryNodeId` will be empty or equal to conversation_id if the very first query is edited so a sibling as added at the top level
      const parentQueryNodeId = action.meta.arg.body.parent_node_id;
      if (parentQueryNodeId && parentQueryNodeId !== conversationTree.conversation_id) {
        // Reform the chain after the parentQueryNodeId to include the new query node + answer
        state.conversationChain = finishChainTillLeafAnswerNode(
          breakChainAfterGivenNode(parentQueryNodeId, state.conversationChain),
          conversationTree,
        );
        // New query node would be added as the latest child of the parent query node
        const newQueryNode = getLatestChildQueryOfQueryNode(parentQueryNodeId, conversationTree);
        if (newQueryNode) {
          const newAnswerNode = getLatestAnswerNodeOfQueryNode(newQueryNode.id, conversationTree);
          if (newAnswerNode?.data.engine_response) {
            // If response is readily available from cache
            state.isQuestionSubmitted = false;
            state.conversationTreeNodeStates[newAnswerNode.id] = {
              ...state.conversationTreeNodeStates[newAnswerNode.id],
              cacheDate: cache_date,
            };
          }
        }
      } else {
        const topLevelQueryNodes = getNodesOfType(conversationTree.top_level_node_ids, conversationTree, 'query');
        const latestQueryNodeId = getMostRecentlyCreatedNodeId(
          topLevelQueryNodes.map((node) => node.id),
          conversationTree,
        );
        const answerNode = getLatestAnswerNodeOfQueryNode(latestQueryNodeId ?? '', conversationTree);
        if (latestQueryNodeId && answerNode) {
          // Start chain with the latest top-level edited query node and it's answer node
          state.conversationChain = finishChainTillLeafAnswerNode(
            [
              {
                queryNodeId: latestQueryNodeId,
                answerNodeId: answerNode.id,
              },
            ],
            conversationTree,
          );
          if (answerNode?.data.engine_response) {
            // If response is readily available from cache
            state.isQuestionSubmitted = false;
            state.conversationTreeNodeStates[answerNode.id] = {
              ...state.conversationTreeNodeStates[answerNode.id],
              cacheDate: cache_date,
            };
          }
        }
      }
    });
    builder.addCase(addChatToConversation.rejected, (state) => {
      state.isQuestionSubmitted = false;
      state.showChartTooltip = false;
      state.showApiCallPendingSpinner = false;
    });
    builder.addCase(regenerateChat.pending, (state) => {
      state.isQuestionSubmitted = true;
      state.showApiCallPendingSpinner = true;
      state.showChartTooltip = false;
    });
    builder.addCase(regenerateChat.fulfilled, (state, action) => {
      const newAnswerNode = action.payload.data;
      state.showApiCallPendingSpinner = false;
      const queryNodeId = newAnswerNode.data.parent_node_id;
      if (queryNodeId && state.conversationTree) {
        state.conversationTree.nodes[newAnswerNode.id] = newAnswerNode;
        state.conversationTree.nodes[queryNodeId].children.push(newAnswerNode.id);
      }
      const queryNodeIndexInChain = state.conversationChain.findIndex(
        (chainNode) => chainNode.queryNodeId === queryNodeId,
      );
      if (queryNodeIndexInChain > -1) {
        // Update the answer node in the chain to show new version on UI
        state.conversationChain[queryNodeIndexInChain].answerNodeId = newAnswerNode.id;
      }
    });
    builder.addCase(regenerateChat.rejected, (state) => {
      state.isQuestionSubmitted = false;
      state.showApiCallPendingSpinner = false;
    });
    builder.addCase(cancelChatRequest.pending, (state) => {
      state.isQuestionSubmitted = true;
      state.showApiCallPendingSpinner = true;
    });
    builder.addCase(cancelChatRequest.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.isQuestionSubmitted = false;
      state.showApiCallPendingSpinner = false;
      if (state.conversationTree?.nodes[data.id]) {
        state.conversationTree.nodes[data.id] = data;
        state.conversationTreeNodeStates[data.id] = {
          ...state.conversationTreeNodeStates[data.id],
          chatGenerationStopped: true,
        };
      }
      _verifyChainAndSetIsQuestionSubmitted(state);
    });
    builder.addCase(cancelChatRequest.rejected, (state) => {
      state.isQuestionSubmitted = false;
      state.showApiCallPendingSpinner = false;
    });
  },
});

export const {
  setIsNewConversation,
  setConversationId,
  setConversationTree,
  setConversationTreeNodeState,
  mergeConversationTreeNodeState,
  setViewSourceState,
  mergeViewSourceState,
  verifyChainAndSetIsQuestionSubmitted,
  changeQueryNodeInChain,
  changeAnswerNodeInChain,
  setInputValue,
  setIsCacheEnabled,
  setIsSemanticCacheEnabled,
  setShowChartTooltip,
  updateConversationTreeFromSignalREvent,
  resetConversationState,
} = conversationSlice.actions;

export default conversationSlice.reducer;
