import React from 'react';

import './HowItWork.css';
const About = () => {
  return (
    <>
      <div className="contentInner">
        <div className="space50"></div>
        <div className="secTitles">
          <h2>Our Vision</h2>
          <p>ABI Insights CoPilot: Your Single Source of Truth for Insights</p>
        </div>
        <div className="empowerWrapper max-80">
          <div className="empCol">
            <img src="/images/multiDom.svg" alt="" />
            <h2>Empowering Multidomain Insights:</h2>
            <p>
              ABI Insights CoPilot serves as the central, unified hub for gathering insights across diverse domains
              within ABI. Our innovative platform leverages natural language prompts to extract contextual information
              from both internal and external knowledge bases .
            </p>
          </div>
          <svg width="1" height="283" viewBox="0 0 1 283" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.5" y1="2.18557e-08" x2="0.499988" y2="283" stroke="url(#paint0_linear_1767_523)" />
            <defs>
              <linearGradient
                id="paint0_linear_1767_523"
                x1="-13.0007"
                y1="298.5"
                x2="-13.0007"
                y2="6.05087e-09"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" stopOpacity="0" />
                <stop offset="0.572989" stopColor="white" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>

          <div className="empCol">
            <img src="/images/stream.svg" alt="" />
            <h2>Streamlining Insight Extraction:</h2>
            <p>
              We are committed to streamlining the current manual, repetitive, and tedious process of extracting
              insights. Insights CoPilot revolutionizes how insights are obtained, making it efficient and effortless.
              At Insights CoPilot, our vision is to empower different domains to achieve their objectives and key
              results (OKRs) with our cutting edge solutions, driving growth and success for all.
            </p>
          </div>
        </div>
        <div className="space50"></div>
        <div className="secTitles">
          <h2>Our Key Objectives</h2>
          <p>
            ABI Insights CoPilot objectives are centered around delivering a powerful and <br></br> adaptable solution
            that caters to your specific needs.{' '}
          </p>
        </div>
        <div className="abtCardsWrapper max-90">
          <div className="abtCard">
            <h2>Insights Extraction:</h2>
            <p>
              We aim to extract valuable insights from both structured and unstructured data sources ,providing you with
              actionable insights that drive informed decisions.
            </p>
          </div>
          <div className="abtCard">
            <h2>Data Agnostic Solution:</h2>
            <p>
              Building a solution that is data agnostic, allowing seamless integration with various backend sources.with
              easy plugging/unplugging, we ensure agility in all your data handling processes.
            </p>
          </div>
          <div className="abtCard">
            <h2>Fine-Tune per Use Case:</h2>
            <p>
              Tailoring our platform to your unique use cases by engineering prompts based on your business inputs.
              Incorporating domain knowledge to fine-tune our models, making them more precise and relevant to your
              specific needs.
            </p>
          </div>
          <div className="abtCard">
            <h2>Access Control:</h2>
            <p>
              Establishing a robust access control layer to ensure that relevant teams have appropriate access to the
              domains they need. Prioritizing data security and privacy while granting access to maintain
              confidentiality and compliance.
            </p>
          </div>
        </div>
        <div className="space50"></div>
        <div className="secTitles">
          <p>
            ABI Insights CoPilot is purpose-built for these domains ensuring comprehensive insights and solutions across
            these key areas.
          </p>
        </div>
        <ul className="abtFoot">
          <li>
            <p>MAZ Commercial</p>
          </li>
          <li>
            <img src="/images/Line2.png" alt="mazline" />
          </li>
          <li>
            <p>Finance</p>
          </li>
          <li>
            <img src="/images/Line2.png" alt="financeline" />
          </li>
          <li>
            <p>CpnA</p>
          </li>
          <li>
            <img src="/images/Line2.png" alt="cpnaline" />
          </li>
          <li>
            <p>Procurement</p>
          </li>
          <li>
            <img src="/images/Line2.png" alt="procurmentline" />
          </li>
          <li>
            <p>Supply</p>
          </li>
          <li>
            <img src="/images/Line2.png" alt="supplyline" />
          </li>
          <li>
            <p>Logistics</p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default About;
