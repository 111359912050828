import { type ReactNode, useCallback, useRef } from 'react';
import { clsx } from 'clsx';

import styles from './Slider.module.css';

type Props = {
  items: ReactNode[];
  classNames?: {
    root?: string;
    item?: string;
  };
};

export const Slider: React.FC<Props> = ({ items, classNames }) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const isMouseDownRef = useRef(false);
  const startXRef = useRef(0);
  const scrollLeftRef = useRef(0);

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    isMouseDownRef.current = true;
    startXRef.current = e.pageX - sliderRef.current!.offsetLeft;
    scrollLeftRef.current = sliderRef.current!.scrollLeft;
  }, []);

  const handleMouseLeave = useCallback(() => {
    isMouseDownRef.current = false;
  }, []);

  const handleMouseUp = useCallback(() => {
    isMouseDownRef.current = false;
  }, []);

  const handleMouseMove = useCallback((e: React.MouseEvent) => {
    if (!isMouseDownRef.current) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current!.offsetLeft;
    const walk = (x - startXRef.current) * 3; // scroll-fast
    if (sliderRef.current) {
      sliderRef.current.scrollLeft = scrollLeftRef.current - walk;
    }
  }, []);

  return (
    <div
      className={clsx(styles.root, classNames?.root)}
      ref={sliderRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      {items.map((item, index) => (
        <div key={index} className={clsx(styles.item, classNames?.item)}>
          {item}
        </div>
      ))}
    </div>
  );
};
