export let globalAbortController: AbortController | undefined; // Global variable to hold the AbortController instance

export let authorizationToken = ''; // Add the authorization token here

export const setAbortController = (abortController: AbortController) => {
  globalAbortController = abortController;
};

export const setAuthorizationToken = (token?: string | null) => {
  authorizationToken = token || '';
};

export const stopRunningCalls = () => {
  if (globalAbortController) {
    // Abort ongoing fetch requests
    globalAbortController.abort();
  }
};
