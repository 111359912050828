import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import useAnalytics from '../../../hooks/useAnalytics';
import { EngineChartResults } from '../../../services/apiService/definitions/types';
import { downloadChartAndData } from '../../../utils/utils';

import ChartAreaCard from './ChartAreaCard';
import ChartCardControls from './ChartCardControls';

import styles from './ChartCard.module.css';

interface ChartCardProps {
  selectedChartType: string;
  chartResults?: EngineChartResults | null;
  sqlResult?: Record<string, unknown>[] | string[] | null;
  handleMaximize: (type: string) => void;
  handleMinimize: (type: string) => void;
  showPopup?: boolean;
  // ref: any;
  handleChartTypeChange: (type: string) => void;
}

const ChartCard = forwardRef<any, Omit<ChartCardProps, 'ref'>>(
  (
    {
      selectedChartType,
      chartResults,
      sqlResult,
      showPopup,
      handleMaximize,
      handleMinimize,
      handleChartTypeChange,
    }: ChartCardProps,
    ref,
  ) => {
    // Chart references
    const lineChartRef = useRef<any>(null);
    const barChartRef = useRef<any>(null);
    const horizontalBarChartRef = useRef<any>(null);
    const doughnutChartRef = useRef<any>(null);
    const pieChartRef = useRef<any>(null);
    const areaChartRef = useRef<any>(null);
    const stackedBarChartRef = useRef<any>(null);
    const stackedAreaChartRef = useRef<any>(null);

    const analytics = useAnalytics();

    const chartRefs = {
      lineChartRef,
      barChartRef,
      horizontalBarChartRef,
      doughnutChartRef,
      pieChartRef,
      areaChartRef,
      stackedBarChartRef,
      stackedAreaChartRef,
    };
    if (!chartResults?.chart_data || Object.keys(chartResults?.chart_data || {}).length === 0) {
      return null;
    }
    // Expose the download function to parent via ref
    useImperativeHandle(ref, () => ({
      handleDownload: (type: string) => {
        const chart_title = chartResults?.chart_title ?? '';
        if (type == 'table') {
          downloadChartAndData(selectedChartType, chartRefs, sqlResult ?? [], chart_title, type);
        } else if (type === 'chart') {
          const feedData = chartResults?.chart_data;
          downloadChartAndData(selectedChartType, chartRefs, feedData, chart_title, type);
        }
        analytics.trackDownloadClick(type == 'table' ? 'table' : selectedChartType, showPopup ? 'popup' : 'card');
      },
    }));

    return (
      <div
        className={styles.chartcard_wrapper}
        style={{
          maxWidth: showPopup ? '990px' : '100%',
          maxHeight: showPopup ? '380px' : 'none',
        }}
      >
        <div className={styles.chart_area_wapper}>
          {/* Chart area rendering */}
          <ChartAreaCard
            selectedChartType={selectedChartType}
            chartResults={chartResults}
            showPopup={showPopup}
            chartRefs={chartRefs}
          />

          {/* Chart controls rendering */}
          <ChartCardControls
            selectedChartType={selectedChartType}
            chartResults={chartResults}
            showPopup={showPopup}
            handleMaximize={handleMaximize}
            handleMinimize={handleMinimize}
            handleChartTypeChange={handleChartTypeChange}
          />
        </div>
      </div>
    );
  },
);
ChartCard.displayName = 'ChartCard';
export default ChartCard;
