import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useAnalytics from '../../../hooks/useAnalytics';
import { regenerateChat } from '../../../redux/features/conversationSlice';
import { AppDispatch, RootState } from '../../../redux/store';

import styles from './Retry.module.css';

type Props = {
  answerNodeId: string;
};

const Retry: React.FC<Props> = ({ answerNodeId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const analytics = useAnalytics();
  const projectKey = useSelector((state: RootState) => state.sideBar.projectKey);
  const conversationId = useSelector((state: RootState) => state.conversation.conversationId);
  const conversationTree = useSelector((state: RootState) => state.conversation.conversationTree);
  const isQuestionSubmitted = useSelector((state: RootState) => state.conversation.isQuestionSubmitted);
  const isPro = useSelector((state: RootState) => state.user.isPro);
  const answerNode = conversationTree?.nodes[answerNodeId];

  const retrySendMessage = async () => {
    try {
      if (isQuestionSubmitted || !answerNode?.parent) {
        return;
      }
      await dispatch(
        regenerateChat({
          queryNodeId: answerNode?.parent,
          body: { is_pro: isPro },
        }),
      );
    } catch (error) {
      console.error('error is:', error);
    } finally {
      analytics.trackRetryClick(projectKey, conversationId ?? '');
    }
  };

  return (
    <button className={styles.root} onClick={retrySendMessage}>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <mask id="mask0_7585_55" maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">
          <rect width="18" height="18" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_7585_55)">
          <path
            className="svg-icon-path"
            d="M6 10C4.88333 10 3.9375 9.6125 3.1625 8.8375C2.3875 8.0625 2 7.11667 2 6C2 4.88333 2.3875 3.9375 3.1625 3.1625C3.9375 2.3875 4.88333 2 6 2C6.575 2 7.125 2.11875 7.65 2.35625C8.175 2.59375 8.625 2.93333 9 3.375V2H10V5.5H6.5V4.5H8.6C8.33333 4.03333 7.96875 3.66667 7.50625 3.4C7.04375 3.13333 6.54167 3 6 3C5.16667 3 4.45833 3.29167 3.875 3.875C3.29167 4.45833 3 5.16667 3 6C3 6.83333 3.29167 7.54167 3.875 8.125C4.45833 8.70833 5.16667 9 6 9C6.64167 9 7.22083 8.81667 7.7375 8.45C8.25417 8.08333 8.61667 7.6 8.825 7H9.875C9.64167 7.88333 9.16667 8.60417 8.45 9.1625C7.73333 9.72083 6.91667 10 6 10Z"
            fill="#F3F3F3"
          />
        </g>
      </svg>
      Retry
    </button>
  );
};

export default Retry;
