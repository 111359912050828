import { driver } from 'driver.js';
import mixpanel from 'mixpanel-browser';

import 'driver.js/dist/driver.css';
import './customdriver.css';

const driverObj = new driver({
  showProgress: true,
  nextBtnText: 'Next',
  prevBtnText: 'Previous',
  steps: [
    {
      element: '#component-a-element',
      popover: {
        title: "<div class='guide-img'><img src='/images/driver1.png'/></div>",
        description:
          "<div class='guide-c'><div class='content-header'>Welcome!</div> <div class='content-description'>This is your chat bar where the magic begins. Simply type your question and press Enter. Whether it's a simple what or a complex why, Insights Copilot is here to help.</div></div>",
        side: 'top',
        align: 'start',
      },
    },
    {
      element: '#component-b-element',
      popover: {
        title: "<div class='guide-img'><img src='/images/driver2.png'/></div>",
        description:
          "<div class='guide-c'><div class='content-header'>Need some inspiration?</div> <div class='content-description'>Check out these popular questions to see what others are asking. Go ahead, click the first one to see how quickly Insights Copilot responds.</div></div>",
        side: 'top',
        align: 'start',
      },
    },
    {
      element: '#component-c-element',
      popover: {
        title: "<div class='guide-img'><img src='/images/driver3.png'/></div>",
        description:
          "<div class='guide-c'><div class='content-header'>Want to revisit a past conversation?</div> <div class='content-description'>All your previous chats are saved here. It's like having a personal archive, making it easy to pick up where you left off.</div></div>",
        side: 'top',
        align: 'start',
      },
    },
    {
      element: '#component-d-element',
      popover: {
        title: "<div class='guide-img'><img src='/images/driver4.png'/></div>",
        description:
          "<div class='guide-c'><div class='content-header'>Ready for a fresh start?</div> <div class='content-description'>Click here to open a brand-new chat. Sometimes, starting from scratch is the best way to get a clear and focused answer.</div></div>",
        side: 'top',
        align: 'start',
      },
    },
    {
      element: '#component-e-element',
      popover: {
        title: "<div class='guide-img'><img src='/images/driver5.png'/></div>",
        description:
          "<div class='guide-c'><div class='content-header'>Stay ahead of the curve.</div> <div class='content-description'>Discover articles tailored to your interests and keep up with the latest trends and news in your field.</div></div>",
        side: 'top',
        align: 'start',
      },
    },
    {
      element: '#component-f-element',
      popover: {
        title: "<div class='guide-img'><img src='/images/driver6.png'/></div>",
        description:
          "<div class='guide-c'><div class='content-header'>Curious where the data comes from?</div> <div class='content-description'>This section gives you a behind-the-scenes look at the data sources that power Insights Copilot's answers.</div></div>",
        side: 'top',
        align: 'start',
      },
    },
    {
      element: '#component-g-element',
      popover: {
        title: "<div class='guide-img'><img src='/images/driver7.png'/></div>",
        description:
          "<div class='guide-c'><div class='content-header'>Choose your domain</div> <div class='content-description'>If you have access to multiple domains, this dropdown lets you switch contexts. Choose the domain relevant to your query and let the AI tailor its responses accordingly.</div></div>",
        side: 'top',
        align: 'start',
      },
    },
    {
      element: '#component-h-element',
      popover: {
        title: "<div class='guide-img'><img src='/images/driver8.png'/></div>",
        description:
          "<div class='guide-c'><div class='content-header'>Make Insights Copilot your own.</div> <div class='content-description'>Here, you can tweak settings like enabling cache, or clearing your chat history. Customize your experience to fit your needs.</div></div>",
        side: 'top',
        align: 'start',
      },
    },
    {
      element: '#component-i-element',
      popover: {
        title: "<div class='guide-img'><img src='/images/driver9.png'/></div>",
        description:
          "<div class='guide-c'><div class='content-header'>Need a refresher on how things work? </div> <div class='content-description'>Visit the tutorial anytime from your profile. And when you're done, you can log out here too. We're always here to help whenever you need us.</div></div>",
        side: 'top',
        align: 'start',
      },
    },
    // Add more steps as needed
  ],

  onNextClick: () => {
    try {
      mixpanel.track('track_tutorial_step_next', { step: driverObj.getActiveIndex() + 1 });
    } catch (exception) {
      console.error('Mixpanel not initialized');
    } finally {
      driverObj.moveNext();
    }
  },
  onPrevClick: () => {
    try {
      mixpanel.track('track_tutorial_step_prev', { step: driverObj.getActiveIndex() - 1 });
    } catch (exception) {
      console.error('Mixpanel not initialized');
    } finally {
      driverObj.movePrevious();
    }
  },
});

export const startTour = () => {
  driverObj.drive();
};

export default driverObj;
