// Toast.tsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hideToast } from '../../redux/features/toastSlice';
import { AppDispatch, RootState } from '../../redux/store';

import ToastIcon from './ToastIcon';

import './Toast.css';

const Toast: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { message, type, visible } = useSelector((state: RootState) => state.toast);

  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        dispatch(hideToast());
      }, 3000); // Auto close after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [dispatch, visible]);

  if (!visible) return null;

  return (
    <div className={`toast-container ${type}`}>
      <div className="toast-message">
        <span className="icon">
          <ToastIcon />
        </span>
        {message}
      </div>
      <button className="close-button" onClick={() => dispatch(hideToast())}>
        ×
      </button>
    </div>
  );
};

export default Toast;
