import React from 'react';

const ToastIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        d="M4.06836 15.5879H13.0684C13.4662 15.5879 13.8477 15.4299 14.129 15.1486C14.4103 14.8672 14.5684 14.4857 14.5684 14.0879V3.08789C14.5684 2.69007 14.4103 2.30854 14.129 2.02723C13.8477 1.74593 13.4662 1.58789 13.0684 1.58789H7.48336C7.08567 1.58824 6.70439 1.74651 6.42336 2.02789L3.00836 5.44289C2.72697 5.72393 2.56871 6.1052 2.56836 6.50289V14.0879C2.56836 14.4857 2.72639 14.8672 3.0077 15.1486C3.289 15.4299 3.67053 15.5879 4.06836 15.5879ZM13.5684 3.08789V14.0879C13.5684 14.2205 13.5157 14.3477 13.4219 14.4414C13.3281 14.5352 13.201 14.5879 13.0684 14.5879H4.06836C3.93575 14.5879 3.80857 14.5352 3.71481 14.4414C3.62104 14.3477 3.56836 14.2205 3.56836 14.0879V6.58789H6.06836C6.46618 6.58789 6.84772 6.42986 7.12902 6.14855C7.41032 5.86725 7.56836 5.48572 7.56836 5.08789V2.58789H13.0684C13.201 2.58789 13.3281 2.64057 13.4219 2.73434C13.5157 2.82811 13.5684 2.95528 13.5684 3.08789ZM6.56836 3.29289V5.08789C6.56836 5.2205 6.51568 5.34768 6.42191 5.44144C6.32814 5.53521 6.20097 5.58789 6.06836 5.58789H4.27336L6.56836 3.29289Z"
        fill="#D53E41"
      />
      <path
        d="M7.88356 7.52821C7.78988 7.43508 7.66315 7.38281 7.53106 7.38281C7.39897 7.38281 7.27224 7.43508 7.17856 7.52821L6.82356 7.88321L6.46856 7.52821C6.37291 7.4463 6.24987 7.40349 6.12403 7.40835C5.9982 7.41321 5.87883 7.46538 5.78978 7.55443C5.70073 7.64347 5.64857 7.76284 5.6437 7.88868C5.63884 8.01452 5.68165 8.13756 5.76356 8.23321L6.11356 8.58821L5.76356 8.94321C5.67044 9.03689 5.61816 9.16362 5.61816 9.29571C5.61816 9.4278 5.67044 9.55453 5.76356 9.64821C5.85724 9.74133 5.98397 9.79361 6.11606 9.79361C6.24815 9.79361 6.37488 9.74133 6.46856 9.64821L6.82356 9.29321L7.17856 9.64821C7.27224 9.74133 7.39897 9.79361 7.53106 9.79361C7.66315 9.79361 7.78988 9.74133 7.88356 9.64821C7.97669 9.55453 8.02896 9.4278 8.02896 9.29571C8.02896 9.16362 7.97669 9.03689 7.88356 8.94321L7.52856 8.58821L7.88356 8.23321C7.97669 8.13953 8.02896 8.0128 8.02896 7.88071C8.02896 7.74862 7.97669 7.62189 7.88356 7.52821Z"
        fill="#D53E41"
      />
      <path
        d="M9.2538 9.64829C9.34748 9.74141 9.4742 9.79368 9.6063 9.79368C9.73839 9.79368 9.86511 9.74141 9.9588 9.64829L10.3138 9.29329L10.6688 9.64829C10.7625 9.74141 10.8892 9.79368 11.0213 9.79368C11.1534 9.79368 11.2801 9.74141 11.3738 9.64829C11.4669 9.55461 11.5192 9.42788 11.5192 9.29579C11.5192 9.1637 11.4669 9.03697 11.3738 8.94329L11.0238 8.58829L11.3738 8.23329C11.4261 8.18846 11.4686 8.1333 11.4987 8.07127C11.5287 8.00924 11.5455 7.94167 11.5482 7.87281C11.5509 7.80395 11.5393 7.73529 11.5141 7.67113C11.489 7.60696 11.4508 7.54869 11.4021 7.49996C11.3534 7.45124 11.2951 7.41311 11.231 7.38797C11.1668 7.36283 11.0981 7.35123 11.0293 7.35389C10.9604 7.35655 10.8928 7.37341 10.8308 7.40342C10.7688 7.43344 10.7136 7.47595 10.6688 7.52829L10.3138 7.88329L9.9588 7.52829C9.86314 7.44637 9.74011 7.40357 9.61427 7.40843C9.48843 7.41329 9.36906 7.46546 9.28001 7.55451C9.19097 7.64355 9.1388 7.76292 9.13394 7.88876C9.12908 8.0146 9.17188 8.13764 9.2538 8.23329L9.6088 8.58829L9.2538 8.94329C9.16067 9.03697 9.1084 9.1637 9.1084 9.29579C9.1084 9.42788 9.16067 9.55461 9.2538 9.64829Z"
        fill="#D53E41"
      />
      <path
        d="M5.86308 11.7915C5.91765 11.912 6.01774 12.006 6.14144 12.0529C6.26514 12.0997 6.40237 12.0956 6.52308 12.0415C7.16274 11.7403 7.86104 11.5841 8.56808 11.5841C9.27512 11.5841 9.97342 11.7403 10.6131 12.0415C10.6775 12.0709 10.7473 12.0862 10.8181 12.0865C10.9321 12.086 11.0425 12.0466 11.1309 11.9747C11.2194 11.9029 11.2807 11.8029 11.3047 11.6915C11.3286 11.58 11.3137 11.4638 11.2625 11.3619C11.2113 11.2601 11.1268 11.1788 11.0231 11.1315C10.2542 10.7734 9.41627 10.5879 8.56808 10.5879C7.71989 10.5879 6.88197 10.7734 6.11308 11.1315C5.99258 11.1861 5.89861 11.2862 5.85176 11.4099C5.8049 11.5336 5.80897 11.6708 5.86308 11.7915Z"
        fill="#D53E41"
      />
    </svg>
  );
};

export default ToastIcon;
