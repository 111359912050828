import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useAnalytics from '../../../hooks/useAnalytics';
import { resetConversationState } from '../../../redux/features/conversationSlice';
import { AppDispatch } from '../../../redux/store';
import { stopRunningCalls } from '../../../services/apiService/globals';

import styles from './NewChatButton.module.css';

type Props = {
  isCollapsed: boolean;
  chatInputRef: React.RefObject<HTMLTextAreaElement>;
};

const NewChatButton = ({ isCollapsed, chatInputRef }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const analytics = useAnalytics();

  const handleNewChatSession = () => {
    analytics.trackNewChatButtonClick();
    stopRunningCalls();
    navigate('/chat');
    dispatch(resetConversationState());
    setTimeout(() => {
      chatInputRef.current?.focus();
    }, 100);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'j') {
        event.preventDefault();
        handleNewChatSession();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const ButtonText = (
    <span className={styles.button_text}>
      New chat
      <span className={styles.button_shortcut}>
        <span className={styles.ctrl_text}>
          <p>
            <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_17608_2948)">
                <rect y="0.0527344" width="24" height="13.8947" rx="2.84211" fill="#161616" />
                <rect x="0.707397" y="0.64502" width="22.6477" height="11.1207" rx="2.21053" fill="#F5E003" />
                <path
                  d="M6.352 8.28908C5.944 8.28908 5.576 8.19308 5.248 8.00108C4.92 7.80908 4.662 7.54708 4.474 7.21508C4.286 6.88308 4.192 6.51108 4.192 6.09908C4.192 5.68708 4.286 5.31508 4.474 4.98308C4.662 4.65108 4.92 4.39108 5.248 4.20308C5.576 4.01508 5.944 3.92108 6.352 3.92108C6.696 3.92108 7.014 3.99108 7.306 4.13108C7.602 4.26708 7.844 4.45908 8.032 4.70708C8.22 4.95108 8.332 5.22508 8.368 5.52908H7.402C7.338 5.30508 7.208 5.12308 7.012 4.98308C6.816 4.83908 6.596 4.76708 6.352 4.76708C6.116 4.76708 5.904 4.82308 5.716 4.93508C5.528 5.04708 5.38 5.20508 5.272 5.40908C5.164 5.61308 5.11 5.84308 5.11 6.09908C5.11 6.35908 5.164 6.59108 5.272 6.79508C5.38 6.99908 5.528 7.15908 5.716 7.27508C5.904 7.38708 6.116 7.44308 6.352 7.44308C6.6 7.44308 6.822 7.36508 7.018 7.20908C7.218 7.05308 7.346 6.85508 7.402 6.61508H8.38C8.332 6.94308 8.212 7.23508 8.02 7.49108C7.832 7.74308 7.592 7.93908 7.3 8.07908C7.008 8.21908 6.692 8.28908 6.352 8.28908ZM8.76502 4.00508H11.495V4.84508H10.577V8.20508H9.68302V4.84508H8.76502V4.00508ZM12.2152 4.00508H13.5352C13.8072 4.00508 14.0492 4.05908 14.2612 4.16708C14.4732 4.27508 14.6372 4.42308 14.7532 4.61108C14.8732 4.79508 14.9332 4.99508 14.9332 5.21108C14.9332 5.41908 14.8872 5.60908 14.7952 5.78108C14.7072 5.94908 14.5812 6.08908 14.4172 6.20108C14.5852 6.30508 14.7112 6.44308 14.7952 6.61508C14.8832 6.78708 14.9272 6.98108 14.9272 7.19708V8.20508H14.0392V7.30508C14.0392 7.07308 13.9852 6.90908 13.8772 6.81308C13.7732 6.71308 13.6012 6.66308 13.3612 6.66308H13.0972V8.20508H12.2152V4.00508ZM13.4812 5.87108C13.6492 5.87108 13.7832 5.82508 13.8832 5.73308C13.9872 5.63708 14.0392 5.51108 14.0392 5.35508C14.0392 5.19908 13.9872 5.07508 13.8832 4.98308C13.7832 4.88708 13.6492 4.83908 13.4812 4.83908H13.0972V5.87108H13.4812ZM16.7413 4.00508V7.35908H18.1093V8.20508H15.8533V4.00508H16.7413Z"
                  fill="#161616"
                />
              </g>
              <defs>
                <clipPath id="clip0_17608_2948">
                  <rect y="0.0527344" width="24" height="13.8947" rx="2.84211" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </p>
        </span>{' '}
        + J
      </span>
    </span>
  );

  return (
    <button
      className={`${styles.new_Chat_Button} ${isCollapsed ? styles.new_Chat_Button_collapse : styles.new_Chat_Button_nocollapse}`}
      onClick={() => handleNewChatSession()}
    >
      <i className="fa-regular fa-plus" style={{ color: '#181818', fontWeight: 800 }}></i>
      {isCollapsed ? '' : ButtonText}
    </button>
  );
};

export default NewChatButton;
