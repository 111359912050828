import React from 'react';

import Onboarding from '../components/Onboarding/Onboarding';

const UserOnboarding = () => {
  return (
    <div
      className="onbording-container"
      style={{
        height: '100vh',
        display: 'flex',
        backgroundColor: 'var(--Greys-Base-BG-800)',
      }}
    >
      <div className="IC-Onboarding-container">
        <Onboarding />
      </div>
    </div>
  );
};

export default UserOnboarding;
