import React, { useEffect, useState } from 'react';

import AcknowledgeHeader from './AcknowledgeHeader';

import './AcknowledgeContent.css';

interface AcknowledgeContainerProps {
  headerIcon: React.ReactNode;
  headerText: string;
  contentTexts: React.ReactNode[];
  iconStyle?: React.CSSProperties;
  onAllCheckedChange?: (allChecked: boolean) => void;
}

const AcknowledgeContainer: React.FC<AcknowledgeContainerProps> = ({
  headerIcon,
  headerText,
  contentTexts,
  iconStyle,
  onAllCheckedChange,
}) => {
  const [checkboxes, setCheckboxes] = useState<boolean[]>(Array(contentTexts.length).fill(false));

  useEffect(() => {
    const allChecked = checkboxes.every(Boolean);

    if (onAllCheckedChange) {
      onAllCheckedChange(allChecked);
    }
  }, [checkboxes, onAllCheckedChange]);

  const handleCheckboxChange = (index: number) => {
    const newCheckboxes = [...checkboxes];

    newCheckboxes[index] = !newCheckboxes[index];

    setCheckboxes(newCheckboxes);
  };

  return (
    <>
      <AcknowledgeHeader icon={headerIcon} text={headerText} iconStyle={iconStyle} />
      <div className="Acknowledge-container">
        {contentTexts.map((content, index) => (
          <div key={index} className="Acknowledge-content">
            <div className="Acknowledge-checkbox">
              <div className="check-box">
                <input
                  type="checkbox"
                  name="acknowledge"
                  id={`acknowledge-${index}`}
                  checked={checkboxes[index]}
                  onChange={() => handleCheckboxChange(index)}
                />
              </div>
            </div>
            <div className="Acknowledge-text">{content}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AcknowledgeContainer;
