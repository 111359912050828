import './index.css';
export const MessageSkeletonLoader: React.FC = () => (
  <div className="skeletonLoader" data-testid="skeleton-loader">
    {Array.from({ length: 3 }).map((_, index) => (
      <div className="singleMsg skeleton" key={index}>
        <div className="msgHeader">
          <div className="profImg skeleton"></div>
          <div className="shine-animation" style={{ width: '20%' }}></div>
        </div>
        <div className="msgBody skeleton">
          <div className="shine-animation"></div>
          <div className="shine-animation"></div>
        </div>
      </div>
    ))}
  </div>
);
