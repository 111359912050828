import React, { useEffect, useState } from 'react';

import { EngineChartResults } from '../../services/apiService/definitions/types';

import ChartType from './ChartType';

interface ChartComponentProps {
  chartType: string;
  chartData: EngineChartResults;
  showPopup?: boolean;
  chartRefs: {
    lineChartRef: React.RefObject<any>;
    barChartRef: React.RefObject<any>;
    horizontalBarChartRef: React.RefObject<any>;
    doughnutChartRef: React.RefObject<any>;
    pieChartRef: React.RefObject<any>;
    areaChartRef: React.RefObject<any>;
    stackedBarChartRef: React.RefObject<any>;
    stackedAreaChartRef: React.RefObject<any>;
  };
}

const ChartComponent: React.FC<ChartComponentProps> = ({ chartType, chartData, showPopup, chartRefs }) => {
  const [uniqueId, setUniqueId] = useState('');
  // create unique id for each chart
  useEffect(() => {
    const generateUniqueId = () => {
      return 'id-' + Date.now() + '-' + Math.random().toString(36).substr(2, 9);
    };

    setUniqueId(generateUniqueId());
  }, []);

  const title = chartData?.chart_title;

  function generateChart(chartResults: any) {
    // Function to generate single chart
    function generateSingleChart(chartResults: any) {
      // Directly use chartResults.chart_data without any modification
      return chartResults?.chart_data;
    }

    // Function to generate multichart
    function generateMultiChart(chartResults: any) {
      // Extract chart data
      const feedData = chartResults?.chart_data;

      // Return chart data directly
      return feedData;
    }

    // Check number of keys in chart data
    const keysCount = Object.keys(chartResults?.chart_data[0]).length;

    // Generate chart based on number of keys
    if (keysCount === 2) {
      return generateSingleChart(chartResults);
    } else {
      return generateMultiChart(chartResults);
    }
  }

  const chartDataFeed = generateChart(chartData);

  // Naming xaxis title and yaxis title
  const xAxisTitle = chartData?.x_axis_cols[0].trim();

  const yAxisTitle = chartData?.y_axis_cols ?? [];

  const xTitle = xAxisTitle || 'X-Axis';

  const yTitle = yAxisTitle || 'Y-Axis';

  const y1AxisTitle = chartData?.y2_axis_cols;

  const y2Title = chartData?.y2_axis_name || 'Secondary Axis';

  const y1Title = chartData?.y_axis_name || 'Y-Axis';

  // A type guard to ensure chartType maps to a valid chart reference key
  function getChartRefKey(chartType: string) {
    switch (chartType) {
      case 'line':
        return 'lineChartRef';
      case 'vbar':
        return 'barChartRef';
      case 'hbar':
        return 'horizontalBarChartRef';
      case 'doughnut':
        return 'doughnutChartRef';
      case 'pie':
        return 'pieChartRef';
      case 'area':
        return 'areaChartRef';
      case 'stackedbar':
        return 'stackedBarChartRef';
      case 'stackedarea':
        return 'stackedAreaChartRef';
      default:
        console.error(`Unknown chartType: ${chartType}`);
        return 'lineChartRef'; // You can return a default ref key or throw an error here
    }
  }
  // Get the correct chartRef based on the chartType
  const chartRefKey = chartType ? getChartRefKey(chartType) : null;
  const chartRef = chartRefKey ? chartRefs[chartRefKey] : undefined;

  return (
    <ChartType
      chartData={chartDataFeed}
      title={title}
      xAxisTitle={xTitle}
      yAxisTitle={yTitle}
      y1AxisTitle={y1AxisTitle}
      y1Title={y1Title}
      y2Title={y2Title}
      id={uniqueId}
      chartType={chartType}
      chartRef={chartRef}
      showPopup={showPopup}
    />
  );
};

export default ChartComponent;
