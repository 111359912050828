import React from 'react';

const ArrowDown: React.FC = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
        <mask id="mask0_8173_293" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
          <rect y="0.5" width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_8173_293)">
          <path
            className="svg-icon-path"
            d="M8 10.7502L4 6.75023L4.93333 5.81689L8 8.88356L11.0667 5.81689L12 6.75023L8 10.7502Z"
            fill="white"
          />
        </g>
      </svg>
    </>
  );
};

export default ArrowDown;
