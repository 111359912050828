import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '../../redux/store';

import './NoticeComponent.css';

interface NoticeProps {
  header: string;
  info: string;
}

const Notice: React.FC<NoticeProps> = ({ header, info }) => {
  const theme = useSelector((state: RootState) => state.user.theme);
  const noticeRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const noticeElement = noticeRef.current;
    const handleLinkClick = (e: Event) => {
      const target = e.target as HTMLElement;
      if (target.tagName === 'A') {
        const href = (target as HTMLAnchorElement).getAttribute('href');
        e.preventDefault();
        if (href) {
          window.open(href, '_blank');
        }
      }
    };

    noticeElement?.addEventListener('click', handleLinkClick);

    return () => {
      noticeElement?.removeEventListener('click', handleLinkClick);
    };
  }, [navigate]);
  return (
    <div
      className="IC-Onboarding-notice-container"
      ref={noticeRef}
      style={{ marginTop: header === 'Terms of Use' ? '0' : header === 'Privacy Policy' ? '20px' : '50px' }}
    >
      <div className="notice-header">
        <p>{header}</p>
      </div>
      <div className="notice-info">
        <p
          dangerouslySetInnerHTML={{ __html: info }}
          style={{
            color: theme === 'light-theme' ? 'rgba(13, 13, 13, 0.90)' : '',
            fontSize: header === 'Terms of Use' || header === 'Privacy Policy' ? 'var(--Info-14)' : '',
          }}
        />
      </div>
    </div>
  );
};

export default Notice;
