import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useAnalytics from '../../hooks/useAnalytics';
import { resetConversationState } from '../../redux/features/conversationSlice';
import { fetchChatHistory } from '../../redux/features/historySlice';
import { clearAllLandingState } from '../../redux/features/landingPageSlice';
import { setProjectKey } from '../../redux/features/sideBar';
import { AppDispatch, RootState } from '../../redux/store';
import { stopRunningCalls } from '../../services/apiService/globals';

import './DomainDropdown.css';

const DomainDropdown: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const analytics = useAnalytics();

  const [isActive, setIsActive] = useState<boolean>(false);
  const selectedValue = useSelector((state: RootState) => state.sideBar.projectKey);
  const userAccount = useSelector((state: RootState) => state.user.userData);
  const isMenuOpen = useSelector((state: RootState) => state.user.isMenuOpen);
  const isSettingsOpen = useSelector((state: RootState) => state.user.isSettingsOpen);
  const isProfileOpen = useSelector((state: RootState) => state.user.isProfileOpen);
  let project_keys = useSelector((state: RootState) => state.sideBar.projectKeys);

  const menuRef = useRef<HTMLDivElement>(null);

  if (project_keys.length === 0) {
    project_keys = (userAccount?.project_keys || []).map((key) => ({
      id: key.id,
      display_name: key.display_name,
      project_name: key.project_name,
    }));
  }

  // const project_keys = Object.keys(projectKeysMapping); // Use project keys from mapping
  const selectedOption = selectedValue
    ? project_keys.find((key) => key.project_name === selectedValue)?.display_name
    : project_keys.map((key) => key.display_name)[0]; // Use the first project key as default

  useEffect(() => {
    if (isMenuOpen || isSettingsOpen || isProfileOpen) {
      setIsActive(false);
    }
  }, [isMenuOpen, isSettingsOpen, isProfileOpen]);

  const toggleActive = () => {
    setIsActive(!isActive);
    analytics.trackDomainDropdownClick();
  };

  const handleOptionClick = async (option: string) => {
    const selectedProjectKey = option;
    dispatch(setProjectKey(selectedProjectKey));
    dispatch(clearAllLandingState());
    analytics.trackDomainClick(selectedProjectKey);
    const currentProjectKeyString = JSON.stringify(selectedProjectKey);
    localStorage.setItem('ProjectKey', currentProjectKeyString);
    await dispatch(fetchChatHistory({ projectKey: selectedProjectKey })).unwrap();
    if (location.pathname === '/chat') {
      stopRunningCalls();
      dispatch(resetConversationState());
      setIsActive(false);
      return;
    } else {
      // Hard refresh clears all the redux state
      window.location.href = '/chat';
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsActive(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={`select-menu ${isActive ? 'active' : ''}`} style={{ position: 'relative' }}>
      <div className="select-btn" onClick={toggleActive} style={styles.selectBtn} ref={menuRef}>
        <span className="sBtn-text">{selectedOption}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 14 14"
          fill="none"
          style={styles.arrow}
        >
          <path
            className="svg-icon-path"
            d="M7 8.98323L3.5 5.48323L4.31667 4.66656L7 7.3499L9.68333 4.66656L10.5 5.48323L7 8.98323Z"
            fill="#B7B7B7"
          />
        </svg>
      </div>
      {isActive && (
        <ul className="options" style={styles.options}>
          {project_keys // Use project keys from mapping
            .map((option, index) => (
              <li
                key={option.id}
                className={index === project_keys.length - 1 ? 'option lastOption' : 'option'}
                onClick={(e) => {
                  e.stopPropagation();

                  handleOptionClick(option.project_name);
                }}
                style={index === project_keys.length - 1 ? styles.lastOption : styles.option}
              >
                {option.display_name}
                {selectedValue === option.project_name && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    style={styles.checkIcon}
                  >
                    <path
                      className="svg-icon-path"
                      d="M5.5 10.5L2 7L2.70711 6.29289L5.5 9.08579L11.2929 3.29289L12 4L5.5 10.5Z"
                      fill="#fff"
                    />
                  </svg>
                )}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  selectBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: ' var(--Greys-Base-BG-900)',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    height: '27px',
    minWidth: '198px',
  },
  arrow: {
    marginLeft: '10px',
  },
  options: {
    position: 'absolute',
    top: '33px',
    left: '0',
    color: ' var( --neutral-0)',
    borderRadius: '5px',
    padding: '10px 0',
    margin: '0',
    listStyle: 'none',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    width: '198px',
    alignItems: 'center',
  },
  option: {
    padding: '4px 20px',
    cursor: 'pointer',
    width: '92%',
    height: 'fit-content',
  },
  lastOption: {
    padding: '4px 20px',
    cursor: 'pointer',
    width: '92%',
    height: 'fit-content',
  },
  checkIcon: {
    float: 'right',
  },
};

export default DomainDropdown;
