import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import useAnalytics from '../../hooks/useAnalytics';
import { updateChatFeedback } from '../../redux/features/conversationSlice';
import { AppDispatch } from '../../redux/store';

import ResponseOption from './ResponseOptions';

import './ThumbsDownPopup.css';

interface ThumbsDownPopupProps {
  answerNodeId: string;
  onClose: () => void;
  setNegativeFeedback: (value: boolean) => void;
  setShowFeedbackSuccess: (value: boolean) => void;
}

const ThumbsDownPopup: React.FC<ThumbsDownPopupProps> = ({
  answerNodeId,
  onClose,
  setNegativeFeedback,
  setShowFeedbackSuccess,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const analytics = useAnalytics();

  const [feedbackContent, setFeedbackContent] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const popupRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setIsSubmitDisabled(feedbackContent.trim() === '');
  }, [feedbackContent]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        // Click occurred outside the popup, close it
        onClose();
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [onClose]);

  const toggleOption = (label: string) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(label)) {
        return prevSelectedOptions.filter((option) => option !== label);
      } else {
        return [...prevSelectedOptions, label];
      }
    });
  };

  const handleSubmit = () => {
    dispatch(
      updateChatFeedback({
        answerNodeId: answerNodeId,
        body: {
          chat_id: answerNodeId,
          feedback_sentiment: false,
          feedback_msg: feedbackContent,
          feedbackOptions: {
            not_factual: false,
            not_detailed: false,
            not_actionable: false,
          },
        },
      }),
    )
      .then((result) => {
        if (result.type.endsWith('fulfilled')) {
          setShowFeedbackSuccess(true);
          setNegativeFeedback(true);
          onClose();
          setTimeout(() => {
            setShowFeedbackSuccess(false);
          }, 2000);
        }
      })
      .catch((error) => {
        toast.error('Something went wrong!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        console.error('Error submitting feedback', error);
      });
    analytics.trackFeedbackSubmit(answerNodeId, selectedOptions);
  };

  return (
    <>
      <div className="popupBG">
        <div className="popup" ref={popupRef}>
          <div className="popupHeader">
            <p className="popup-title">
              Help us Improve
              <span>Please offer further feedback on this response. Choose all options that apply.</span>
            </p>
            <span className="closeIcon">
              <i
                className="fa-solid fa-xmark"
                onClick={onClose}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === 'Space') {
                    onClose();
                  }
                }}
                tabIndex={0}
              ></i>
            </span>
          </div>
          <div className="line" style={{ marginTop: '14px', maxWidth: '520px', width: '100%' }}></div>
          <div className="popup-response-container">
            <div className="popup-response-question">How can the response be improved?</div>
            <div className="popup-response-answer">
              <textarea
                placeholder="Your Response"
                value={feedbackContent}
                onChange={(event) => setFeedbackContent(event.target.value)}
              ></textarea>
            </div>
            <div className="popup-response-options">
              <ResponseOption
                label="Irrelevant"
                onClick={() => {
                  toggleOption('Irrelevant');
                }}
              />
              <ResponseOption label="Answer too long" onClick={() => toggleOption('Answer too long')} />
              <ResponseOption label="Answer too Short" onClick={() => toggleOption('Answer too Short')} />
              <ResponseOption label="Not Helpful" onClick={() => toggleOption('Not Helpful')} />
              <ResponseOption label="Not Factual" onClick={() => toggleOption('Not Factual')} />
              <ResponseOption
                label="The Visualisations were not relevant"
                onClick={() => toggleOption('The Visualisations were not relevant')}
              />
            </div>
          </div>
          <div className={isSubmitDisabled ? 'popupFooter disabled' : 'popupFooter'}>
            <button className="feedback-cancel" onClick={onClose}>
              Cancel
            </button>
            <button className="feedback-submit" onClick={handleSubmit} disabled={isSubmitDisabled}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThumbsDownPopup;
