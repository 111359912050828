import { removeCookie } from 'typescript-cookie';

import { clearUserData, setIsLoggedIn } from '../redux/features/userSlice';
import { store } from '../redux/store';
import { setAuthorizationToken, stopRunningCalls } from '../services/apiService/globals';

export const logoutAndCleanup = () => {
  stopRunningCalls();
  setAuthorizationToken(null);
  removeCookie('is_logged_in');
  localStorage.removeItem('AccessToken');
  store.dispatch(setIsLoggedIn(false));
  store.dispatch(clearUserData());
};
